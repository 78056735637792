import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { FormGeneral } from '../FormGeneral/FormGeneral';
import { FormProspectus } from '../FormProspectus/FormProspectus';
import { FormInvoicing } from '../FormInvoicing/FormInvoicing';
import { RequestFormSummary } from '../Summary/RequestFormSummary';
import { renderForm } from 'bxcommon/helpers/form.helper';


// Equity
const equitySteps = [
  { no: 0, component: <FormGeneral isEquity /> },
  { no: 1, component: <FormProspectus /> },
  { no: 2, component: <FormInvoicing /> },
  { no: 3, component: <RequestFormSummary isEquity /> }
];

const debtSteps = [
  { no: 0, component: <FormGeneral /> },
  { no: 1, component: <FormProspectus /> },
  { no: 2, component: <FormInvoicing /> },
  { no: 3, component: <RequestFormSummary /> }
];

const derivativesSteps = [
  { no: 0, component: <FormGeneral /> },
  { no: 1, component: <FormProspectus /> },
  { no: 2, component: <FormInvoicing /> },
  { no: 3, component: <RequestFormSummary /> }
];

const equity = renderForm(equitySteps, 'equity', 'ES');
const debt = renderForm(debtSteps, 'debt', 'DS');
const derivatives = renderForm(derivativesSteps, 'derivatives', 'DP');

const RequestView = ({match}) => {
  return (
    <Switch>
      <Route path={`${match.path}/equity/:requestId?`} render={equity} />
      <Route path={`${match.path}/debt/:requestId?`} render={debt} />
      <Route path={`${match.path}/derivative/:requestId?`} render={derivatives} />
      <Redirect to="/list" />
    </Switch>
  );
};

export default RequestView;
