import axios from 'axios';

import { sortDialcodes, sortCountries } from '../../helpers/countries.helpers';
import AppConfig from '../../providers/AppConfig';


export const RESOURCES_ACTIONS = {
  HYDRATE: 'hydrate_resources',
  SET_ERROR: 'set_resources_error'
};

export const hydrateResources = (url) => {
  return (dispatch) => {
    return Promise.all([
        axios.get(url),
        axios.get('/countries/')
      ])
      .then(result => {
        dispatch({
          type: RESOURCES_ACTIONS.HYDRATE,
          payload: {
            ...result[0].data.data,
            countries: sortCountries(result[1].data.data, AppConfig.mainCountriesIsoCodes),
            dialcodes: sortDialcodes(result[1].data.data, AppConfig.mainCountriesIsoCodes)
          }
        })
      })
  };
};
