import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { successToast, warningToast } from 'bxcommon/store/actions';
import { BaseButtonFill } from 'bxcommon/index';
import { createInput } from 'bxcommon/helpers/form.helper';
import { forgotPassword } from 'bxcommon/misc/requests'

import UserCommonView from 'bxcommon/components/UserCommonView/UserCommonView';

const UserForgotPassword = ({history, successToast, warningToast, isAdvisor}) => {
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const initialFormValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(common('errors.requiredField'))
      .email(common('errors.email')),
  });

  const successMessage = useCallback((type = 'userNotExists') => {
    successToast(
      common(`forgotPassword.success.${type}Description`),
      common(`forgotPassword.success.${type}Title`)
    );
  }, [successToast]);

  const errorMessage = useCallback((type = 'userNotActivated') => {
    warningToast(
      common(`forgotPassword.errors.${type}Description`),
      common(`forgotPassword.errors.${type}Title`)
    );
  }, [warningToast])

  const onSubmit = async(formData) => {
    try {
      setForgotPasswordLoading(true);
      await forgotPassword(formData);
      successMessage();
      setForgotPasswordLoading(false);
      history.push('/login');
    } catch (e) {
      if (e.response.status == 401) {
        errorMessage('userNotActivated');
      } else {
        successMessage('userNotExists');
      }
      setForgotPasswordLoading(false);
    }
  };
  return (
    <UserCommonView
      isAdvisor={isAdvisor}
      heading={common('forgotPassword.heading')}
      subheading={common('forgotPassword.subheading')}
      initialFormValues={initialFormValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <div className="row">
        {createInput('email', t('forgotPassword.label'))}
      </div>
      <div className="row">
        <BaseButtonFill
          type="submit"
          full
          iconRight
          icon="arrow-right"
          loading={forgotPasswordLoading}
          data-cypress="forgot-password-button"
        >
          { common('forgotPassword.button') }
        </BaseButtonFill>
      </div>
    </UserCommonView>
  );
};


export default withRouter(connect(null, { forgotPassword, successToast, warningToast })(UserForgotPassword));
