import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';
import classnames from 'classnames';

import { history } from 'bxcommon/BxBrowserRouter';
import { BaseUploadInput, BaseIcon } from '..';

import './BaseDropdown.scss';

const createMenu = (content) => (
  <Menu>
    {content.map((item, index)=> (
      <Menu.Item key={index}>
        <div
          className={classnames('base-dropdown__item', {
            'base-dropdown__item--disabled': item.disabled
          })}
          onClick={() => {
            if (item.runBefore) {
              item.runBefore(item);
            }
            history.push(item.link);
          }}
          data-cypress="dropdown-item"
        >
          { item.icon && (
            <BaseIcon
              className="base-dropdown__icon"
              icon={item.icon}
              dark
            />
          ) }
          {item.label}
        </div>
      </Menu.Item>
    ))}
  </Menu>
);

export const BaseDropdown = ({ className, children, ...rest }) => {
  const { triggerElement, menuContent } = children;

  return (
    <Dropdown
      {...rest}
      overlay={createMenu(menuContent)}
      trigger={['click']}
      className={classnames('base-dropdown', className)}
    >
      { triggerElement }
    </Dropdown>
  );
};

BaseUploadInput.propTypes = {
  className: PropTypes.string,
  children: PropTypes.shape({
    triggerElement: PropTypes.node.isRequired,
    menuContent: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        link: PropTypes.string,
        icon: PropTypes.string,
        disabled: PropTypes.string
      })
    )
  })
};
