export const equalGroups = (base, modified) => {
  if (base.name !== modified.name) {
    return false;
  }

  const baseMembers = base.members.map(member => member.id);
  const modifiedMembers = modified.members.map(member => member.id);

  if (baseMembers.length !== modifiedMembers.length) {
    return false;
  }

  for (let memberId of modifiedMembers) {
    if (!baseMembers.includes(memberId)) {
      return false;
    }
  }

  return true;
};

