import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import { BaseLoaderSpinner } from '..';

import './BaseTable.scss';

export const BaseTable = ({labels, loading, children, selectable, selectAll, onSelectAll}) => {
  const labelRow = labels
    .map((item, idx) => (
      <td className="copy copy--small" key={idx}>{item}</td>
    ));

  return (
    <table
      className="base-table"
      data-cypress="base-table"
    >
      <tbody>
      <tr className="base-table__labels">
        {selectable && <td>
          <Checkbox
            name="selectable"
            checked={selectAll}
            onChange={onSelectAll}
          />
        </td>}
        {labelRow}
      </tr>
      {!loading ? (
        <>
          {children}
        </>
      ) : (
        <tr className="tr-no-style">
          <td>
            <BaseLoaderSpinner isComponent />
          </td>
        </tr>
      )}
      </tbody>
    </table>
  );
};

BaseTable.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired
};
