import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { BaseInputBasic } from '..';
import { debounce } from '../../helpers/common.helpers';
import AppConfig from '../../providers/AppConfig';

export const BaseSearch = props => {
  const { onChange, placeholder, ...rest } = props;
  const [ search, setSearch ] = useState('');

  const debouncedChange = useCallback(debounce((e) => onChange(e), AppConfig.searchDebounceTime), []);

  return (
    <BaseInputBasic
      placeholder={placeholder}
      icon="search"
      handleChange={(e) => {
        debouncedChange(e);
        setSearch(e);
      }}
      value={search}
      deletable
      data-cypress={rest['data-cypress']}
    />
  );
};

BaseSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};
