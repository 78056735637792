import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage, FastField, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  BaseBox,
  BaseBoxHeading,
  BaseTable,
  BaseIcon,
  BaseButtonFill,
  BaseButtonLink,
  BaseFetchSelect,
  BaseInput,
} from 'bxcommon';
import { requiredStringValidator } from 'bxcommon/helpers/form.helper';
import { warningToast, successToast } from 'bxcommon/store/actions';

import RouteLeavingGuard from 'bxcommon/components/RouteLeaveGuard/RouteLeaveGuard';
import { equalGroups } from '../common/common.helpers';
import { ProspectusHeader } from '../ProspectusHeader/ProspectusHeader';
import {
  updateUserGroup,
  addUserGroup,
  hydrateUserGroups,
  setUserGroup
} from '../../store/actions';
import errorsService  from 'bxcommon/helpers/errors.helper';

import './UserGroupsEdit.scss'

const UserGroupsEdit = props => {
  const {
    warningToast,
    successToast,
    updateUserGroup,
    addUserGroup,
    setUserGroup,
    selectedUserGroup,
    userGroups,
    history,
    match
  } = props;

  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const mappedMembersToMatchAutocomplete = selectedUserGroup.members && selectedUserGroup.members.map((member) => ({
    key: member.id,
    email: member.email,
    label: `${member.first_name} ${member.last_name}`
  }));

  const initialValues = {
    name: selectedUserGroup.name || '',
    members: mappedMembersToMatchAutocomplete || [],
    selectedMember: ''
  };

  const [loading, setLoading] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const id = match.params.groupId ? Number(match.params.groupId) : null;

    if (id && selectedUserGroup.id !== id) {
      const foundGroup = userGroups.find(group => group.id === Number(match.params.groupId));

      if (foundGroup.id) {
        setUserGroup(foundGroup);
      }
    }
  }, [setUserGroup, match.params.groupId, userGroups, selectedUserGroup.id]);

  const validationSchema = Yup.object().shape({
    name: requiredStringValidator(),
    members: Yup.array().required(t('userGroups.errors.membersRequired'))
  });

  const handleSubmit = async({ name, members }) => {
    setFormChanged(false);
    const mappedMembers = members.map(m => m.key);

    const data = { name, members: mappedMembers };

    try {
      setLoading(true);
      if (selectedUserGroup.id) {
        await updateUserGroup(data, selectedUserGroup.id);
        successToast(
          t('userGroups.edit.success.updateDescription'),
          t('userGroups.edit.success.title')
        );
        setFormChanged(false);
        setLoading(false);
      } else {
        await addUserGroup(data);
        successToast(
          t('userGroups.edit.success.addDescription'),
          t('userGroups.edit.success.title')
        );
        setLoading(false);
        history.push('/groups');
      }
    } catch (error) {
      errorsService.showBackendErrors(error, true);
      setLoading(false);
    }
  };

  const handleFormChanged = (base) => {
    setFormChanged(!equalGroups(base, {name: initialValues.name, members: initialValues.members}));
  };

  const addNewMember = (values, newMember, setFieldValue) => {
    const alreadyAdded = values.members.find(member => member.key === newMember.key);

    if (!alreadyAdded) {
      handleFormChanged({name: values.name, members: [...values.members, newMember]});
      setFieldValue('members', [...values.members, newMember]);
      setFieldValue('selectedMember', '')
    } else {
      warningToast(
        t('userGroups.edit.add.alreadyAddedDescription'),
        t('userGroups.edit.add.alreadyAddedTitle')
      );
    }

  };

  const removeMember = (values, key, setFieldValue) => {
    const filtered = values.members.filter(member => member.key !== key);

    handleFormChanged({name: values.name, members: filtered});
    setFieldValue('members', filtered);
  };

  return (
    <>
      <RouteLeavingGuard
        when={formChanged}
        navigate={path => history.push(path)}
      />
      <ProspectusHeader>
        <div className="container user-groups-edit">
          <div className="row">
            <div className="col-2">
              <BaseButtonLink
                iconLeft
                icon="arrow-left"
                onClick={() => history.push('/groups')}
                className="user-groups-edit__go-back"
                data-cypress="user-groups-edit-go-back"
              >
                {common('goBack')}
              </BaseButtonLink>
            </div>
            <div className="col-8">
              <Formik
                enableReinitialize={true}
                ref={formRef}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({values, setFieldValue}) => (
                  <>
                    <Form>
                      <BaseBox
                        data-cypress="user-groups-box-name"
                        lifted
                      >
                        <BaseBoxHeading>{t('userGroups.edit.name.heading')}</BaseBoxHeading>
                        <div className="row">
                          <div className="col-6">
                            <FastField
                              component={BaseInput}
                              name="name"
                              label={t('userGroups.edit.name.label')}
                              data-cypress="name"
                              onChange={(value) => {handleFormChanged({name: value, members: values.members})}}
                            />
                          </div>
                        </div>
                      </BaseBox>
                      <BaseBox
                        data-cypress="user-groups-box-members"
                        lifted
                      >
                        <BaseBoxHeading>{t('userGroups.edit.add.heading')}</BaseBoxHeading>
                        <div className="row">
                          <div className="col-10">
                            <Field
                              queryUrl="/users/?query="
                              component={BaseFetchSelect}
                              exclude={values.members}
                              name="selectedMember"
                              placeholder={t('userGroups.edit.add.placeholder')}
                              onChange={(value) => setFieldValue('selectedMember', value)}
                              data-cypress="user-groups-edit-select-new-member"
                            />
                            <ErrorMessage name="members">
                              {() =>
                                <div className="user-groups-edit__error">
                                  {t('userGroups.edit.errors.membersRequired')}
                                </div>
                              }
                            </ErrorMessage>
                          </div>
                          <div className="col-2">
                            <BaseButtonFill
                              disabled={!values.selectedMember}
                              type="button"
                              data-cypress="user-groups-add-member-button"
                              // TODO - set field in redux
                              onClick={() => addNewMember(values, values.selectedMember, setFieldValue)}
                            >
                              {t('userGroups.edit.add.button')}
                            </BaseButtonFill>
                          </div>
                        </div>

                        {Boolean(values.members.length) && (
                          <>
                            <BaseBoxHeading>{t('userGroups.edit.user.heading')}</BaseBoxHeading>
                            <BaseTable labels={t('userGroups.edit.labels', {returnObjects: true})}>
                              {values.members
                                .map((member) => (
                                  <tr
                                    key={member.key}
                                  >
                                    <td
                                      className="copy copy--color-dark user-groups__name"
                                      data-cypress="user-groups-edit-user-name"
                                    >
                                      {member.label}
                                    </td>
                                    <td
                                      className="copy copy--color-dark user-groups__name"
                                      data-cypress="user-groups-edit-user-email"
                                    >
                                      {member.email}
                                    </td>
                                    <td>
                                      <BaseIcon
                                        clickable
                                        onClick={() => removeMember(values, member.key, setFieldValue)}
                                        icon="delete"
                                        data-cypress="user-groups-edit-delete-button"
                                      />
                                    </td>
                                  </tr>
                                ))
                              }
                            </BaseTable>
                          </>
                        )}
                      </BaseBox>

                      <div className="user-groups-edit__submit">
                        <BaseButtonFill
                          data-cypress="user-groups-submit-group-button"
                          type="submit"
                          loading={loading}
                        >
                          {selectedUserGroup.id ? t('userGroups.edit.editButton') : t('userGroups.edit.submitButton')}
                        </BaseButtonFill>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
            <div className="col-2" />
          </div>
        </div>
      </ProspectusHeader>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedUserGroup: state.selectedUserGroup,
    userGroups: state.userGroups
  }
};

UserGroupsEdit.propTypes = {
  selectedUserGroup: PropTypes.shape({
    name: PropTypes.string,
    members: PropTypes.array
  }),
  userGroups: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    members: PropTypes.array
  }))
};

export default withRouter(connect(
  mapStateToProps,
  {
    warningToast,
    successToast,
    updateUserGroup,
    hydrateUserGroups,
    addUserGroup,
    setUserGroup
  }
)(UserGroupsEdit));
