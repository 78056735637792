import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppHeaderDefault, BaseButtonFill } from '..';
import { HalfPageLayout } from 'bxcommon/components/HalfPageLayout/HalfPageLayout';

import './UserRegistrationConfirmation.scss';

const UserRegistrationConfirmation = ({history, isAdvisor}) => {
  const { t: common } = useTranslation('common');

  const Content = () => (
    <div className="user-registration-confirmation__content">
      <h1 className="heading heading--secondary">
        {common('register.confirmation')}
      </h1>
      <BaseButtonFill
        iconRight
        icon="arrow-right"
        onClick={() => history.push('/login')}
        data-cypress="register-confirmation-button"
      >
        {common('register.login')}
      </BaseButtonFill>
    </div>
  );

  return (
    <AppHeaderDefault isAdvisor={isAdvisor}>
      <div className="user-registration-confirmation">
        <HalfPageLayout rightContent={<Content />} />
      </div>
    </AppHeaderDefault>
  );
};


export default UserRegistrationConfirmation;
