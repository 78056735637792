import axios from 'axios';
import authHelper from '../../helpers/auth.helper';
import { clearForm } from './request.actions';
import { clearList } from './list.actions';

const loginRequest = (data, loginAsAdmin) => axios.post('/users/auth/', {...data, login_as_admin: loginAsAdmin});

export const USER_ACTIONS = {
  TOGGLE_LOGIN: 'toggle_login',
  HYDRATE_DETAILS: 'hydrate_user_details'
};

export const hydrateUserDetails = () => {
  return (dispatch) => {
    return axios.get('/users/self')
      .then(({data}) => {
        return dispatch({
          type: USER_ACTIONS.HYDRATE_DETAILS,
          payload: data
        });
      });
  };
};

export const simpleLogin = (data, loginAsAdmin = false) => {
  return (dispatch) => {
    return loginRequest(data, loginAsAdmin)
      .then(({data}) => {
        authHelper.setToken(data.data.token);
        return hydrateUserDetails()(dispatch);
      })
  }
};

export const login = (data, loginAsAdmin = false) => {
  return (dispatch) => {
    return loginRequest(data, loginAsAdmin)
      .then(({data}) => {
        authHelper.setToken(data.data.token);
        return hydrateUserDetails()(dispatch);
      })
      .then(() => {
        dispatch({
          type: USER_ACTIONS.TOGGLE_LOGIN,
          payload: true
        });
      });
  }
};

export const initExistingToken = () => {
  return (dispatch) => {
    return hydrateUserDetails()(dispatch)
      .then(() => {
        dispatch({
          type: USER_ACTIONS.TOGGLE_LOGIN,
          payload: true
        });
      })
  }
};

export const logout = () => {
  authHelper.removeToken();
  return (dispatch) => {
    dispatch({
      type: USER_ACTIONS.TOGGLE_LOGIN,
      payload: false
    });
    dispatch(clearList());
    dispatch(clearForm());
  };
};
