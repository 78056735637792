import React from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import {createRouteHelpers } from 'bxcommon/components/RouteWrapper/RouteWrapper';
import ListView from './components/ListView/ListView';
import ProspectusDetails from './components/ProspectusDetails/ProspectusDetails';
import RequestView from './components/RequestView/RequestView';
import UserRegistration from './components/UserRegistration/UserRegistration';
import UserRegistrationConfirmation from 'bxcommon/components/UserRegistrationConfirmation/UserRegistrationConfirmation';
import UserLogin from './components/UserLogin/UserLogin';
import AdminLogin from './components/AdminLogin/AdminLogin';
import UserResetPassword from 'bxcommon/components/UserResetPassword/UserResetPassword';
import UserForgotPassword from 'bxcommon/components/UserForgotPassword/UserForgotPassword';
import UserAccountConfirmation from 'bxcommon/components/UserAccountConfirmation/UserAccountConfirmation';
import UserGroupsList from './components/UserGroupsList/UserGroupsList';
import UserGroupsEdit from './components/UserGroupsEdit/UserGroupsEdit';
import FinalTerms from './components/FinalTerms/FinalTerms';
import Addendum from './components/Addendum/Addendum';
import DocumentDownload from 'bxcommon/components/DocumentDownload/DocumentDownload';

import { initExistingToken, hydrateResources, warningToast } from 'bxcommon/store/actions';
import { BaseLoaderSpinner } from 'bxcommon';
import authHelper from 'bxcommon/helpers/auth.helper';
import store, { hydrateProspectusesList } from './store';
import { hydrateUserGroups } from './store/actions';
import { RequestProvider } from 'bxcommon/context/Request.context';

const { PrivateRoute, UnauthenticatedRoute } = createRouteHelpers(store);

class Routes extends React.Component {
  state = {
    loaded: false
  };

  async componentDidMount() {
    // Things need to be loaded for app to work
    if (!Object.keys(this.props.resources).length) {
      try {
        await this.props.hydrateResources('/prospectuses/resources/');

        // if we have token and it's not expired set login action to true (create login only action)
        if (!this.props.user.loggedIn && !authHelper.isExpired) {
          await this.props.initExistingToken();
        } else if (authHelper.token && authHelper.isExpired) {
          this.props.warningToast(this.props.t('errors.sessionExpired'));
          authHelper.removeToken();
        }
      } finally {
        this.setState({loaded: true})
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      this.state.loaded ?
        <RequestProvider url="prospectuses">
          <Switch>
            <PrivateRoute
              load={[this.props.hydrateProspectuses]}
              path="/list"
              component={ListView}
            />
            <PrivateRoute
              load={[this.props.hydrateProspectuses]}
              loadIf={!this.props.list.length}
              path="/details/:detailsId"
              component={ProspectusDetails}
            />
            <PrivateRoute
              load={[this.props.hydrateProspectuses]}
              loadIf={!this.props.list.length}
              path="/request"
              component={RequestView}
            />
            <PrivateRoute
              adminOnly
              loadIf={!this.props.userGroups.length}
              load={[this.props.hydrateUserGroups]}
              path="/groups/edit/:groupId?"
              component={UserGroupsEdit}
            />
            <PrivateRoute
              adminOnly
              load={[this.props.hydrateUserGroups]}
              path="/groups"
              component={UserGroupsList}
            />
            <PrivateRoute
              load={[this.props.hydrateProspectuses]}
              loadIf={!this.props.list.length}
              path="/final-terms/:requestId?"
              component={FinalTerms}
            />
            <PrivateRoute
              load={[this.props.hydrateProspectuses]}
              loadIf={!this.props.list.length}
              path="/addendum/:requestId?"
              component={Addendum}
            />
            <PrivateRoute
              path="/documents/:uuid"
              component={DocumentDownload}
            />
            <PrivateRoute
              path="/prospectuses/final-terms/:uuid"
              component={DocumentDownload}
            />
            <PrivateRoute
              path="/prospectuses/addenda/:uuid"
              component={DocumentDownload}
            />
            <UnauthenticatedRoute path="/register/confirmation" component={UserRegistrationConfirmation}/>
            <UnauthenticatedRoute path="/register" component={UserRegistration}/>
            <UnauthenticatedRoute path="/login" component={UserLogin}/>
            <UnauthenticatedRoute path="/admin" component={AdminLogin}/>
            <UnauthenticatedRoute path="/forgot-password" component={UserForgotPassword}/>
            <UnauthenticatedRoute path="/reset-password" component={UserResetPassword}/>
            <UnauthenticatedRoute path="/confirm-account" component={UserAccountConfirmation}/>
            <Redirect to="/list" />
          </Switch>
        </RequestProvider>
      : <BaseLoaderSpinner large />
    )
  }
}

const mapStateToProps = (state) => ({
  resources: state.resources,
  list: state.list,
  userGroups: state.userGroups,
  group: state.group,
  user: state.user
});

export default withRouter(
  compose(
    withTranslation('common'),
    connect(mapStateToProps, {
      hydrateProspectuses: hydrateProspectusesList,
      hydrateUserGroups,
      hydrateResources,
      initExistingToken,
      warningToast
    })
)(Routes));
