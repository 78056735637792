import React, { useCallback, useContext, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { warningToast } from 'bxcommon/store/actions';
import { STATUS } from 'bxcommon/constants/status.constant';
import { RequestContext } from 'bxcommon/context/Request.context';

const RequestDataWrapper_ = (props) => {
  const { t: common } = useTranslation('common');
  const { match, history, warningToast, list, children } = props;
  const { updateRequestField, setLoading } = useContext(RequestContext);

  const cantFindToast = useCallback(() => {
    warningToast(
      common('errors.cantFindRequestC'),
      common('errors.cantFindRequestT')
    );
    setLoading(false);
    history.push(props.redirect);
  }, [common, history, warningToast, setLoading]);

  useLayoutEffect(() => {
    if (!match.params.requestId) {
      cantFindToast();
      return;
    }

    if (match.params.requestId) {
      setLoading(true);
      const item = list.find(item => item.case_id === match.params.requestId);

      if (!item) {
        cantFindToast();
        return;
      }

      if (!item.status === STATUS.APPROVED) {
        warningToast(
          common('errors.notEditableRequestC'),
          common('errors.notEditableRequestT')
        );
        history.push(props.redirect);
        return;
      }

      updateRequestField(item);
      setLoading(false);
    }
  }, [match, warningToast, history, common, list, cantFindToast, setLoading]);

  return children;
};

const mapStateToProps = (state) => ({
  list: state.list,
});

export const RequestDataWrapper = connect(mapStateToProps, { warningToast })(RequestDataWrapper_);
