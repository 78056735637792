export const initialState = {
  loading: false,
  progress: 0,
  progressStatus: 'normal',
  activeFileIndex: null
};

export const REDUCER_TYPES = {
  SET_LOADING: 'setLoading',
  SET_PROGRESS: 'setProgress',
  SET_PROGRESS_STATUS: 'setProgressStatus',
  SET_ACTIVE_FILE_INDEX: 'setActiveFileIndex'
};

export default (state, action) => {
  switch (action.type) {
    case REDUCER_TYPES.SET_LOADING:
      return {...state, loading: action.payload};
    case REDUCER_TYPES.SET_PROGRESS:
      return {...state, progress: action.payload};
    case REDUCER_TYPES.SET_PROGRESS_STATUS:
      return {...state, progressStatus: action.payload};
    case REDUCER_TYPES.SET_ACTIVE_FILE_INDEX:
      return {...state, activeFileIndex: action.payload};
    default:
      throw new Error();
  }
};
