import { history } from 'bxcommon/BxBrowserRouter';
import { PAYMENT_STATUSES } from "bxcommon/constants/payments.constant";

export const registryInitCheck = (match, user, updateRequestField, list, request) => {
  const caseId = match.params.caseId;
  const entry = list.length && list.find(entry => entry.case_id === caseId);

  if (user.is_admin && caseId && caseId !== request.case_id && entry) {
    updateRequestField(entry);
  } else if (user.is_admin && caseId && !entry) {
    history.push('/');
  } else if (!user.is_admin && !request.case_id && list.length) {
    updateRequestField(list[0]);
  }
};

export const isPaymentProceededSuccessfully = (payment) => {
  return payment && [PAYMENT_STATUSES.SUCCESS, PAYMENT_STATUSES.TRUSTED].includes(payment.status)
}
