import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { BaseFormFieldWrapper } from '../BaseFormFieldWrapper/BaseFormFieldWrapper'

const TextArea = Input.TextArea;

const BaseTextArea_ = props => {
  const {field, onBlur, handleChange, ...rest} = props;

  return (
    <TextArea
      {...field}
      {...rest}
      onBlur={onBlur}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

BaseTextArea_.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any
  }),
  onBlur: PropTypes.func,
  handleChange: PropTypes.func
};

export const BaseTextArea = BaseFormFieldWrapper(BaseTextArea_);
