import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { FastField } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';

import { successToast } from 'bxcommon/store/actions';
import { BaseButtonFill, BaseInput } from 'bxcommon/index';
import { resetPassword } from 'bxcommon/misc/requests'
import { passwordValidator } from 'bxcommon/helpers/form.helper';

import UserCommonView from 'bxcommon/components/UserCommonView/UserCommonView';
import errorService, { ErrorService } from '../../helpers/errors.helper';

const RESET_ERRORS = {
  [ErrorService.ERRORS.UNDECODABLE_VALUE]: {
    type: 'warning',
    title: 'common:resetPassword.errors.errorTitle',
    content: 'common:resetPassword.errors.wrongUserId'
  },
  [ErrorService.ERRORS.INVALID_PASSWORD_RESET_TOKEN]: {
    type: 'warning',
    title: 'common:resetPassword.errors.errorTitle',
    content: 'common:resetPassword.errors.wrongToken'
  },
  [ErrorService.ERRORS.DOES_NOT_EXIST]: {
    type: 'warning',
    title: 'common:resetPassword.errors.errorTitle',
    content: 'common:resetPassword.errors.userNotFound'
  }
};

const UserResetPassword = ({history, successToast, isAdvisor}) => {
  const [resetPasswordLoading, setResetPasswordLogin] = useState(false);
  const { t: common } = useTranslation('common');

  const initialFormValues = {
    new_password: '',
    new_password_confirmation: ''
  };

  const query = queryString.parse(history.location.search);


  const validationSchema = Yup.object().shape({
    new_password: passwordValidator(),
    new_password_confirmation: passwordValidator()
      .oneOf([Yup.ref('new_password'), null], common('errors.passwordsDontMatch'))
  },[
    ['new_password', 'new_password_confirmation']
  ]);

  const onSubmit = async(formData) => {
    if (!query || !query.user_id || !query.token) {
      return;
    }

    try {
      setResetPasswordLogin(true);

      await resetPassword({...formData, ...query});

      successToast(
        common('resetPassword.success.successDescription'),
        common('resetPassword.success.successTitle')
      );
      setResetPasswordLogin(false);
      history.push('/login');
    }catch (error) {
      errorService.handleErrorToast(error, RESET_ERRORS);
      setResetPasswordLogin(false);
    }
  };
  return (
    <UserCommonView
      isAdvisor={isAdvisor}
      heading={common('resetPassword.heading')}
      subheading={common('resetPassword.subheading')}
      initialFormValues={initialFormValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >

      <div className="row">
        <FastField
          component={BaseInput}
          name="new_password"
          type="password"
          label={common('resetPassword.newPassword')}
          inputHint={common('passwordHint')}
          data-cypress="password"
        />
      </div>
      <div className="row">
        <FastField
          component={BaseInput}
          name="new_password_confirmation"
          type="password"
          label={common('resetPassword.confirmNewPassword')}
          data-cypress="confirmPassword"
        />
      </div>
      <div className="row">
        <BaseButtonFill
          type="submit"
          full
          iconRight
          icon="arrow-right"
          loading={resetPasswordLoading}
          data-cypress="reset-password-button"
        >
          { common('resetPassword.button') }
        </BaseButtonFill>
      </div>
    </UserCommonView>
  );
};


export default withRouter(connect(null, { resetPassword, successToast })(UserResetPassword));
