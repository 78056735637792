import { createReducer, initialStateMerger } from 'bxcommon/helpers/redux.helpers';
import { SELECTED_USER_GROUP_ACTIONS } from '../actions/selectedUserGroup.actions';

const selectedUserGroupInitialState = initialStateMerger({});


export default (initialState) => {
  const selectedUserGroup = {
    [SELECTED_USER_GROUP_ACTIONS.SET_USER_GROUP]: (state, {payload}) => {
      return {...payload};
    }
  };

  return createReducer(selectedUserGroupInitialState(initialState), selectedUserGroup);
}
