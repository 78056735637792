import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form as AntForm, Input } from 'antd';
import { FastField, Field } from 'formik';
import i18n from '../i18n';

import FormSteps from 'bxcommon/components/FormSteps/FormSteps';
import {
  BaseButtonFill,
  BaseButtonOutline,
  BaseCheckbox,
  BaseCheckboxGroup,
  BaseDatePicker,
  BaseInput,
  BaseSelect,
  BaseUploadBox
} from '../components';
import AppConfig from '../providers/AppConfig';

const FormItem = AntForm.Item;

export const createCleanFields = config => (value, fieldName, setField) => {
  const field = config[fieldName];

  if (field) {
    field.forEach(item => {
      if (item.condition ? item.condition(value) : !value) {
        setField(item.key, item.value);
      }
    });
  }
};

export const searchForLabel = (options, value, keys = { label: 'name', value: 'id' }) => {
  const option = options.find(option => option[keys.value] === value);
  return option ? option[keys.label] : value;
};

// Creates form step instance with steps provided to the function
export const renderForm = (steps, t, securityType) => props => (
  <FormSteps {...props} translationPrefix={t} steps={steps} securityType={securityType} />
);

export const createDatePicker = (name, label, disabled = false, disabledDate = null) => (
  <FastField
    component={BaseDatePicker}
    name={name}
    label={label}
    disabled={disabled}
    disabledDate={disabledDate}
    format={AppConfig.defaultDateFormat}
    data-cypress={name}
  />
);

export const createInput = (name, label, disabled) =>
  React.createElement(disabled !== undefined ? Field : FastField, {
    component: BaseInput,
    name,
    label,
    disabled,
    'data-cypress': name
  });

const DEFAULT_UPLOAD_BOX_CONFIG = {
  shouldUploadManually: false,
  multiple: false,
  pdfOnly: true,
  extraFieldsMapper: {},
  customValidators: [],
  showFilesWithErrors: false
};
export const createUploadBox = (disableFooterFn, formRef, callback) => ({
  name,
  title,
  conditions,
  type,
  maxFileSize,
  disabled = false,
  config = {},
  formValues,
  uploadInputRef,
  isRenewal = false,
  isCodeOfConduct = false,
  otherProps = {}
}) => {
  config = { ...DEFAULT_UPLOAD_BOX_CONFIG, ...config };
  return (
    <Field
      component={BaseUploadBox}
      name={name}
      title={title}
      conditions={conditions}
      type={type}
      onUploadStateChange={disableFooterFn}
      maxFileSize={maxFileSize}
      data-cypress={name}
      disabled={disabled}
      config={config}
      formRef={formRef}
      formValues={formValues}
      callback={callback}
      uploadInputRef={uploadInputRef}
      isRenewal={isRenewal}
      isCodeOfConduct={isCodeOfConduct}
      {...otherProps}
    ></Field>
  );
};

export const createCheckboxGroup = (name, label, options, value) => (
  <FastField
    component={BaseCheckboxGroup}
    name={name}
    label={label}
    options={options}
    value={value}
    data-cypress={name}
  />
);

export const filterInactiveMetadataForOptions = ({
  options,
  requestValues,
  activeFlagKey = 'is_active',
  customValueKey = 'id'
}) => {
  return options.filter(item => {
    return item[activeFlagKey] || requestValues.includes(item[customValueKey]);
  });
};

export const createSelect = ({
  name,
  label,
  options,
  customKeys = { label: 'name', value: 'id' },
  placeholder = '',
  dropdownClassName = '',
  allowClear = false,
  valueType = null,
  multiple = false,
  getPopupContainer = null
}) => (
  <FastField
    component={BaseSelect}
    name={name}
    label={label}
    placeholder={placeholder}
    selectOptions={options}
    customKeys={customKeys}
    data-cypress={name}
    dropdownClassName={dropdownClassName || name}
    allowClear={allowClear}
    valueType={valueType}
    dropdownMatchSelectWidth={false}
    mode={multiple ? 'multiple' : 'default'}
    getPopupContainer={getPopupContainer}
  />
);

export const createCheckbox = (name, checked, onChange, text, label) => {
  return (
    <Field component={BaseCheckbox} name={name} label={label} checked={checked} onChange={onChange} data-cypress={name}>
      {text}
    </Field>
  );
};

export const useValidation = schema => {
  const initSchema = schema;

  const [validationSchema, setSchema] = useState(schema);

  const enableValidation = () => setSchema(initSchema);
  const disableValidation = () => setSchema(Yup.object().shape({}));
  const runDelayedCallback = cb => setTimeout(() => cb()); // waits till the form updates schema

  const validate = (cb, shouldValidate = true) => {
    const initKeys = Object.keys(schema.fields).length;
    const currentKeys = Object.keys(validationSchema.fields).length;

    if (shouldValidate && initKeys && !currentKeys) {
      enableValidation();
      runDelayedCallback(cb);
    } else if (!shouldValidate) {
      disableValidation();
      runDelayedCallback(cb);
    } else {
      cb();
    }
  };

  return [validationSchema, validate];
};

export const requiredArrayWithAtLeastOneElementValidator = () => {
  return Yup.array()
    .required()
    .min(1, i18n.t('common:errors.chooseAtLeastOne'));
};

export const requiredStringValidator = () => {
  return Yup.string().required(i18n.t('common:errors.requiredField'));
};

export const requiredStringMax500Validator = () => {
  return Yup.string()
    .max(500, i18n.t('common:errors.max500chars'))
    .required(i18n.t('common:errors.requiredField'));
};

export const requiredFilesValidator = () => {
  return Yup.array().required(i18n.t('common:errors.atLeastOneFileRequired'));
};

export const passwordValidator = () =>
  requiredStringValidator().test(
    'is-valid-password',
    i18n.t('common:errors.password'),
    value => value && value.length >= 8 && /^(?=.*?[A-z])((?=.*?[0-9])|(?=.*?[#?!@$%^&*-])).{8,}$/.test(value)
  );

export const phoneRegExp = /^[0-9]/;

export const FILE_EXTENSIONS = {
  DOC: 'doc',
  DOCX: 'docx',
  PDF: 'pdf',
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpeg'
};

export const isFormEditable = status => {
  return AppConfig.editStatuses.includes(status);
};

export const renderMultipleInputs = (key, trans, data, dataError, dataTouched, setFieldValue, setFieldTouched) => {
  const addNewItem = (data, setFieldValue) => {
    if (data[data.length - 1]) {
      setFieldValue(key, [...data, '']);
    }
  };

  const clearMultipleInput = (data, setFieldValue) => {
    setFieldValue(key, data.filter(i => i));
  };

  return (
    <div className="col-12 request__form-multiple-data">
      <div className="ant-form-item-label">
        <label title={trans.label}>{trans.label}</label>
      </div>

      {data.map((value, idx) => (
        <div key={idx} data-cypress={key}>
          <div className="field-container">
            <FormItem
              help={dataTouched && dataError ? dataError : false}
              validateStatus={dataTouched && dataError ? 'error' : 'success'}
            >
              <Input
                value={value}
                onChange={e => {
                  setFieldValue(key, [...data.slice(0, idx), e.target.value, ...data.slice(idx + 1)]);
                }}
                onBlur={() => setFieldTouched(key, true)}
              />
            </FormItem>
          </div>
        </div>
      ))}

      <div className="row">
        <BaseButtonFill
          small
          type="button"
          disabled={!data[data.length - 1]}
          onClick={() => addNewItem(data, setFieldValue)}
          className="request__form-multiple-issuers-button"
          data-cypress="add-new-item-button"
        >
          {trans.add}
        </BaseButtonFill>

        {data.length > 1 && data.some(i => !i) && (
          <BaseButtonOutline
            small
            type="button"
            onClick={() => clearMultipleInput(data, setFieldValue)}
            className="request__form-multiple-issuers-button request__form-multiple-issuers-button--clear"
            data-cypress="clear-item-button"
          >
            {trans.clear}
          </BaseButtonOutline>
        )}
      </div>
    </div>
  );
};

export const DEFAULT_DEGREES = {
  type: {},
  details: {}
};

export const DEGREES_MAPPING_OPTIONS = {
  DETAILED_MAP: 'detailed_map',
  MAP: 'map'
};

export const mapDegrees = (degrees, expected_output) => {
  if (expected_output === DEGREES_MAPPING_OPTIONS.DETAILED_MAP) {
    const results = JSON.parse(JSON.stringify(DEFAULT_DEGREES));
    for (let [degree_id, degree_details] of Object.entries(degrees)) {
      results.type[degree_id] = true;
      results.details[degree_id] = degree_details;
    }
    return results;
  }

  if (expected_output === DEGREES_MAPPING_OPTIONS.MAP) {
    const results = {};
    const { type, details } = degrees;
    if (Object.keys(type).length > 0) {
      for (let [degree_id, degree_bool] of Object.entries(type)) {
        if (degree_bool) {
          results[Number(degree_id)] = details[degree_id];
        }
      }
    }
    return results;
  }
};

export const filterDialCode = dialCode => {
  return `+${dialCode.replace(/\D/g, '')}`;
};

export const isAnyDegreeSelected = degrees_temp => {
  return degrees_temp && degrees_temp.type && Object.values(degrees_temp.type).some(value => value);
};
