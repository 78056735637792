import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { RequestContext } from '../../context/Request.context';
import { BaseButtonFill } from '..';
import { HalfPageLayout } from '../HalfPageLayout/HalfPageLayout';

import './ThankYouPage.scss';

export const ThankYouPage = ({link, description, isAdvisor}) => {
  const { t } = useTranslation();
  const { clearForm, setSubmittedState } = useContext(RequestContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      if (!isAdvisor) {
        clearForm();
      }
      setSubmittedState(false);
    }
  }, []);

  const Content = () => (
    <div className="thank-you__wrapper">
      <h1 className="heading heading--primary">
        {t('request.thankYou.heading')}
      </h1>
      { description &&
      <p className="copy copy--color-dark thank-you__description">
        {description}
      </p>
      }

      <BaseButtonFill
        onClick={() => window.location = link}
        iconRight
        icon="arrow-right"
        data-cypress="thank-you-button"
      >
        {t('request.thankYou.button')}
      </BaseButtonFill>
    </div>
  );

  return (
    <div
      className={classnames('thank-you', {
        'thank-you--advisor': isAdvisor
      })}
      data-cypress="thank-you"
    >
      <HalfPageLayout rightContent={<Content />} />
    </div>
  );
};

ThankYouPage.propTypes = {
  link: PropTypes.string.isRequired,
  description: PropTypes.string,
  isAdvisor: PropTypes.bool
};
