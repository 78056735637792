import React from 'react';
import { useTranslation } from "react-i18next";

import { AppHeader, BaseButton, FooterComponent } from '..';

import './ErrorFallback.scss';

export const ErrorFallback = ({ componentStack, error }) => {
  const { t: common } = useTranslation('common');

  return (
    <div>
      <AppHeader/>
      <div className="error-container container">
        <div className="row">
          <div className="col-2" />
          <div className="col-8">
            {process.env.NODE_ENV === 'development' ? (
              <div className="error-wrapper">
                <p><strong>{common('errorFallback.header')}</strong></p>
                <p>{common('errorFallback.whatWeKnow')}</p>
                <p><strong>{common('errorFallback.error')}:</strong> {error.toString()}</p>
                <p><strong>{common('errorFallback.stacktrace')}:</strong><pre>{componentStack}</pre></p>
              </div>
            ) : (
              <div className="error-wrapper">
                <p><strong>{common('errorFallback.header')}</strong></p>
                <p>{common('errorFallback.hint')}</p>
                <div className="error-wrapper__buttons">
                  <BaseButton fill onClick={() => document.location.reload()}>{common('errorFallback.refreshBtn')}</BaseButton>
                  <BaseButton fill onClick={() => document.location = "/"}>{common('errorFallback.backToMainBtn')}</BaseButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <FooterComponent/>
    </div>
  )
};
