import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StepWizard from 'react-step-wizard';
import { connect } from 'react-redux';
import { Steps } from 'antd';
import classnames from 'classnames';

import { AppHeaderAdmin, BaseLoaderSpinner, ThankYouPage } from '..';
import { RequestHeader } from 'bxcommon/components/RequestHeader/RequestHeader';
import { removeFromList } from 'bxcommon/store/actions';
import { setCompletedState, setSubmittedState, updateRequestField, warningToast } from '../../store/actions';

import AppConfig from 'bxcommon/providers/AppConfig';

import './FormSteps.scss';

const mapStateToProps = (state) => ({
  requestCompleted: state.requestMeta.requestCompleted,
  requestSubmitted: state.requestMeta.requestSubmitted,
  resources: state.resources,
  request: state.request,
  list: state.list,
  isAdmin: state.user.details.is_admin
});

// Responsible for both displaying UI steps and providing a wrapper with logic for step wizard
const FormSteps = React.memo(({
  requestCompleted,
  requestSubmitted,
  steps,
  translationPrefix,
  history,
  match,
  list,
  updateRequestField,
  resources,
  securityType,
  warningToast,
  isAdmin
}) => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const firstStep = 1;
  const [step, setStep] = useState({previousStep: firstStep, activeStep: firstStep});
  const [loading, setLoading] = useState(false);
  const stepsInstance = useRef({});
  const templateRef = useRef(null);

  const transitions = {
    enterRight: '',
    enterLeft : '',
    exitRight : '',
    exitLeft  : ''
  };

  useLayoutEffect(() => {
    const currentStep = stepsInstance.current.state.activeStep + 1;
    setStep({previousStep: currentStep, activeStep: currentStep});
    if (match.params.requestId) {
      setLoading(true);
      const item = list.find(item => item.case_id === match.params.requestId);

      if (!item) {
        warningToast(
          common('errors.cantFindRequestC'),
          common('errors.cantFindRequestT')
        );
        history.push('/list');
        return;
      }

      if (!AppConfig.editStatuses.includes(item.status)) {
        warningToast(
          common('errors.notEditableRequestC'),
          common('errors.notEditableRequestT')
        );
        history.push('/list');
        return;
      }

      updateRequestField(item);
      setTimeout(() => setLoading(false));
    } else {
      // Set security type
      if (resources.security_type && resources.security_type.data.length) {
        const type = resources.security_type.data.find(({slug}) => slug === securityType);
        updateRequestField({security_type: type.id})
      }
    }
  }, []);

  const uiSteps = steps.map(({no}, idx, arr) => {
    let alwaysOn = (requestCompleted && (idx + 1 !== arr.length)) || requestSubmitted;

    return (
      <Steps.Step
        status={alwaysOn ? 'finish' : null}
        title={t(`request.${translationPrefix}.steps`, {returnObjects: true})[no]}
        key={no}
      />
    );
  });

  return (
    <div
      className={classnames('steps-view', {
        'steps-view--admin': isAdmin
      })}
      ref={templateRef}
    >
      <div className="steps-view__header">
        { isAdmin && <AppHeaderAdmin isProspectus /> }
        <div className="container">
          <RequestHeader
            header={t(`request.${translationPrefix}.header`)}
            onCancel={{label: common(`request.cancel`)}}
            stepsInstance={stepsInstance.current}
          />
          <div className="steps-view__steps">
            <Steps
              current={step.activeStep - 1}
              labelPlacement={'vertical'}
            >
              {uiSteps}
            </Steps>
          </div>
        </div>
      </div>
      <div className="steps-view__wrapper">
        {loading ? <BaseLoaderSpinner large /> : requestSubmitted
          ? <ThankYouPage link="/list" />
          : <StepWizard
            className="steps-view__wizard"
            transitions={transitions}
            instance={(instance) => (stepsInstance.current = instance)}
            isLazyMount={true}
            initialStep={firstStep}
            onStepChange={step => {
              templateRef.current.scrollIntoView();
              setStep(step);
            }}
          >
            {steps.map((step, idx) => React.cloneElement(step.component, {key: idx}))}
          </StepWizard>
        }
      </div>
    </div>
  );
});

FormSteps.propTypes = {
  requestCompleted: PropTypes.bool.isRequired, // metadata store state
  requestSubmitted: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    no: PropTypes.number,
    component: PropTypes.node,
    cancelFn: PropTypes.func
  }).isRequired),
  translationPrefix: PropTypes.string.isRequired,
  history: PropTypes.object,
  securityType: PropTypes.string,
  url: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default connect(mapStateToProps, {
  setSubmittedState,
  setCompletedState,
  updateRequestField,
  warningToast,
  removeFromList
})(FormSteps);
