import React, { useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { BaseBox, BaseBoxHeading, RequestStepWrapper, BaseRadio } from 'bxcommon';
import { RequestContext } from 'bxcommon/context/Request.context';
import { createCleanFields, useValidation, createInput, createSelect } from 'bxcommon/helpers/form.helper';
import { FormWrapper } from "bxcommon/components/FormWrapper/FormWrapper";

import './FormInvoicing.scss';

const INVOICE_TYPE = {
  SENT_TO_ME: 1,
  SENT_TO_ISSUER: 2,
  SENT_INSTRUCTIONS: 3
};

export const FormInvoicing = (props) => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const {
    request,
    updateRequestField,
    formRef,
    resources: {
      invoice_type,
      countries
    }
  } = useContext(RequestContext);

  const requiredStringValidator = Yup.string()
    .when('invoice_type', {
      is: it => it === INVOICE_TYPE.SENT_TO_ISSUER,
      then: Yup.string().required(common('errors.requiredField')),
      otherwise: Yup.string()
    });

  const [validationSchema, submitWithValidation] = useValidation(Yup.object().shape({
    invoice: Yup.object().shape({
      invoice_type: Yup.number()
        .nullable()
        .test('invoice-type', common('errors.requiredField'), value => value),
      issuer_name: requiredStringValidator,
      first_name: requiredStringValidator,
      last_name: requiredStringValidator,
      address_line_1: requiredStringValidator,
      postcode: requiredStringValidator,
      city: requiredStringValidator,
      country: requiredStringValidator
    })
  }));

  const initialValues = {
    invoice: {
      invoice_type: request.invoice.invoice_type || {},
      issuer_name: request.invoice.issuer_name || '',
      first_name: request.invoice.first_name || '',
      last_name: request.invoice.last_name || '',
      address_line_1: request.invoice.address_line_1 || '',
      address_line_2: request.invoice.address_line_2 || '',
      postcode: request.invoice.postcode || '',
      city: request.invoice.city || '',
      country: request.invoice.country || undefined
    }
  };

  const isChecked = (values, type) => values.invoice.invoice_type && values.invoice.invoice_type === type;

  const cleanFields = createCleanFields({
    1: [
      {key: 'issuer_name', value: ''},
      {key: 'first_name', value: ''},
      {key: 'last_name', value: ''},
      {key: 'address_line_1', value: ''},
      {key: 'address_line_2', value: ''},
      {key: 'postcode', value: ''},
      {key: 'city', value: ''},
      {key: 'country', value: undefined}
    ]
  });

  const createRadio = (type, values, setFieldValue) => {
    const item = invoice_type.data.find(i => i.id === type);
    return (
      <Field
        component={BaseRadio}
        name="invoice.invoice_type"
        value={item.id}
        checked={isChecked(values, type)}
        onChange={(value) => cleanFields(false, value, setFieldValue)}
        data-cypress="invoice_type"
      >
        {item.name}
      </Field>
    );
  };

  const onFormSubmit = () => {
    const values = formRef.current.state.values;
    values.security_type = request.security_type;
    return updateRequestField({...values});
  };

  return (
    <FormWrapper>
      <RequestStepWrapper
        formRef={formRef}
        validationSubmit={submitWithValidation}
        {...props}
      >
        <>
          <Formik
            ref={formRef}
            initialValues={initialValues}
            onSubmit={() => onFormSubmit()}
            validationSchema={validationSchema}
          >
            {({values, setFieldValue}) => (
              <BaseBox
                className="request-form__box"
                lifted
              >
                <BaseBoxHeading>{t('request.form.invoicing.heading')}</BaseBoxHeading>
                <Form className="form-container">
                  {createRadio(INVOICE_TYPE.SENT_TO_ME, values, setFieldValue)}
                  {createRadio(INVOICE_TYPE.SENT_TO_ISSUER, values, setFieldValue)}

                  {values.invoice.invoice_type === INVOICE_TYPE.SENT_TO_ISSUER && (
                    <div className="request-invoice__contact request__form-input-offset">
                      <div className="col-10">
                        <div className="row">
                          {createInput('invoice.issuer_name', t('request.form.invoicing.company_name'))}
                        </div>
                        <div className="row">
                          <div className="col-6">
                            {createInput('invoice.first_name', 'First name')}
                          </div>
                          <div className="col-6">
                            {createInput('invoice.last_name', 'Last name')}
                          </div>
                        </div>
                        <div className="row">
                          {createInput('invoice.address_line_1', t('request.form.invoicing.address_line_1'))}
                        </div>
                        <div className="row">
                          {createInput('invoice.address_line_2', t('request.form.invoicing.address_line_2'))}
                        </div>
                        <div className="row">
                          <div className="col-3">
                            {createInput('invoice.postcode', t('request.form.invoicing.postcode'))}
                          </div>
                          <div className="col-9">
                            {createInput('invoice.city', t('request.form.invoicing.city'))}
                          </div>
                        </div>
                        {createSelect({
                          name: 'invoice.country',
                          label: t('request.form.invoicing.country'),
                          options: countries,
                          placeholder: t('request.form.invoicing.countryPlaceholder')
                        })}
                      </div>
                    </div>
                  )}
                </Form>
              </BaseBox>
            )}
          </Formik>
        </>
      </RequestStepWrapper>
    </FormWrapper>
  );
};

FormInvoicing.propTypes = {
  nextStep: PropTypes.func,
  lastStep: PropTypes.func
};
