import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import './BaseSwitch.scss';

export const BaseSwitch = ({value, onChange}) => {
  return (
    <Switch
      checked={value}
      onChange={onChange}
      data-cypress="base-switch"
    />
  );
};

BaseSwitch.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};
