import React, { useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BaseIcon } from 'bxcommon';

import { TOAST_TYPE } from '../../store/actions';

export const Toast = ({id, body: {type, title}, options, removeToast, children}) => {
  const [icon, setIcon] = useState(<div />);
  
  const dismiss = (always) => {
    if ((!always || typeof(always) !== 'boolean') && options.disableClick) {
      return;
    }
    removeToast(id);
  };
  
  useLayoutEffect(() => {
    switch (type) {
      case TOAST_TYPE.SUCCESS:
        setIcon(<BaseIcon icon="check" />);
        break;
      case TOAST_TYPE.WARNING:
        setIcon(<BaseIcon icon="warning" />);
        break;
      case TOAST_TYPE.ERROR:
        setIcon(<BaseIcon icon="error" />);
        break;
    }
  }, [type]);
  
  useEffect(() => {
    const { timeout } = options;
    let timeoutInstance;
    if (typeof(timeout) === 'number' && timeout > 0) {
      timeoutInstance = setTimeout(() => dismiss(true), timeout)
    }
    return () => {
      if (timeoutInstance) clearTimeout(timeoutInstance);
    }
  }, []);
  
  return (
    <div
      data-cypress="toast"
      className={classNames('toast', `toast--${type}`)}
    >
      {!options.disableClick &&
        <div
          className="toast__remove"
          onClick={dismiss}
        >
          <BaseIcon icon="times" />
        </div>
      }
      <div className="toast__icon">
        {icon}
      </div>
      <div className="toast__content">
        <div className="toast__title">{title}</div>
        <div className="toast__message">{children}</div>
      </div>
    </div>
  );
};

Toast.propTypes = {
  id: PropTypes.number.isRequired,
  body: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.oneOf(Object.values(TOAST_TYPE))
  }).isRequired,
  options: PropTypes.shape({
    timeout: PropTypes.number,
    disableClick: PropTypes.bool
  }).isRequired,
  children: PropTypes.node.isRequired,
  removeToast: PropTypes.func,
};
