import React from 'react';
import './BaseEnvironmentBar.scss';

export const BaseEnvironmentBar = () => {
  let allowedEnv = ['local', 'stage', 'test'];
  let environment = process.env.REACT_APP_ENV || null;

  return (
    <div className='environment-bar'>
      {environment && allowedEnv.includes(environment) && <p className="copy copy--color-dark">Environment: {environment}</p>}
    </div>
  );
};
