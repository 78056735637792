import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group'

import { Toast } from './Toast';
import { removeToast, TOAST_TYPE } from '../../store/actions';
import './Toaster.scss';


const mapStateToProps = (state) => ({toasts: state.toasts});

const Toaster = ({toasts, removeToast}) => {
  return (
    <div className="toasts-list">
      <CSSTransitionGroup
        transitionName="toast__transition"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={400}
      >
        {toasts.map(toast => (
          <Toast
            key={toast.id}
            {...toast}
            title={toast.body.title}
            removeToast={removeToast}
          >
            {toast.body.message}
          </Toast>
          ))}
      </CSSTransitionGroup>
    </div>
  )
};

Toaster.propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.shape({
    body: PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.oneOf(Object.values(TOAST_TYPE))
    }),
    options: PropTypes.shape({
      timeout: PropTypes.number,
      disableClick: PropTypes.bool
    })
  })).isRequired,
  removeToast: PropTypes.func.isRequired
};

export default connect(mapStateToProps, { removeToast })(Toaster);
