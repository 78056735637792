import { createReducer, initialStateMerger } from 'bxcommon/helpers/redux.helpers';
import { USER_GROUPS_ACTIONS } from '../actions/userGroups.actions';

const userGroupsInitialState = initialStateMerger([]);


export default (initialState) => {
  const initial = userGroupsInitialState(initialState);

  const userGroups = {
    [USER_GROUPS_ACTIONS.HYDRATE_USER_GROUPS]: (state, {payload}) => {
      return [...payload];
    },
    [USER_GROUPS_ACTIONS.REMOVE_FROM_USER_GROUPS]: (state, {payload}) => {
      return state.filter(group => group.id !== payload);
    },
    [USER_GROUPS_ACTIONS.CLEAR_USER_GROUPS]: () => {
      return initial;
    }
  };

  return createReducer(userGroupsInitialState(initialState), userGroups);
}
