import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BaseLoader } from '..';
import { prepareDocumentUrl } from '../../misc/requests';
import { errorToast } from 'bxcommon/store/actions';

const DocumentDownload = ({match, errorToast}) => {
  const [loading, setLoading] = useState(false);
  const { t: common } = useTranslation('common');

  useEffect(() => {
    const getUrl = async () => {
      try {
        setLoading(true);
        const response = await prepareDocumentUrl(match.path, match.params.uuid);
        document.location.assign(process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_BASE_URL + response : response);
        setLoading(false)
      } catch (_) {
        errorToast(
          common('errors.documentDownload'),
          common('errorToastHeading')
        );
        setLoading(false);
      }
    };

    getUrl();
  }, [match.params.uuid]);

  return (
    <BaseLoader loading={loading} />
  )
};

export default connect(null, { errorToast })(DocumentDownload);
