import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './BaseLoaderSpinner.scss';

export const BaseLoaderSpinner = ({ className, small, large, white, isComponent }) => {
  const classes = classnames('base-loader-spinner', className, {
    'base-loader-spinner--white': white,
    'base-loader-spinner--small': small,
    'base-loader-spinner--large': large,
    'base-loader-spinner--component': isComponent,
  });

  return <div className={classes} data-cypress="loader" />
};


BaseLoaderSpinner.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  white: PropTypes.bool,
  isComponent: PropTypes.bool
};
