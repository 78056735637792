import React, {useContext, useState, useRef} from 'react'
import {Prompt} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import { ModalContext } from 'bxcommon/context/Modal.context';
import { MODAL_RESPONSE_TYPE } from 'bxcommon/components/BaseModal/BaseModal';

const RouteLeavingGuard = ({when, navigate}) => {
  const { t: common } = useTranslation('common');
  const { openConfirmModal } = useContext(ModalContext);

  const [confirmedNavigation, setConfirmNavigation] = useState(false);
  const lastLocation = useRef(null);

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && when) {
      lastLocation.current = nextLocation;

      openConfirmModal(common('warningToastHeading'), common('changesNotSaved'))
        .then((response) => {
          if (response === MODAL_RESPONSE_TYPE.ACCEPT) {
            handleConfirmNavigationClick()
          }
        });
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    if (lastLocation.current) {
      setConfirmNavigation(true);
      navigate(lastLocation.current.pathname)
    }
  };

  return (
    <Prompt
      when={when}
      message={handleBlockedNavigation}
    />
  )
};

export default (RouteLeavingGuard);
