import React, { lazy } from 'react';

const Add = lazy(() => import('bxcommon/components/Icons/Add'));
const ArrowDown = lazy(() => import('bxcommon/components/Icons/ArrowDown'));
const ArrowLeft = lazy(() => import('bxcommon/components/Icons/ArrowLeft'));
const ArrowRight = lazy(() => import('bxcommon/components/Icons/ArrowRight'));
const Calendar = lazy(() => import('bxcommon/components/Icons/Calendar'));
const Check = lazy(() => import('bxcommon/components/Icons/Check'));
const CurvedArrowDown = lazy(() => import('bxcommon/components/Icons/CurvedArrowDown'));
const CurvedArrowUp = lazy(() => import('bxcommon/components/Icons/CurvedArrowUp'));
const Csv = lazy(() => import('bxcommon/components/Icons/Csv'));
const Edit = lazy(() => import('bxcommon/components/Icons/Edit'));
const Delete = lazy(() => import('bxcommon/components/Icons/Delete'));
const Debt = lazy(() => import('bxcommon/components/Icons/Debt'));
const Derivative = lazy(() => import('bxcommon/components/Icons/CurvedArrowUp'));
const DesktopUpload = lazy(() => import('bxcommon/components/Icons/DesktopUpload'));
const Equity = lazy(() => import('bxcommon/components/Icons/Equity'));
const Error = lazy(() => import('bxcommon/components/Icons/Error'));
const FileTypeDoc = lazy(() => import('bxcommon/components/Icons/FileTypeDoc'));
const FileTypePdf = lazy(() => import('bxcommon/components/Icons/FileTypePdf'));
const FileTypePng = lazy(() => import('bxcommon/components/Icons/FileTypePng'));
const Help = lazy(() => import('bxcommon/components/Icons/Help'));
const Info = lazy(() => import('bxcommon/components/Icons/Info'));
const Mail = lazy(() => import('bxcommon/components/Icons/Mail'));
const No = lazy(() => import('bxcommon/components/Icons/No'));
const PaperAirplane = lazy(() => import('bxcommon/components/Icons/PaperAirplane'));
const Pencil = lazy(() => import('bxcommon/components/Icons/Pencil'));
const Phone = lazy(() => import('bxcommon/components/Icons/Phone'));
const QuestionCircleOutlined = lazy(() => import('bxcommon/components/Icons/QuestionCircleOutlined'));
const Search = lazy(() => import('bxcommon/components/Icons/Search'));
const Times = lazy(() => import('bxcommon/components/Icons/Times'));
const Warning = lazy(() => import('bxcommon/components/Icons/Warning'));
const Yes = lazy(() => import('bxcommon/components/Icons/Yes'));
const AutoRenew = lazy(() => import('bxcommon/components/Icons/AutoRenew'));
const OpenFile = lazy(() => import('bxcommon/components/Icons/OpenFile'));

export default {
  add: <Add />,
  'arrow-down': <ArrowDown />,
  'arrow-left': <ArrowLeft />,
  'arrow-right': <ArrowRight />,
  calendar: <Calendar />,
  check: <Check />,
  'curved-arrow-down': <CurvedArrowDown />,
  'curved-arrow-up': <CurvedArrowUp />,
  csv: <Csv />,
  edit: <Edit />,
  error: <Error />,
  delete: <Delete />,
  debt: <Debt />,
  derivative: <Derivative />,
  'desktop-upload': <DesktopUpload />,
  equity: <Equity />,
  'file-type-doc': <FileTypeDoc />,
  'file-type-pdf': <FileTypePdf />,
  'file-type-png': <FileTypePng />,
  help: <Help />,
  info: <Info />,
  mail: <Mail />,
  no: <No />,
  'paper-airplane': <PaperAirplane />,
  pencil: <Pencil />,
  phone: <Phone />,
  'question-circle-outlined': <QuestionCircleOutlined />,
  search: <Search />,
  times: <Times />,
  warning: <Warning />,
  yes: <Yes />,
  autoRenew: <AutoRenew />,
  openFile: <OpenFile />
};
