import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { BaseBox, BaseBoxHeading, BaseButtonFill, BaseHint, RequestStepWrapper } from 'bxcommon';
import { RequestContext } from 'bxcommon/context/Request.context';
import { RequestDataWrapper } from 'bxcommon/components';
import {
  createSelect,
  createUploadBox,
  requiredFilesValidator,
} from 'bxcommon/helpers/form.helper';

import { CSVDownload, deleteAdditionalFiles, patchAdditionalFiles } from '../../common/requests';
import { removeExtensionFromFileName, validateIsin } from '../../common/common.helpers';
import { ProspectusHeader } from '../ProspectusHeader/ProspectusHeader';
import { STATUS } from 'bxcommon/constants/status.constant';
import config from '../../prospectus.config';
import { connect } from 'react-redux';
import { errorToast, successToast } from "bxcommon/store/actions";

import './FinalTerms.scss';

const {maxFileSize, ADDITIONAL_FILE_TYPES} = config;
const FinalTerms = (props) => {
  const {request, resources: {final_term_type}} = useContext(RequestContext);
  const {t} = useTranslation();
  const {t: common} = useTranslation('common');
  const formRef = useRef(null);
  const uploadInputRef = useRef(null);
  const {
    history,
    successToast,
    errorToast
  } = props;

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (request.case_id && (request.status !== STATUS.APPROVED || request.issue_type !== 5)) {
      history.push('/list');
    }
  }, [request.case_id, request.status, request.issue_type, history]);

  const renderUploadBox = createUploadBox(() => {
  }, formRef);
  const initialValues = {
    uploadType: 1,
    files: []
  };

  const validationSchema = Yup.object().shape({
    uploadType: Yup.mixed().required(common('errors.requiredField')),
    files: requiredFilesValidator()
  });

  const onFormSubmit = async () => {
    setDisabled(true);
    let results = await uploadInputRef.current.manualUpload(ADDITIONAL_FILE_TYPES.FINAL_TERMS);
    const withError = results.some(file => file.status === STATUS.ERROR);

    try {
      if (withError) {
        await deleteAdditionalFiles(request.case_id, ADDITIONAL_FILE_TYPES.FINAL_TERMS);
        errorToast(
          common(`request.form.errors.upload.backendErrorDuringUpload.description`),
          common(`request.form.errors.upload.backendErrorDuringUpload.title`),
        )
      } else {
        await patchAdditionalFiles(request.case_id, ADDITIONAL_FILE_TYPES.FINAL_TERMS);
        successToast(
          common('request.form.common.allFilesUploadedSuccessfully'),
          common('request.form.common.success')
        )
      }
    } catch (e) {
      errorToast(
        common(`request.form.errors.upload.backendErrorDuringUpload.description`),
        common(`request.form.errors.upload.backendErrorDuringUpload.title`),
      )
    } finally {
      setDisabled(false);
    }
  };

  return (
    <ProspectusHeader>
      <div className="final-terms">
        <RequestDataWrapper
          redirect={'/list'}
          {...props}
        >
          <RequestStepWrapper
            noSteps
            hidden
            goBack={`../details/${request.case_id}`}
            {...props}
          >
            <>
              <BaseBox
                className="request-form__box"
                lifted
              >
                <BaseBoxHeading>{t('finalTerms.headings.upload')}</BaseBoxHeading>
                <Formik
                  ref={formRef}
                  initialValues={initialValues}
                  onSubmit={() => onFormSubmit()}
                  validationSchema={validationSchema}
                >
                  {({values}) => (
                    <Form className="form-container">
                      <div className="row">
                        <div className="col-10">
                          {
                            createSelect({
                              name: 'uploadType',
                              label: t('finalTerms.uploadType'),
                              options: final_term_type.data,
                            })
                          }
                        </div>
                      </div>

                      <div className="row request-form--with-close-hint final-terms__upload">
                        <div className="col-10">
                          {renderUploadBox({
                            name: 'files',
                            conditions: common('request.form.common.fileDefaultsPDF'),
                            type: values.uploadType,
                            maxFileSize,
                            config: {
                              shouldUploadManually: true,
                              multiple: true,
                              customValidators: [{
                                field: 'name',
                                fieldMapper: (field) => removeExtensionFromFileName(field),
                                validatorFn: validateIsin
                              }],
                              extraFieldsMapper: {'isin_number': (file) => removeExtensionFromFileName(file.name)},
                              showFilesWithErrors: true
                            },
                            uploadInputRef
                          })}
                          <BaseHint>
                            {t('finalTerms.fileUploadInfo')}
                          </BaseHint>

                          <BaseButtonFill
                            type="submit"
                            className="base-upload-box__submit-button"
                            loading={disabled}
                            disabled={disabled}
                            data-cypress="submit-button"
                          >
                            {common('submit')}
                          </BaseButtonFill>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </BaseBox>
              <BaseBox
                className="request-form__box"
                lifted
              >
                <BaseBoxHeading>{t('finalTerms.headings.summary')}</BaseBoxHeading>
                <div className="copy copy--color-dark">
                  {t('finalTerms.csvDescription')}
                </div>
                <div>
                  <BaseButtonFill
                    className="final-terms__download-button"
                    onClick={() => CSVDownload(request.case_id)}
                  >
                    {t('finalTerms.csvButton')}
                  </BaseButtonFill>
                </div>
              </BaseBox>
            </>
          </RequestStepWrapper>
        </RequestDataWrapper>
      </div>
    </ProspectusHeader>
  );
};

export default connect(null, {errorToast, successToast})(FinalTerms);
