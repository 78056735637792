import axios from 'axios';

import { history } from '../BxBrowserRouter';
import { errorToast, logout } from '../store/actions';
import i18n from '../i18n';
import authHelper from 'bxcommon/helpers/auth.helper';
import errorService, {ErrorService, AUTHENTICATION_ERRORS} from 'bxcommon/helpers/errors.helper';


export default (store, publicRoutes = []) => {
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response && error.response.status === 500) {
      console.error(error);

      // Logout if we don't have critical data for the app to operate
      if (['resources', 'countries'].some(i => error.response.config.url.includes(i))) {
        store.dispatch(logout());
        history.push('/login');
      }
      store.dispatch(errorToast(
        i18n.t('common:errors.tryAgain'),
        i18n.t('common:errors.serverError')
      ));
    }

    if (error.response && (error.response.status === 401 || error.response.status === 403)) {

      // If we're on a public route - ignore log out flow
      if (publicRoutes.includes(history.location.pathname)) {
        return Promise.reject(error);
      }

      try {
        if (
          [ErrorService.ERRORS.SESSION_EXPIRED, ErrorService.ERRORS.NOT_AUTHENTICATED]
            .includes(error.response.data.code)
        ) {
          errorService.handleErrorToast(error, AUTHENTICATION_ERRORS);
          authHelper.removeToken();
          store.dispatch(logout());
          history.push('/login');
        }
      } catch(e) {
        console.error('Error in response interceptor:', e)
      }
    }

    return Promise.reject(error);
  });
};
