export const initialState = {
  listLoading: false,
  deleteLoading: false,
  activeItemIndex: null
};

export const REDUCER_TYPES = {
  SET_LIST_LOADING: 'setListLoading',
  SET_DELETE_LOADING: 'setDeleteLoading',
  SET_ACTIVE_ITEM_INDEX: 'setActiveItemIndex'
};

export default (state, action) => {
  switch (action.type) {
    case REDUCER_TYPES.SET_LIST_LOADING:
      return {...state, listLoading: action.payload};
    case REDUCER_TYPES.SET_DELETE_LOADING:
      return {...state, deleteLoading: action.payload};
    case REDUCER_TYPES.SET_ACTIVE_ITEM_INDEX:
      return {...state, activeItemIndex: action.payload};
    default:
      throw new Error();
  }
};
