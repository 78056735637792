import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { debounce } from '../../helpers/common.helpers';
import AppConfig from '../../providers/AppConfig';
import { BaseLoaderSpinner } from '..';

const { Option } = Select;

export const BaseFetchSelect = (props) => {
  const {field,  onBlur, onChange, queryUrl, exclude, ...rest} = props;

  const [lastFetchId, updateFetchId] = useState(0);
  const [value, setValue] = useState({key: '', value: ''});
  const [data, setData] = useState({ response: [], fetching: false });

  const { t } = useTranslation();

  const incrementFetchId = () => updateFetchId(id => id + 1);

  useEffect(() => {
    if (!field.value || !field.value.label) {
      setValue({key: '', value: ''});
    }
  }, [field.value]);

  const fetchUser = debounce(value => {
    if (value.length < 2) {
      return;
    }

    incrementFetchId();
    const fetchId = lastFetchId;
    setData({ response: [], fetching: true });

    axios(`${queryUrl}${value}`)
      .then(body => {
        if (fetchId !== lastFetchId) {
          return;
        }
        const filterOut = exclude.map(e => e.key);
        const response = body.data.data
          .filter(user => !filterOut.includes(user.id))
          .map(user => ({
            key: user.id,
            label: `${user.first_name} ${user.last_name}`,
            email: user.email
          }));

        setData({ response, fetching: false });
      });
  }, AppConfig.searchDebounceTime);

  const change = value => {
    const valueWithEmail = {
      key: value.key,
      // Ant design returns array if label is with spaces (check line 92 between tags)
      label: value.label[0],
      email: value.label[2]
    };

    setData({
      response: [],
      fetching: false
    });
    setValue(valueWithEmail);
    onChange(valueWithEmail);
  };

  return (
    <Select
      {...field}
      {...rest}
      showSearch
      labelInValue
      value={value}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      placeholder={t('userGroups.edit.selectUsers')}
      notFoundContent={data.fetching ? <BaseLoaderSpinner small isComponent /> : null}
      onSearch={fetchUser}
      onChange={change}
      loading={data.fetching}
      dropdownClassName="base-fetch-select-dropdown"
    >
      {data.response.map(d => (
        <Option key={d.key} value={d.key}>{d.label} - {d.email}</Option>
      ))}
    </Select>
  );
};
