import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BaseLoaderSpinner } from '..';
import icons from 'bxcommon/components/Icons';
import './BaseIcon.scss';

export const BaseIcon = props => {
  const classes = classnames('base-icon', props.className, {
    'base-icon--small': props.small,
    'base-icon--large': props.large,
    'base-icon--xlarge': props.xlarge,
    'base-icon--xxlarge': props.xxlarge,
    'base-icon--xxxlarge': props.xxxlarge,
    'base-icon--white': props.white,
    'base-icon--gray': props.gray,
    'base-icon--dark': props.dark,
    'base-icon--clickable': props.clickable
  });

  return (
    <Suspense fallback={<span />}>
      <div className={classes} onClick={props.onClick} data-cypress={props['data-cypress']}>
        {props.loading ? <BaseLoaderSpinner isComponent /> : icons[props.icon]}
      </div>
    </Suspense>
  );
};

BaseIcon.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  white: PropTypes.bool,
  gray: PropTypes.bool,
  dark: PropTypes.bool,
  clickable: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};
