import { createReducer, initialStateMerger } from 'bxcommon/helpers/redux.helpers';
import { LIST_ACTIONS } from '../actions/list.actions';


const listInitialState = initialStateMerger([]);


export default (initialState) => {

  const initial = listInitialState(initialState);

  const list = {
    [LIST_ACTIONS.HYDRATE_LIST]: (state, {payload}) => {
      return [...payload];
    },
    [LIST_ACTIONS.ADD_TO_LIST]: (state, {payload}) => {
      return [...state, payload];
    },
    [LIST_ACTIONS.UPDATE_ON_LIST]: (state, {payload}) => {
      const index = state.findIndex(item => item.case_id === payload.case_id);
      return [...state.slice(0, index), payload, ...state.slice(index + 1)];
    },
    [LIST_ACTIONS.REMOVE_FROM_LIST]: (state, {payload}) => {
      return state.filter(listItem => listItem.case_id !== payload);
    },
    [LIST_ACTIONS.CLEAR_LIST]: () => {
      return initial;
    }
  };

  return createReducer(initial, list);
}
