export const PROGRESS_STATUS_OPTIONS = {
  ACTIVE: 'active',
  SUCCESS: 'success',
  EXCEPTION: 'exception',
};

export const UPLOAD_ACTIONS_OPTIONS = {
  ADD: 'add',
  REPLACE: 'replace'
};

export const acceptMultipleFileTypes = `
  application/pdf,
  image/png,
  image/jpg,
  image/jpeg,
  application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document
`;

export const acceptPdfOnly = 'application/pdf';
