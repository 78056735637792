import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { STATUS } from '../../constants/status.constant';

import './BaseStatusBar.scss';

export const BaseStatusBar = props => {
  const { title, description, statusText, status, registrationOfficerComment } = props;

  const classes = classnames('base-status-bar', `base-status-bar--${statusText}`);
  return (
    <div className={classes} data-cypress="status-bar">
      <div className="container">
        <div className="row">
          <div className="col-2" />
          <div className="col-8">
            <p className="copy copy--color-dark copy--bold" data-cypress="status-bar-title">
              {title}
            </p>
            <p className="copy copy--color-dark base-status-bar__description">
              {status === STATUS.UPDATE_REQUIRED && registrationOfficerComment
                ? registrationOfficerComment
                : description}
            </p>
          </div>
          <div className="col-2" />
        </div>
      </div>
    </div>
  );
};

BaseStatusBar.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  registrationOfficerComment: PropTypes.string
};
