import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { AppHeaderDefault, BaseBox } from 'bxcommon';
import { HalfPageLayout } from 'bxcommon/components/HalfPageLayout/HalfPageLayout';

import './UserCommonView.scss';

const UserCommonView = props => {
  const { children, heading, subheading, initialFormValues, onSubmit, validationSchema, isAdvisor, leftContent = null } = props;

  const Content = () => (
    <div className="row">
      <div className="col-2" />
      <div className="col-8">
        <div className="user-common-view__title">
          <h1 className="heading heading--primary heading--color-primary">{ heading }</h1>
          <p>{ subheading }</p>
        </div>
        <Formik
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <BaseBox
            className="user-common-view__box"
            borderless
          >
            <Form className="form-container">
              { children }
            </Form>
          </BaseBox>
        </Formik>
      </div>
      <div className="col-2" />
    </div>
  );

  return (
    <AppHeaderDefault isAdvisor={isAdvisor}>
      <div className="user-common-view__content">
        <HalfPageLayout leftContent={leftContent} rightContent={<Content />} />
      </div>
    </AppHeaderDefault>
  );
};

UserCommonView.propTypes = {
  children: PropTypes.array.isRequired,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  isAdvisor: PropTypes.bool,
  initialFormValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
  leftContent: PropTypes.node
};

export default UserCommonView;
