import { createReducer, initialStateMerger } from 'bxcommon/helpers/redux.helpers';
import { RESOURCES_ACTIONS } from '../actions/resources.actions';

const resourcesInitialState = initialStateMerger({});

export default (initialState) => {

  const resources = {
    [RESOURCES_ACTIONS.HYDRATE]: (state, {payload}) => {
      return Object.assign({}, state, payload);
    }
  };

  return createReducer(resourcesInitialState(initialState), resources);
}
