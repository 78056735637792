import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BaseIcon, BaseLoaderSpinner } from '..';

import './BaseButton.scss';

export const BaseButton = props => {
  const { loading = false, ...rest } = props;

  const classes = classnames('base-button', rest.className, {
    'base-button--fill': rest.fill,
    'base-button--outline': rest.outline,
    'base-button--link': rest.link,
    'base-button--small': rest.small,
    'base-button--large': rest.large,
    'base-button--left': rest.left,
    'base-button--disabled': rest.disabled,
    'base-button--full': rest.full,
    'base-button--active': !loading && !rest.hidden,
    'base-button__category': rest.category,
    'base-button__intro': rest.intro
  });

  const loaderClasses = classnames('base-button__loader', {
    'base-button__loader--visible': loading
  });

  const contentClasses = classnames('base-button__content', {
    'base-button__content--visible': !loading && !rest.hidden
  });

  const createIcon = className => (
    <BaseIcon
      className={classnames('base-button__icon', className)}
      icon={rest.icon}
      small={rest.small}
      large={rest.large}
      white={rest.fill || rest.iconWhite}
      gray={rest.iconGray}
    />
  );

  return (
    <button
      className={classes}
      type={rest.type}
      disabled={rest.disabled}
      style={rest.style}
      onClick={rest.hidden ? null : rest.onClick}
      data-cypress={rest['data-cypress']}
    >
      {loading && <BaseLoaderSpinner isComponent white={rest.fill} className={loaderClasses} small={rest.small} />}
      <div className={contentClasses}>
        {rest.iconLeft && createIcon('icon--left')}
        <span>{rest.children}</span>
        {rest.iconRight && createIcon('icon--right')}
      </div>
    </button>
  );
};

BaseButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fill: PropTypes.bool,
  outline: PropTypes.bool,
  link: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  left: PropTypes.bool,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.bool,
  iconRight: PropTypes.bool,
  iconGray: PropTypes.bool,
  iconWhite: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  full: PropTypes.bool,
  hidden: PropTypes.bool,
  category: PropTypes.bool,
  intro: PropTypes.bool
};
