import React, { useCallback, useContext, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import { Checkbox } from 'antd';

import AppConfig from '../../providers/AppConfig';
import { history } from '../../BxBrowserRouter';
import { BaseButtonFill, BaseIcon, BaseSearch, BaseTable, HowToStart, MODAL_RESPONSE_TYPE } from '..';
import { ModalContext } from '../../context/Modal.context';
import { updateRequestField } from '../../store/actions';
import reducer, { initialState, REDUCER_TYPES } from './ListViewWrapper.reducer';

import SearchFormModal from 'registry/src/components/ListView/SearchFormModal';
import { getCsv } from 'registry/src/store/actions/request.actions';

import './ListViewWrapper.scss';

const ListViewWrapper = props => {
  const {
    isAdmin,
    list,
    labels,
    editUrl,
    detailsUrl,
    removeFromList,
    children,
    updateRequestField,
    isProspectus,
    groupDetails,
    search: { queryName, hydrateList, placeholder },
    selectable,
  } = props;
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const { openConfirmModal } = useContext(ModalContext);

  const [{ listLoading, deleteLoading, activeItemIndex }, updateState] = useReducer(reducer, initialState);

  const updateRequest = useCallback((caseId) => {
    updateRequestField(list.find(entry => entry.case_id === caseId));
  }, [list, updateRequestField]);

  const goToDetails = useCallback((item) => {
    updateRequest(item.case_id);
    if (AppConfig.editStatuses.includes(item.status)) {
      goToEdit(item)
    } else {
      history.push(`/${detailsUrl}/${item.case_id}`)
    }
  }, [history, updateRequest]);

  const goToEdit = useCallback((listItem) => {
    updateRequest(listItem.case_id);
    if (isProspectus) {
      history.push(`/request/${editUrl[listItem.security_type]}/${listItem.case_id}`)
    } else {
      history.push(`/${editUrl}/${listItem.case_id}`)
    }
  }, [history, updateRequest]);

  const showSpinner = useCallback((index) => {
    return deleteLoading && index === activeItemIndex;
  }, [deleteLoading, activeItemIndex]);

  const deleteRequest = async (caseId, index) => {
    const response = await openConfirmModal(
      common('request.cancelModal.title'),
      common('request.cancelModal.description'),
      { okButton: common('request.cancelModal.okButton') }
    );
    if (response === MODAL_RESPONSE_TYPE.ACCEPT) {
      try {
        updateState({ type: REDUCER_TYPES.SET_ACTIVE_ITEM_INDEX, payload: index });
        updateState({ type: REDUCER_TYPES.SET_DELETE_LOADING, payload: true });

        await removeFromList(caseId);
      } finally {
        updateState({ type: REDUCER_TYPES.SET_ACTIVE_ITEM_INDEX, payload: null });
        updateState({ type: REDUCER_TYPES.SET_DELETE_LOADING, payload: false });
      }
    }
  };

  const search = async (query) => {
    updateState({ type: REDUCER_TYPES.SET_LIST_LOADING, payload: true });
    await hydrateList(query, queryName);
    updateState({ type: REDUCER_TYPES.SET_LIST_LOADING, payload: false });
  };

  const clearSearchFilters = () => {
    setCurrentFiltersFormatted('')
    setFilteredList(list);
  }

  const downloadCsv = async () => {
    await props.getCsv({ case_ids: selected }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `register_report_${new Date().toLocaleDateString()}.csv`);
      document.body.appendChild(link);
      link.click();
    });
  }

  const onSelectAll = () => {
    if (selected.length === filteredList.length) {
      setSelected([]);
    } else {
      const elemsToSelect = filteredList.filter(elem => !selected.includes(elem.case_id)).map(elem => elem.case_id);
      setSelected([...selected, ...elemsToSelect])
    }
    setSelectAll(!selectAll);
  }

  const selectItem = (id) => {
    let newSelected;
    if (selected.includes(id)) {
      newSelected = selected.filter(s => s !== id)
    } else {
      newSelected = [...selected, id];
    }
    setSelected(newSelected)
    setSelectAll(newSelected.length === filteredList.length)
  }

  const onSearchIconClick = () => {
    setSelected([]);
    setSelectAll(false);
    setSearchModalVisible(true)
  }

  const [selectAll, setSelectAll] = useState(null);
  const [selected, setSelected] = useState([]);
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [currentFiltersFormatted, setCurrentFiltersFormatted] = useState('');
  const [filteredList, setFilteredList] = useState(list);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="list__wrapper">
            <div className="list__heading-wrapper">
              <h1
                className={classnames('heading', {
                  'heading--hero': !isAdmin,
                  'heading--secondary': isAdmin
                })}
                data-cypress="list-view-heading"
              >
                {t('list.heading')} ({filteredList.length})
              </h1>
              {isAdmin && isProspectus && (
                <div className="col-3">
                  <BaseSearch
                    placeholder={t(placeholder)}
                    onChange={search}
                    data-cypress="list-view-search-input"
                  />
                </div>
              )}

              {isAdmin && !isProspectus && (
                <div className="col-3 list__action-buttons">
                  <BaseIcon clickable icon="csv" onClick={downloadCsv}/>
                  <BaseIcon clickable icon="search" onClick={onSearchIconClick}/>
                  <SearchFormModal
                    visible={searchModalVisible}
                    setSearchModalVisible={setSearchModalVisible}
                    setFilteredList={setFilteredList}
                    clearSearchFilters={clearSearchFilters}
                    setCurrentFiltersFormatted={setCurrentFiltersFormatted}
                  />
                </div>
              )}
            </div>

            {currentFiltersFormatted.length > 0 && (
              <div className="list__filters">
                <div className="list__filters--list">
                  <p>{t('request.searchForm.currentFilters')}</p>
                  <p dangerouslySetInnerHTML={{ __html: currentFiltersFormatted }}/>
                </div>
                <div className="list__filters--clear-btn">
                  <BaseButtonFill small onClick={clearSearchFilters}>
                    {t('request.searchForm.clearSearchFilters')}
                  </BaseButtonFill>
                </div>
              </div>
            )}

            {isProspectus && groupDetails &&
            <h2
              className="heading heading--subsection"
              data-cypress="list-view-group-title"
            >
              {t('list.partOfGroup')}: {groupDetails.name}
            </h2>
            }
            {Boolean(filteredList.length) ? (
              <div
                className="list"
                data-cypress="list-view-list"
              >
                <BaseTable
                  labels={labels
                    .filter(l => l !== null)
                    .map(label => label ? t(`list.labels.${label}`) : '')}
                  loading={listLoading}
                  selectable={!isProspectus}
                  selectAll={selectAll}
                  onSelectAll={onSelectAll}
                >
                  {filteredList
                    .sort((a, b) => a.last_edited > b.last_edited ? -1 : 1)
                    .map((item, index) => (
                      <tr
                        key={item.case_id}
                        data-cypress="list-item"
                      >
                        {selectable && (<td>
                          <div>
                            <Checkbox
                              name="selectable"
                              checked={selected.includes(item.case_id)}
                              onChange={() => {
                                selectItem(item.case_id)
                              }}
                            />
                          </div>
                        </td>)}
                        <td
                          className="copy copy--color-dark td-date"
                          data-cypress="list-last-edited"
                        >
                          {moment(item.last_edited).format(AppConfig.dayMonthHourFormat)}
                        </td>
                        <td
                          className="copy copy--color-dark"
                          data-cypress="list-case-id"
                        >
                          {item.case_id}
                        </td>

                        {children(item)}

                        <td>
                          <div className="list__controls">
                            <>
                              {AppConfig.deleteStatuses.includes(item.status) && (
                                <BaseIcon
                                  clickable
                                  onClick={() => deleteRequest(item.case_id, index)}
                                  icon="delete"
                                  data-cypress="list-delete-button"
                                  loading={showSpinner(index)}
                                />
                              )}
                              {AppConfig.editStatuses.includes(item.status) && (
                                <BaseIcon
                                  clickable
                                  onClick={() => goToEdit(item)}
                                  icon="edit"
                                  data-cypress="list-edit-button"
                                />
                              )}
                            </>

                            <BaseIcon
                              clickable
                              onClick={() => goToDetails(item)}
                              icon="arrow-right"
                              data-cypress="list-details-button"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </BaseTable>
              </div>
            ) : (
              <div className="list__empty">
                {
                  Object.keys(currentFiltersFormatted).length > 0 ? t('list.emptyFiltered') : t('list.empty')
                }
              </div>
            )}
          </div>
          {isProspectus && <HowToStart/>}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: state.list,
  isAdmin: state.user.details.is_admin,
  groupDetails: state.user.details.group
});

ListViewWrapper.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  isAdmin: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string),
  editUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  detailsUrl: PropTypes.string,
  search: PropTypes.shape({
    queryName: PropTypes.string,
    hydrateList: PropTypes.func,
    placeholder: PropTypes.string
  }),
  removeFromList: PropTypes.func,
  children: PropTypes.func.isRequired,
  updateRequestField: PropTypes.func,
  isProspectus: PropTypes.bool,
  groupDetails: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })
};

export default connect(mapStateToProps, { updateRequestField, getCsv })(ListViewWrapper);
