import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import { getKey } from '../../helpers/common.helpers';
import './BaseFormFieldWrapper.scss';

const FormItem = Form.Item;

export const BaseFormFieldWrapper = (Component) => props => {
  const {field, form, label, onChange, ...rest} = props;

  const touched = getKey(form.touched, field.name);
  const hasError = getKey(form.errors, field.name);

  const touchedError = hasError && touched;

  const onBlur = () => form.setFieldTouched(field.name, true);

  const handleChange = value => {
    form.setFieldValue(field.name, value);

    if (onChange) {
      onChange(value, field.name);
    }
  };

  return (
    <div className="field-container">
      <FormItem
        label={label}
        help={touchedError ? hasError : false}
        validateStatus={touchedError ? "error" : "success"}
      >
        <Component
          {...field}
          {...rest}
          onBlur={onBlur}
          handleChange={handleChange}
        />
      </FormItem>
    </div>
  );
};

BaseFormFieldWrapper.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any
  }).isRequired,
  // not checking for shape of form because it is passed through Formik.
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
};
