import {createReducer, initialStateMerger} from 'bxcommon/helpers/redux.helpers';
import { REGISTRY_ACTIONS } from '../actions/registry.actions';

const registryInitialState = initialStateMerger({result: []});

export default (initialState) => {
  const registry = {
    [REGISTRY_ACTIONS.HYDRATE_RESULT]: (state, {payload}) => {
      return Object.assign({}, state, { result: payload })
    }
  };

  return createReducer(registryInitialState(initialState), registry);
}
