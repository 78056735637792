import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ReactComponent as Check } from 'bxcommon/assets/images/check-plain.svg';
import { ReactComponent as Times } from 'bxcommon/assets/images/times.svg';
import { BaseBox, BaseBoxHeading, SummaryCombinedValue, SummaryInput, SummaryWrapper } from 'bxcommon';
import { SummaryFilesList } from 'bxcommon/components/SummaryHelpers/SummaryFiles';
import AppConfig from 'bxcommon/providers/AppConfig';

import 'bxcommon/components/SummaryHelpers/SummaryHelpers.scss';


export const ProspectusSummary = (props) => {
  const { request } = props;
  const { t } = useTranslation();

  const formatDate = (value) => value ? moment(value).format(AppConfig.defaultDateFormat) : value;
  const isAlreadyPublished = Boolean(request.already_published_location || request.already_published_date);

  return (
    <SummaryWrapper
      heading={t('request.summary.prospectus')}
      buttonText={t('request.summary.editProspectus')}
      {...props}
    >

      {/* Basic Information*/}
      <BaseBox borderless className="base-summary__section">
        <>
          <BaseBoxHeading className="base-summary__subheading">
            {t('request.form.prospectus.basicInformation.place_of_publication')}
          </BaseBoxHeading>
          <ul className="base-summary__content">
            <SummaryInput
              name={t('request.summary.regservice')}
              data-cypress="summary-publish-on"
            >
              <SummaryCombinedValue
                condition={Boolean(request.publish_here)}
                subtitle={t('request.form.prospectus.basicInformation.publish_on')}
                subvalue={formatDate(request.publish_on)}
              >
                {request.publish_here ? <Check /> : <Times />}
              </SummaryCombinedValue>
            </SummaryInput>
            <SummaryInput
              name={t('request.summary.alreadyPublished')}
            >
              <SummaryCombinedValue
                condition={isAlreadyPublished}
                subtitle={t('request.summary.alreadyPublished')}
                subvalue={formatDate(request.already_published_date)}
              >
                <span className="base-summary--margin">
                  {isAlreadyPublished ? <Check /> : <Times />}
                </span>
                <span
                  className="base-summary--margin"
                  data-cypress="summary-already-published-location"
                >
                  {isAlreadyPublished && request.already_published_location}
                </span>
              </SummaryCombinedValue>
            </SummaryInput>
            <SummaryInput
              name={t('request.form.prospectus.basicInformation.paper_version')}
              data-cypress="summary-paper-version"
            >
              {request.paper_version}
            </SummaryInput>
          </ul>
        </>
      </BaseBox>

      <BaseBox borderless className="base-summary__section">
        <>
          <BaseBoxHeading className="base-summary__subheading">
            {t('request.form.prospectus.typeOfProspectus.heading')}
          </BaseBoxHeading>
          <ul className="base-summary__content">
            {/* todo: remove MOCK on files (first one possibly out) */}
            <SummaryInput
              name={t('request.summary.uploadPdf')}
            >
              <SummaryFilesList files={request.prospectus_files} />
            </SummaryInput>

            <SummaryInput
              name={t('request.summary.uploadIncorporated')}
            >
              <SummaryFilesList files={request.incorporated_files} />
            </SummaryInput>

            <SummaryInput
              name={t('request.form.prospectus.typeOfProspectus.uploadAdditionalFiles')}
            >
              <SummaryFilesList files={request.additional_files} />
            </SummaryInput>
          </ul>
        </>
      </BaseBox>

    </SummaryWrapper>
  )
};

ProspectusSummary.propTypes = {
  request: PropTypes.object
};
