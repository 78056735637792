import React from 'react';
import PropTypes from 'prop-types';

import { BaseButtonOutline } from '..';
import { searchForLabel } from '../../helpers/form.helper';

import './SummaryHelpers.scss';

export const SummaryInput = ({name, condition = true, children, ...rest}) => {
  const isFn = typeof(children) == 'function';

  return condition ? (
    <li className="base-summary__item row">
      <span className="col-6 base-summary__item-title">{name}</span>
      <span
        className="col-6 base-summary__item-value"
        data-cypress={rest['data-cypress']}
      >
        {isFn ? children() : children || '-'}
      </span>
    </li>
  ) : null
};

SummaryInput.propTypes = {
  name: PropTypes.string,
  condition: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};


export const SummaryCombinedValue = ({condition, subtitle, subvalue, children}) =>
  (
    <span className="base-summary__combined-value">
      <span>{children}</span>
      {condition &&
        <span className="base-summary__combined-value-wrapper">
          <span className="combined-value__subtitle">{subtitle}</span>
          <span className="combined-value__subvalue">{subvalue}</span>
        </span>
      }
    </span>
  );

SummaryCombinedValue.propTypes = {
  condition: PropTypes.bool,
  subtitle: PropTypes.string,
  subValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node
};

export const SummaryWrapper = (props) => {
  return (
    <div className="base-summary__section-wrapper">
      <div className="base-summary__section-heading">
        <h1 className="heading heading--bold heading--color-primary heading--subsection">
          {props.heading}
        </h1>
        {/* TODO: move change logic to HOC / wrapper */}
        {props.step &&
          <BaseButtonOutline
            onClick={() => props.goToStep(props.step)}
            data-cypress="summary-edit-button"
          >
            {props.buttonText}
          </BaseButtonOutline>
        }
      </div>
      {props.children}
    </div>
  )
};


SummaryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  goToStep: PropTypes.func,
  step: PropTypes.number
};

export const summaryValuesIterator = (options, valuesArray) => valuesArray && valuesArray.length
  ? <ul className="base-summary__values-list">
      {
        valuesArray.map((value, idx) =>
          <li key={idx}>
            {searchForLabel(options, value)}
          </li>
        )
      }
    </ul>
  : '-';
