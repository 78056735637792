import React, {useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { logout, warningToast } from 'bxcommon/store/actions';
import { logout as logoutRequest } from 'bxcommon/misc/requests';
import { AppHeader, BaseButtonLink, BaseButtonOutline } from 'bxcommon';

import './AppHeaderDefault.scss';

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn,
  isAdmin: state.user.details.is_admin
});

const AppHeaderDefault_ = (props) => {
  const {
    logout,
    loggedIn,
    isAdmin,
    history,
    children,
    warningToast,
    isAdvisor,
    adminHeader,
    controls,
    hideControls
  } = props;
  const { t: common } = useTranslation('common');

  const [logoutLoading, setLogoutLoading] = useState(false);
  const navigateToRoute = (name) => {
    history.push(name)
  };

  const handleLogout = async() => {
    setLogoutLoading(true);

    try {
      await logoutRequest();
      logout();

      history.push(isAdmin ? '/admin' : '/login')
    } catch (_) {
      warningToast(common('login.unableToLogoutContent'), common('login.unableToLogoutTitle'));
      setLogoutLoading(false);
    }
  };

  const HeaderCommonButton = isAdvisor ? BaseButtonOutline : BaseButtonLink;

  const navigationButton = (
    ['/login', '/admin'].includes(history.location.pathname) ? (
      <HeaderCommonButton
        onClick={() => navigateToRoute('/register')}
        data-cypress="header-register-button"
      >
        {common('header.register')}
      </HeaderCommonButton>
    ) : (
      <HeaderCommonButton
        onClick={() => navigateToRoute('/login')}
        data-cypress="header-login-button"
      >
        {common('header.login')}
      </HeaderCommonButton>
    )
  );

  return (
    <>
      <div
        className={classnames('app-header-default', {
          'app-header-default--admin': isAdmin && !hideControls
        })}
      >
        { isAdmin && !hideControls && adminHeader }
        <AppHeader>
          <div className="row">
            <div className="col-6">
              {controls}
            </div>
            {!hideControls &&
              <div className="col-6 app-header__logout">
                {loggedIn ? (
                  <BaseButtonLink
                    iconLeft
                    icon="arrow-right"
                    onClick={handleLogout}
                    data-cypress="header-logout-button"
                    loading={logoutLoading}
                  >
                    {common('header.logout')}
                  </BaseButtonLink>
                ) : navigationButton}
              </div>
            }
          </div>
        </AppHeader>
      </div>
      <div className="app-header__children">
        {children}
      </div>
    </>
  )
};


AppHeaderDefault_.propTypes = {
  logout: PropTypes.func,
  loggedIn: PropTypes.bool,
  history: PropTypes.object,
  isAdmin: PropTypes.bool,
  isAdvisor: PropTypes.bool,
  adminHeader: PropTypes.node,
  controls: PropTypes.node,
  hideControls: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export const AppHeaderDefault = withRouter(connect(mapStateToProps, { logout, warningToast })(AppHeaderDefault_));
