import React from 'react';
import PropTypes from 'prop-types';
import { BaseButton } from '../BaseButton/BaseButton';

export const BaseButtonFill = props => {
  const { children, ...rest } = props;

  return (
    <BaseButton
      fill
      {...rest}
    >
      {children}
    </BaseButton>
  );
};

BaseButtonFill.propTypes = {
  children: PropTypes.node.isRequired
};
