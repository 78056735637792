import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { BaseModal } from '../components';
import { generateDeferredPromise } from '../helpers/common.helpers';

let ModalContext;
const {
  Provider,
  Consumer
} = (ModalContext= React.createContext({}));

export const MODAL_TYPES = {
  WARNING: 'warning',
  QUESTION: 'help',
  INFO: 'info'
};

class ModalProvider extends React.Component {
  modalDefaults = {
    icon: MODAL_TYPES.INFO,
    okButton: this.props.t('common:defaultModal.okButton'),
    cancelButton: this.props.t('common:defaultModal.cancelButton'),
    result: null
  };

  state = {
    ...this.modalDefaults,
    description: '',
    customContent: false,
    title: '',
    visible: false,
    result: null,
    customClass: '',
  };

  openModal = (isConfirm, isCustom) => (title, description, config, customClass) => {
    if ([title, description].some(prop => !prop)) {
      throw new Error('Modal requires at least type, title & description provided');
    }

    config = config || {};

    const deffered = generateDeferredPromise();

    this.setState({
      icon: config.type || MODAL_TYPES.QUESTION,
      title,
      description: description,
      customContent: isCustom,
      okButton: config.okButton || this.modalDefaults.okButton,
      cancelButton: isConfirm ? (config.cancelButton || this.modalDefaults.cancelButton) : null,
      visible: true,
      customClass,
      result: deffered
    });

    return deffered.promise;
  };


  closeModal = (bool) => {
    this.state.result.resolve(bool);
    this.setState({visible: false});
  };

  render() {
    return (
      <Provider
        value={{
          openConfirmModal: this.openModal(true),
          openInfoModal: this.openModal(false),
          openCustomInfoModal: this.openModal(false, true)
        }}
      >
        <BaseModal
          customClass={this.state.customClass}
          response={this.closeModal}
          {...this.state}
        />
        {this.props.children}
      </Provider>
    );
  }
}

ModalProvider.propTypes = {
  children: PropTypes.node
};

const translatedProvider = withTranslation()(ModalProvider);

export {
  translatedProvider as ModalProvider,
  Consumer as ModalConsumer,
  ModalContext
};
