import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { BaseLoader } from 'bxcommon/index';
import { history } from 'bxcommon/BxBrowserRouter';

// Universal wrapper that provides loading of data that is required for a given component to run
const DataLoader = (props) => {
  const [loading, setLoading] = useState(true);
  const previousKey = useRef(null);
  const isMounted = useRef(false);

  const getData = useCallback(async () => {
    try {
      await Promise.all(props.load.map(action => action()))
    } finally {
      if (isMounted.current) {
        setLoading(false)
      }
    }
  }, [props.load]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (props.location.key !== previousKey.current) {
      setLoading(true);
      previousKey.current = props.location.key;
      getData(); //todo: add canceler that should be run from the unsubscribe (AFTER MVP)
    }
  }, [getData, props]);

  const {component: Component, ...rest} = props;

  return !loading
    ? <Component {...rest} />
    : <BaseLoader loading={loading} large />
};


DataLoader.propTypes = {
  load: PropTypes.arrayOf(PropTypes.func),
};

export const createRouteHelpers = (store) => ({
  PrivateRoute: ({component: Component, ...rest}) => {
    const userStore = store.getState().user;
    useEffect(() => {
      if (userStore.loggedIn && !userStore.details.is_admin && rest.adminOnly) {
        history.push('/');
      }
    }, [userStore]);

    const checkForLoader = (props) => {
      return rest.load && rest.load.length && rest.loadIf !== false
        ? <DataLoader component={Component} {...props} {...rest} />
        : <Component {...props} />;
    };

    // returnUrl needed for trasferring user back to document if id is provided.
    const returnUrl = rest.location.pathname ? rest.location.pathname.replace(/\//, '?') : '';

    return <Route {...rest} render={(props) => (
      store.getState().user.loggedIn === true
        ? checkForLoader(props)
        : <Redirect to={
            rest.location.search.includes('?admin=true')
              ? `/admin${returnUrl}`
              : `/login${returnUrl}`
          }/>
    )}/>
  },
  UnauthenticatedRoute: ({component: Component, render: RenderComponent, ...rest}) => (
    <Route {...rest} render={(props) => {
      if (store.getState().user.loggedIn === false) {
        return RenderComponent ? RenderComponent : <Component {...props} />
      } else {
        return <Redirect to="/"/>
      }
    }}/>
  )
});

createRouteHelpers().PrivateRoute.propTypes = {
  load: PropTypes.arrayOf(PropTypes.func),
  loadIf: PropTypes.bool,
  adminOnly: PropTypes.bool
};
