import axios from 'axios';

export const USER_GROUPS_ACTIONS = {
  HYDRATE_USER_GROUPS: 'hydrate_groups',
  REMOVE_FROM_USER_GROUPS: 'remove_from_groups',
  CLEAR_USER_GROUPS: 'clear_groups',
};

export const hydrateUserGroups = (id) => {
  return (dispatch) => {
    return axios.get(`/groups/${id ? `${id}/` : ''}`)
      .then(({data}) => {
        dispatch({
          type: USER_GROUPS_ACTIONS.HYDRATE_USER_GROUPS,
          payload: id ? [data.data] : data.data
        });
        return id ? [data.data] : data.data;
      });
  }
};

export const removeFromUserGroups = (id) => {
  return (dispatch) => {
    return axios.delete(`/groups/${id}/`)
      .then(() => {
        dispatch({
          type: USER_GROUPS_ACTIONS.REMOVE_FROM_USER_GROUPS,
          payload: id
        });
      });
  }
};

export const clearUserGroups = () => {
  return dispatch => {
    dispatch({type: USER_GROUPS_ACTIONS.CLEAR_USER_GROUPS});
  }
};
