import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { BaseIcon } from '..';
import { BaseFormFieldWrapper } from '../BaseFormFieldWrapper/BaseFormFieldWrapper'

export const BaseInputBasic = props => {
  const {field, onBlur, handleChange, inputHint, type, icon, value, deletable, ...rest} = props;
  const hint = inputHint && <div className="base-input__hint">{inputHint}</div>;

  const inputSuffix = useCallback(() => {
    if (deletable && value) {
      return (
        <BaseIcon
          icon="times"
          small
          onClick={() => handleChange('')}
        />
      )
    } else if (icon) {
      return <BaseIcon icon={icon} small />
    }
  }, [deletable, value, icon]);

  return (
    <>
      {
        React.createElement(type === 'password' ? Input.Password : Input,
        {
          ...field,
          ...rest,
          value,
          onBlur: onBlur,
          onChange: (e) => handleChange(e.target.value),
          suffix: icon && !deletable ? <BaseIcon icon={icon} small /> : inputSuffix()
        })
      }
      {hint}
    </>
  );
};

BaseInputBasic.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any
  }),
  onBlur: PropTypes.func,
  handleChange: PropTypes.func,
  inputHint: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  deletable: PropTypes.bool,
  type: PropTypes.string
};

export const BaseInput = BaseFormFieldWrapper(BaseInputBasic);
