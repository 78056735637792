import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

import { BaseLoaderSpinner } from 'bxcommon/index';
import { confirmAccount } from 'bxcommon/misc/requests';
import { successToast, warningToast } from 'bxcommon/store/actions';
import errorService, { ErrorService } from '../../helpers/errors.helper';

const CONFIRMATION_ERRORS = {
  [ErrorService.ERRORS.USER_ALREADY_ACTIVATED]: {
    type: 'warning',
    title: 'common:confirmAccount.errors.errorTitle',
    content: 'common:confirmAccount.errors.alreadyConfirmed'
  },
  [ErrorService.ERRORS.UNDECODABLE_VALUE]: {
    type: 'warning',
    title: 'common:confirmAccount.errors.errorTitle',
    content: 'common:confirmAccount.errors.wrongUserId'
  },
  [ErrorService.ERRORS.INVALID_EMAIL_CONFIRMATION_TOKEN]: {
    type: 'warning',
    title: 'common:confirmAccount.errors.errorTitle',
    content: 'common:confirmAccount.errors.wrongToken'
  },
  [ErrorService.ERRORS.DOES_NOT_EXIST]: {
    type: 'warning',
    title: 'common:confirmAccount.errors.errorTitle',
    content: 'common:confirmAccount.errors.userNotFound'
  }
};

const UserAccountConfirmation = ({history, successToast, warningToast}) => {
  const { t: common } = useTranslation('common');


  const confirmUser = useCallback(async (user_id, token) => {
    try {
      if (user_id && token) {
        await confirmAccount({user_id, token});
        successToast(
          common('confirmAccount.successContent'), common('confirmAccount.successTitle')
        )
      } else {
        warningToast(
          common('confirmAccount.errors.default'), common('confirmAccount.errors.errorTitle')
        );
      }
    } catch (error) {
      errorService.handleErrorToast(error, CONFIRMATION_ERRORS)
    } finally {
      history.push('/login');
    }
  }, [history]);

  useEffect(() => {
    const {user_id, token} = queryString.parse(history.location.search);
    confirmUser(user_id, token);
  }, [history, confirmUser]);

  return <BaseLoaderSpinner large />
};

export default connect(null, {successToast, warningToast})(UserAccountConfirmation);
