import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BaseStatusBar } from '..';
import { getStatusesStringList } from 'bxcommon/helpers/common.helpers';
import { RequestContext } from 'bxcommon/context/Request.context';

import { AMENDMENT_TYPE, STATUS } from '../../constants/status.constant';
import AppConfig from '../../providers/AppConfig';

import './FormWrapper.scss';

export const FormWrapper = ({ children }) => {
  const { t } = useTranslation();
  const {
    request,
    resources: { status }
  } = useContext(RequestContext);

  const statuses = useMemo(() => getStatusesStringList(status.data), []);
  const requestStatus = request.status;
  let amendmentStatus;
  let amendmentType;
  let amendmentDescription;
  if (request.amendment && request.amendment.type !== AMENDMENT_TYPE.RENEWAL) {
    amendmentStatus = request.amendment.status;
    amendmentType = request.amendment.type;
    amendmentDescription =
      request.amendment.rejected_comment || t(`request.form.amendmentStatus.${statuses[amendmentStatus]}.description`);
  }

  let renewalStatus;
  let renewalType;
  let renewalDescription;
  if (request.amendment && request.amendment.type === AMENDMENT_TYPE.RENEWAL) {
    renewalStatus = request.amendment.status;
    renewalType = request.amendment.type;
    renewalDescription =
      request.amendment.rejected_comment || t(`request.form.renewalStatus.${statuses[renewalStatus]}.description`);
  }
  const deletionDate = request.deletion_date;
  const isDeletionPendingApproval = amendmentType === AMENDMENT_TYPE.DELETION && amendmentStatus === STATUS.IN_REVIEW;
  const isDeletionApproved = !isDeletionPendingApproval && !!deletionDate;
  const formattedDeletionDate = deletionDate ? moment(deletionDate).format(AppConfig.dotsDateFormat) : null;

  const renderStatusBar = () => {
    switch (true) {
      case isDeletionPendingApproval:
        return (
          <BaseStatusBar
            title={t('request.form.deletion.inProgress.title')}
            description={t('request.form.deletion.inProgress.description')}
            statusText={statuses[amendmentStatus]}
            status={amendmentStatus}
          ></BaseStatusBar>
        );
      case isDeletionApproved:
        return (
          <BaseStatusBar
            title={t('request.form.deletion.approved.title')}
            description={t('request.form.deletion.approved.description', { date: formattedDeletionDate })}
            statusText="deletion"
            status={amendmentStatus}
          ></BaseStatusBar>
        );
      default:
        return (
          <>
            {AppConfig.detailsStatuses.includes(request.status) &&
              !AppConfig.deleteStatuses.includes(renewalStatus) && (
                <BaseStatusBar
                  title={t(`request.form.status.${statuses[requestStatus]}.title`)}
                  description={t(`request.form.status.${statuses[requestStatus]}.description`)}
                  statusText={statuses[requestStatus]}
                  status={requestStatus}
                  registrationOfficerComment={request.registration_officer_comment || ''}
                ></BaseStatusBar>
              )}
            {request.amendment &&
              AppConfig.amendmentStatuses.includes(amendmentStatus) &&
              request.amendment.type !== AMENDMENT_TYPE.RENEWAL && (
                <BaseStatusBar
                  title={t(`request.form.amendmentStatus.${statuses[amendmentStatus]}.title`)}
                  description={amendmentDescription}
                  statusText={statuses[amendmentStatus]}
                  status={amendmentStatus}
                ></BaseStatusBar>
              )}
            {request.amendment &&
              AppConfig.amendmentStatuses.includes(renewalStatus) &&
              request.amendment.type === AMENDMENT_TYPE.RENEWAL && (
                <BaseStatusBar
                  title={t(`request.form.renewalStatus.${statuses[renewalStatus]}.title`)}
                  description={renewalDescription}
                  statusText={statuses[renewalStatus]}
                  status={renewalStatus}
                ></BaseStatusBar>
              )}
          </>
        );
    }
  };
  return (
    <div className="form-wrapper">
      {renderStatusBar()}
      <div className="form-wrapper__content">
        {React.Children.map(children, child => {
          if (!child) return null;
          return React.cloneElement(child, {
            isDeletionProcessStarted: isDeletionPendingApproval || isDeletionApproved
          });
        })}
      </div>
    </div>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
