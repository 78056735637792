import listReducer from 'bxcommon/store/reducers/list.reducer';
import requestReducer from 'bxcommon/store/reducers/request.reducer';
import resourcesReducer from 'bxcommon/store/reducers/resources.reducer';
import toasterReducer from 'bxcommon/store/reducers/toaster.reducer';
import userReducer from 'bxcommon/store/reducers/user.reducer';
import requestMetaReducer from 'bxcommon/store/reducers/requestMeta.reducer';
import registryReducer from 'bxcommon/store/reducers/registry.reducer';

const requestInitialState = {
  // personal
  address_line_1: '',
  address_line_2: '',
  postcode: '',
  city: '',
  country: '',
  alternative_email: '',
  phone_number: '',
  phone_number_dial_code: '',
  work_phone_number: '',
  work_phone_number_dial_code: '',

  // express payment
  express_payment_needed: false,

  // fields of activity
  fields_of_activity: [],

  // education
  education: {
    // proof of knowledge
    proof_of_knowledge: false,
    proof_of_knowledge_professional_expertise: false,
    proof_of_knowledge_knowhow: false,
    proof_of_knowledge_declaration: false,

    // professional or academic degree
    degree: null,
    has_other_degree: false,
    other_degree: '',

    // professional trainings && knowledge according to FinSA and other
    has_predefined_certificates: false,
    predefined_certificates: [],
    has_additional_certificates: false,
    additional_certificates: '',
    has_employer_confirmation: false,
    has_finsa_certificates: false,
    finsa_certificates: [],
    has_other_education: false,
    other_education: ''
  },

  // company / job / ombudsman office
  companies: [
    {
      type_of_employment: 1,
      id: null,
      name: '',
      address_line_1: '',
      address_line_2: '',
      postcode: '',
      city: '',
      country: null,
      ombudsman_offices: [],
      job_function: '',
      job_position: '',
      confirmation_of_employment_files: [],
      liability_insurance_files: []
    }
  ],

  // documents
  passport_files: [],
  cv_files: [],
  criminal_record_extract_files: [],
  professional_or_academic_degree_files: [],
  predefined_certificate_files: [],
  additional_certificate_files: [],
  employer_confirmation_files: [],
  finsa_certificate_files: []
};

export default {
  requestMeta: requestMetaReducer(),
  list: listReducer(),
  request: requestReducer(requestInitialState),
  resources: resourcesReducer(),
  toasts: toasterReducer(),
  user: userReducer(),
  registry: registryReducer()
};
