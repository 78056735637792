import requestInterceptor from '../interceptors/request.interceptor';

export class AuthHelper {
  constructor() {
    this.keyName = null;
  };

  static parseJWT(token) {
    const base64Url = token.split('.')[1];
    const base64 = decodeURIComponent(atob(base64Url).split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''));
    return JSON.parse(base64);
  }

  init(serviceName) {
    this.keyName = serviceName;
    if (this.token) {
      requestInterceptor(this.token);
    }
  }

  get tokenKey() {
    return `${this.keyName}_token`;
  }

  get token() {
    return localStorage.getItem(this.tokenKey);
  }

  get isExpired() {
    let exp;
    try {
      exp = AuthHelper.parseJWT(this.token).exp;
    } catch (e) {
      exp = 0;
    }
    return !this.token || exp <= Math.trunc(Date.now() / 1000);
  }

  setToken(token) {
    localStorage.setItem(this.tokenKey, token);
    requestInterceptor(token);
  }

  removeToken() {
    localStorage.removeItem(this.tokenKey);
    requestInterceptor(null);
  }
}

export default new AuthHelper();
