import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BaseIcon, BaseStatus } from 'bxcommon';
import ListViewWrapper from 'bxcommon/components/ListViewWrapper/ListViewWrapper';

import { ProspectusHeader } from '../ProspectusHeader/ProspectusHeader';
import { hydrateProspectusesList, removeFromProspectusesList } from '../../store';
import { clearList } from 'bxcommon/store/actions';

export const SECURITY_TYPE = {1: 'equity', 2: 'debt', 3: 'derivative'};

const ListView = ({hydrateList, removeFromList, userId, clearList, history, hasGroup}) => {
  const labels = ['lastUpdate', 'caseId', 'status', 'description', 'issuerName', hasGroup ? 'ownership': null, ''];

  useEffect(() => {
    return () => {
      const dontClearList = history.location.pathname.includes('/details')
        || history.location.pathname.includes('/request/');

      if (!dontClearList) {
        clearList();
      }
    }
  }, [clearList, history.location.pathname]);

  return (
    <ProspectusHeader>
      <ListViewWrapper
        isProspectus
        labels={labels}
        editUrl={SECURITY_TYPE}
        detailsUrl={'details'}
        search={{queryName: 'case_id', hydrateList, placeholder: 'list.labels.caseId'}}
        hydrateList={hydrateList}
        removeFromList={removeFromList}
      >
        {item => (
          <>
            <td
              className="copy copy--color-dark"
              data-cypress="list-status"
            >
              <BaseStatus status={item.status}/>
            </td>
            <td
              className="copy copy--color-dark"
              data-cypress="list-name"
            >
              {item.name}
            </td>
            <td
              className="copy copy--color-dark"
              data-cypress="list-issuer-name"
            >
              {item.issuer_name || item.issuers.join(', ')}
            </td>
            {hasGroup &&
              <td
                className="copy copy--color-dark"
                data-cypress="list-owner"
              >
                {item.creator && item.creator.id === userId &&
                  <BaseIcon icon="yes"/>
                }
              </td>
            }
          </>
        )}
      </ListViewWrapper>
    </ProspectusHeader>
  );
};

ListView.propTypes = {
  hydrateList: PropTypes.func,
  removeFromList: PropTypes.func,
  userId: PropTypes.string
};

const mapStateToProps = (state) => ({
  userId: state.user.details.id,
  hasGroup: state.user.details.group
});

export default connect(
  mapStateToProps,
  {
    hydrateList: hydrateProspectusesList,
    removeFromList: removeFromProspectusesList,
    clearList
  }
)(ListView);
