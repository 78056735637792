import i18n from '../../i18n';

export const TOASTER_ACTIONS = {
  ADD_TOAST: 'add_toast',
  REMOVE_TOAST: 'remove_toast',
};

export const TOAST_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
};

const addToast = (toast) => {
  validateToast(toast);

  return (dispatch) => {
    dispatch({
      type: TOASTER_ACTIONS.ADD_TOAST,
      payload: {...toast}
    });
  };
};

export const removeToast = (id) => {
  return (dispatch) => {
    dispatch({
      type: TOASTER_ACTIONS.REMOVE_TOAST,
      payload: id
    });
  };
};


const dispatchToast = (type, defaultTitle) => (payload, title=i18n.t(defaultTitle)) => {
  return typeof(payload) === 'string' && title
    ? addToast({ body: { message: payload, title: title, type: type }})
    : addToast({ body: { message: payload.message, title: payload.title, type: type }, options: payload.options })
};

export const successToast = dispatchToast(TOAST_TYPE.SUCCESS, 'common:successToastHeading');
export const warningToast = dispatchToast(TOAST_TYPE.WARNING, 'common:warningToastHeading');
export const errorToast = dispatchToast(TOAST_TYPE.ERROR, 'common:errorToastHeading');


function validateToast(toast) {
  if (!toast.body) {
    throw new Error('Alert has no body')
  }

  if (!toast.body.message) {
    throw new Error('Alert has empty message')
  }

  if (!toast.body.type || !Object.values(TOAST_TYPE).includes(toast.body.type)) {
    throw new Error('Wrong alert type')
  }
}
