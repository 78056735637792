import axios from 'axios';

export const CSVDownload = (caseId) => {
  axios.get(`/prospectuses/${caseId}/final-terms/`, {
    responseType: 'arraybuffer',
    headers: {
      'Accept': 'text/csv'
    }
  })
    .then (response => {
      let fileName = 'final-terms.csv';
      const type = response.headers['content-type'];
      const cd = response.headers['content-disposition'];
      if (cd) {
        fileName = cd.match(/filename="(.*?)"/)[1];
      }

      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });

      // IE11
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
      }
    })
};

export const getAddendumList = (caseId) => {
  return axios.get(`/prospectuses/${caseId}/addenda/`)
    .then(({data}) => data.data)
};

export const deleteAdditionalFiles = (caseId, type) => {
  return axios.delete(`/prospectuses/${caseId}/${type}/`)
    .then(({data}) => data.data)
};

export const patchAdditionalFiles = (caseId, type) => {
  return axios.patch(`/prospectuses/${caseId}/${type}/`)
    .then(({data}) => data.data)
};
