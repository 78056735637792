export const STATUS = {
  DRAFT: 1,
  IN_REVIEW: 2,
  UPDATE_REQUIRED: 3,
  APPROVED: 4,
  REJECTED: 5,
  ERROR: 6
};

export const AMENDMENT_TYPE = {
  DEFAULT: 0,
  DELETION: 1,
  RENEWAL: 2
};
