import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { history } from '../../BxBrowserRouter';

import { PAYMENT_METHODS } from '../../constants/payments.constant';
import { STATUS } from '../../constants/status.constant';
import { RequestContext } from 'bxcommon/context/Request.context';
import { ClientAdvisorBadge } from '../ClientAdvisorBadge/ClientAdvisorBadge';
import { RequestDeletionButton } from '../RequestDeletionButton/RequestDeletionButton';
import { RequestFooter } from '../RequestFooter/RequestFooter';
import { RequestSummaryFooter } from '../RequestSummaryFooter/RequestSummaryFooter';
import { BaseButtonLink, BaseButtonOutline, BaseStatus, PaymentButton } from '..';
import AppConfig from '../../providers/AppConfig';
import { isFormEditable } from '../../helpers/form.helper';
import { isPaymentProceededSuccessfully } from 'registry/src/common/common.helpers';
import { scrollToTop } from 'bxcommon/helpers/scrollTop.helpers';
import { FORM_VARIANT } from 'registry/src/constants/form.constant';
import { STEP_VARIANT } from 'registry/src/constants/steps.constant';
import { Steps } from 'antd';

import './RequestStepWrapper.scss';

const { REQUEST_DETAILS, REQUEST_FORM, MAIN } = FORM_VARIANT;

const RequestStepWrapper_ = props => {
  const {
    children,
    createdAt,
    isFinal,
    validationSubmit,
    formRef,
    content,
    disabled,
    noSteps,
    finalRedirect,
    isAdvisor,
    adminRedirect,
    isAdmin,
    togglePublish,
    goBack,
    formVariant = MAIN,
    setActiveCategory,
    activeCategory,
    categories,
    steps,
    activeStep,
    setActiveStep,
    isIntro,
    isRenewal,
    setEditForm,
    editForm,
    isCodeOfConduct,
    setIsSummaryCompleted,
    isUpdateRequire
  } = props;
  const {
    cancelAmendment,
    hydrateList,
    metadata,
    saveStep,
    submitForm,
    clearForm,
    clearAfterSubmit,
    request,
    successToast,
    warningToast,
    validateFormFnRef,
    setCompletedState
  } = useContext(RequestContext);
  const { t: common } = useTranslation('common');
  const isClientAdvisorBadgeVisible = isAdvisor && request.status === STATUS.APPROVED && request.badge_png_file;
  const { SUMMARY, PAYMENTS_CONFIRMATIONS, FIELDS_OF_ACTIVITY, PERSONAL_INFORMATION } = STEP_VARIANT;
  const isSummary = steps && steps[activeStep - 1] === SUMMARY;
  const isFiledsActivity = steps && steps[activeStep - 1] === FIELDS_OF_ACTIVITY;
  const isPaymentsConfirmations = steps && steps[activeStep - 1] === PAYMENTS_CONFIRMATIONS;
  const isAmendment = request.amendment && AppConfig.editStatuses.includes(request.amendment.status);
  const isPaymentSuccess = isAdvisor && !isPaymentProceededSuccessfully(request.payment);
  const isPaymentSuccessRenewal =
    request.amendment && isAdvisor && !isPaymentProceededSuccessfully(request.amendment.payment);

  useEffect(() => {
    // We need to pass reference of validation to context function to be able to use it when saving
    // from outside of the form scope
    validateFormFnRef.current = validationSubmit;
  }, []);
  const paymentRef = useRef();

  const displayErrorToast = (toastType = 'somethingWentWrong') => {
    warningToast(common(`request.toasts.${toastType}Message`), common(`request.toasts.${toastType}Title`));
  };

  const displayWarningToast = (toastType = 'validationWarning') => {
    warningToast(common(`request.toasts.${toastType}Message`), common(`request.toasts.${toastType}Title`));
  };

  const displaySuccessToast = (toastType = 'saveAndReturn') => {
    successToast(common(`request.toasts.${toastType}Message`), common(`request.toasts.${toastType}Title`));
  };

  const onSuccess = (toastType = 'requestSubmitted') => {
    displaySuccessToast(toastType);
    clearAfterSubmit();
    sessionStorage.removeItem('regsevices-form-values-additional-email')
    history.push(finalRedirect);
  };

  const saveAndReturn = () => {
    const form = formRef.current;
    const formActions = form.getFormikActions();
    validationSubmit(() => {
      formActions
        .submitForm()
        .then(() => saveStep(form.state.values))
        .then(() => {
          displaySuccessToast();
          if (!noSteps) {
            clearForm();
            history.push('/');
          }
        })
        .catch(() => {});
    }, false);
  };

  const saveAndContinue = () => {
    const form = formRef.current;
    const formActions = form.getFormikActions();
    validationSubmit(() => {
      formActions
        .submitForm()
        .then(formActions.validateForm) // TODO: This is hack to revalidate | check formik 2.0 when it's out
        .then(errors => {
          const hasErrors = Boolean(Object.keys(errors).length);
          if (hasErrors) {
            displayWarningToast();
            return;
          }
          saveStep(form.state.values)
            .then(() => {
              if (noSteps) {
                setCompletedState(true);
                history.push(adminRedirect && isAdmin ? `${adminRedirect}/${request.case_id}` : '/');
                return;
              }

              if (metadata.requestCompleted) {
                props.lastStep();
              } else {
                props.nextStep();
              }
            })
            .catch(() => {});
        });
    });
  };

  const saveAndSubmit = () => {
    const form = formRef.current;
    const formActions = form.getFormikActions();
    validationSubmit(() => {
      formActions
        .submitForm()
        .then(formActions.validateForm)
        .then(errors => {
          const hasErrors = Boolean(Object.keys(errors).length);
          if (hasErrors) {
            displayWarningToast();
          }
          return !hasErrors;
        })
        .then(async validationResults => {
          if (validationResults) {
            const result = await submitForm();
            if (result) {
              if (result.status) {
                return onSuccess('unnecessarySubmissionAllGood');
              }
              if (
                isAdvisor &&
                !isPaymentProceededSuccessfully(isRenewal ? request.amendment.payment : request.payment) &&
                form.state.values.payment.method === PAYMENT_METHODS.CREDIT_CARD
              ) {
                try {
                  paymentRef.current.clickButton();
                } catch (e) {
                  displayErrorToast();
                }
              } else {
                onSuccess();
              }
            }
          }
        });
    });
  };

  const nextStepSubmit = () => {
    const form = formRef.current;
    const formActions = form.getFormikActions();

    validationSubmit(() => {
      formActions
        .submitForm()

        .then(formActions.validateForm)
        .then(errors => {
          const hasErrors = Boolean(Object.keys(errors).length);
          if (hasErrors) {
            displayWarningToast();
          } else {
            formActions.setTouched({});
            setActiveStep(activeStep + 1);
            scrollToTop();
          }
          return !hasErrors;
        })
        .then(async validationResults => {
          if (validationResults) {
            try {
              await saveStep(form.state.values);
              await hydrateList();
            } catch (e) {
              displayWarningToast();
            }
          }
        });
    });
  };

  const submitAmendment = async () => {
    const form = formRef.current;
    const formActions = form.getFormikActions();
    validationSubmit(() => {
      formActions
        .submitForm()
        .then(formActions.validateForm)
        .then(errors => {
          const hasErrors = Boolean(Object.keys(errors).length);
          if (hasErrors) {
            displayWarningToast();
          }
          return !hasErrors;
        })
        .then(async validationResults => {
          if (validationResults) {
            try {
              await saveStep(form.state.values);
              if (isRenewal) {
                await hydrateList();
                await setActiveStep(activeStep + 1);
                sessionStorage.setItem('regsevices-form-values', JSON.stringify(form.state.values));
              }
              if (!isRenewal) {
                await submitForm(true, false, form.state.values.additional_recipients);
                await hydrateList();
                displaySuccessToast('amendmentSubmitted');
                history.push('/request-details');
              }
            } catch (e) {
              displayWarningToast();
            }
          }
        });
    });
  };

  const submitRequireUpdate = async () => {
    const form = formRef.current;
    const formActions = form.getFormikActions();
    validationSubmit(() => {
      formActions
        .submitForm()
        .then(formActions.validateForm)
        .then(errors => {
          const hasErrors = Boolean(Object.keys(errors).length);
          if (hasErrors) {
            displayWarningToast();
          }
          return !hasErrors;
        })
        .then(async validationResults => {
          if (validationResults) {
            try {
              await saveStep(form.state.values);
              // We use amendment for require update because payment have already done on initial
              await submitForm(true, false, request.additional_recipients);
              await hydrateList();
              displaySuccessToast('unnecessarySubmissionAllGood');
              history.push('/request-details');
            } catch (e) {
              displayWarningToast();
            }
          }
        });
    });
  };

  const saveAndContinueSteps = () => {
    if (isSummary) {
      !isUpdateRequire ? [setActiveStep(activeStep + 1), scrollToTop()] : submitRequireUpdate();
    }
    !isSummary && !isPaymentsConfirmations && nextStepSubmit();
    isPaymentsConfirmations && saveAndSubmit();
  };

  const uploadSessionStorage = (firstname, lastname) => {
    sessionStorage.setItem('regsevices-form-values-firstname', JSON.stringify(firstname));
    sessionStorage.setItem('regsevices-form-values-lastname', JSON.stringify(lastname));
  };

  const nextStepAmendment = (isRenewal = false) => {
    const form = formRef.current;
    const formActions = form.getFormikActions();
    validationSubmit(() => {
      formActions
        .submitForm()
        .then(formActions.validateForm)
        .then(errors => {
          const hasErrors = Boolean(Object.keys(errors).length);
          if (hasErrors) {
            displayWarningToast();
            return;
          }
          setActiveStep(activeStep + 1);
          formActions.setTouched({});
          if (isRenewal) {
            setEditForm(false);
            uploadSessionStorage(
              form.state.values.first_name,
              form.state.values.last_name,
            );
          }
          scrollToTop();
        })
        .catch(() => {});
    });
  };

  const amendmentSaveAndSubmit = () => {
    isSummary ? submitAmendment() : nextStepAmendment();
  };

  const handleBack = async () => {
    setActiveStep(activeStep - 1);
    scrollToTop();
  };
  const handleBackRenewal = async () => {
    setActiveStep(activeStep - 1);
    setEditForm(false);
    scrollToTop();
  };

  const handleNext = async () => {
    const form = formRef.current;
    const formActions = form.getFormikActions();
    validationSubmit(() => {
      formActions
        .submitForm()
        .then(formActions.validateForm)
        .then(errors => {
          const hasErrors = Boolean(Object.keys(errors).length);
          if (hasErrors) {
            setEditForm(true);
            scrollToTop();
            displayWarningToast();
            return;
          }
          setActiveStep(activeStep + 1);
          uploadSessionStorage(
            form.state.values.first_name,
            form.state.values.last_name,
          );
          scrollToTop();
        })
        .catch(() => {});
    });
  };

  const goToEditForm = async () => {
    setEditForm(true);
    scrollToTop();
  };

  const cancelEditForm = async () => {
    const form = formRef.current;
    const cancelChangeData = Object.keys(form.fields).reduce((acc, item, index) => {
      if (item.includes('education')) {
        const name = item.split('education.')[1];
        if (index === 0) {
          acc['education'] = {
            ...acc['education'],
            ['has_finsa_certificates']: form.initialValues.education['has_finsa_certificates'],
            ['finsa_certificates']: form.initialValues.education['finsa_certificates']
          };
        }
        if (!item.includes('degrees_temp')) {
          acc['education'] = { ...acc['education'], [name]: form.initialValues.education[name] };
        } else {
          acc['education'] = {
            ...acc['education'],
            degrees_temp: {
              type: form.initialValues.education.degrees_temp.type,
              details: form.initialValues.education.degrees_temp.details
            }
          };
        }
      } else if (item.includes('companies')) {
        acc['companies'] = form.initialValues['companies'];
      } else {
        acc[item] = form.initialValues[item];
      }
      return acc;
    }, {});
    const currentValues =
      steps[activeStep - 1] === STEP_VARIANT.PROFFESSIONAL_ACADEMIC_DEGREES
        ? Object.assign({}, form.state.values, cancelChangeData)
        : { ...form.state.values, ...cancelChangeData };
    Object.entries(currentValues).forEach(([key, value]) => form.setFieldValue(key, value));
    setEditForm(false);
    scrollToTop();
  };

  const renewalSaveAndSubmit = () => {
    isSummary && [submitAmendment(), scrollToTop(), setIsSummaryCompleted(true)];
    !isSummary && !isPaymentsConfirmations && nextStepAmendment(isRenewal);
    isPaymentsConfirmations && saveAndSubmit();
  };

  const goToEdit = async () => {
    await saveStep({
      amendment: { new: true }
    });
    history.push(`/request-form/${isAdmin ? request.case_id : ''}`);
  };

  const goToEditRenewal = async () => {
    await saveStep({
      // When you want test Renewal process on local environment amendment: change to { new: true }
      amendment: { new: true, type: 2 }
    });
    history.push(`/request-form/${isAdmin ? request.case_id : ''}`);
  };

  const onCancelAmendmentClick = async () => {
    await cancelAmendment(request.case_id);
    await hydrateList();
  };

  const onCancelRenewalPayment = async () => {
    await cancelAmendment(request.case_id);
    await hydrateList();
    history.push(`/request-details/${request.case_id}`);
    sessionStorage.removeItem('regsevices-form-values');
  };

  const handleDeletionRequestDone = () => {
    hydrateList('/client-advisors/');
  };

  const isRequestDetails = formVariant === REQUEST_DETAILS;
  const isRequestForm = formVariant === REQUEST_FORM;
  const isMainForm = formVariant === MAIN;

  const { Step } = Steps;

  const isRenewalAllowed = request.renewal_allowed;

  const showDetails = formVariant => (
    <BaseStatus
      showDetails
      isAdvisor={isAdvisor}
      isPublishedOnRegister={request.is_published}
      handleSwitchChange={togglePublish}
      {...request}
      data-cypress="request-status"
      formVariant={formVariant}
    />
  );

  const showStatus = () => {
    const iconOptions = {
      iconLeft: isRequestDetails,
      full: isRequestDetails,
      left: isRequestDetails,
      iconRight: !isRequestDetails,
      small: true,
      className: 'multiline-button'
    };
    return (
      isAdvisor &&
      AppConfig.approvedStatuses.includes(request.status) && (
        <div>
          {!props.isDeletionProcessStarted && (
            <>
              {(!request.amendment ||
                (request.amendment && AppConfig.resolvedStatuses.includes(request.amendment.status))) && (
                <>
                  <div className="base-status__info">
                    <BaseButtonOutline
                      onClick={goToEdit}
                      {...iconOptions}
                      icon="pencil"
                      data-cypress="details-request-change-button"
                    >
                      {common('request.details.requestChange')}
                    </BaseButtonOutline>
                  </div>
                  {isRequestDetails && isRenewalAllowed && (
                    <div className="base-status__info">
                      <BaseButtonOutline
                        onClick={goToEditRenewal}
                        className="request-details__button--payments"
                        {...iconOptions}
                        icon="autoRenew"
                        data-cypress="details-request-change-button"
                      >
                        {common('request.details.requestRenewal')}
                      </BaseButtonOutline>
                    </div>
                  )}
                </>
              )}
              {isAmendment && (
                <div className="base-status__info">
                  <BaseButtonOutline
                    onClick={onCancelAmendmentClick}
                    {...iconOptions}
                    icon="delete"
                    data-cypress="details-request-change-button"
                  >
                    {common('request.details.cancelChange')}
                  </BaseButtonOutline>
                </div>
              )}
            </>
          )}

          <RequestDeletionButton
            createdAt={createdAt}
            isHidden={props.isDeletionProcessStarted}
            onDeletionRequestDone={handleDeletionRequestDone}
            formVariant={formVariant}
          />
        </div>
      )
    );
  };

  return (
    <div className={classnames('request-wrapper', { [`request-wrapper--${formVariant}`]: formVariant })}>
      <div className="container">{!isMainForm ? <div className="row">{showDetails(formVariant)}</div> : null}</div>
      <div className="container">
        <div className="row">
          {isMainForm && (
            <div className="col-2">
              {goBack && (
                <BaseButtonLink
                  iconLeft
                  icon="arrow-left"
                  onClick={() => history.push(goBack)}
                  className="request-wrapper__go-back"
                  data-cypress="go-back-button"
                >
                  {common('goBack')}
                </BaseButtonLink>
              )}

              <>
                {showDetails(formVariant)}
                {showStatus()}
                {request.status !== 0 && <div className="request-wrapper__info">{content}</div>}
                {isClientAdvisorBadgeVisible && (
                  <ClientAdvisorBadge
                    clientId={request.case_id}
                    href={request.badge_zip_file}
                    image={request.badge_png_file}
                  />
                )}
              </>
            </div>
          )}

          {!isMainForm && (
            <div className="col-3 request-wrapper__sidebar">
              {isRequestDetails && (
                <>
                  {
                    <div className="request-wrapper__category">
                      {Object.values(categories).map(category => {
                        return (
                          <div key={category}>
                            {category === categories.downloadQr || category === categories.history ? null : (
                              <BaseButtonOutline
                                type="button"
                                left
                                large
                                category
                                className={classnames({ 'base-button__category--active': activeCategory === category })}
                                onClick={() => setActiveCategory(category)}
                              >
                                {category}
                              </BaseButtonOutline>
                            )}
                          </div>
                        );
                      })}
                      <div className="request-wrapper__category--bottom">
                        {Object.values(categories).map(category =>
                          (category === categories.downloadQr && isClientAdvisorBadgeVisible) ||
                          category === categories.history ? (
                            <div key={category}>
                              <BaseButtonOutline
                                type="button"
                                left
                                large
                                category
                                className={classnames('base-button__category--secondary', {
                                  'base-button__category--active': activeCategory === category
                                })}
                                onClick={() => setActiveCategory(category)}
                              >
                                {category}
                              </BaseButtonOutline>
                            </div>
                          ) : null
                        )}
                      </div>
                    </div>
                  }
                  {showStatus()}
                </>
              )}
              {isRequestForm && !isIntro && (
                <div
                  className={classnames('request-wrapper__stepper', {
                    'request-wrapper__stepper--amendment': isIntro
                  })}
                >
                  <Steps type="navigation" direction="vertical" current={activeStep - 1}>
                    {steps.map(step => (
                      <Step className="request-wrapper__nav" title={step} key={step} />
                    ))}
                  </Steps>
                </div>
              )}
            </div>
          )}

          <div className="col-8 request-wrapper__form">{children}</div>
          {isRequestDetails && <div className="col-1" />}
          {isMainForm && <div className="col-2" />}
        </div>
      </div>
      {isMainForm && isAmendment && (
        <div className="request-wrapper__footer">
          <div className="container request-wrapper__footer--summary">
            <RequestSummaryFooter submit={submitAmendment} />
          </div>
        </div>
      )}
      {isMainForm && isFormEditable(request.status) && (
        <div className="request-wrapper__footer">
          {isFinal ? (
            <div className="container request-wrapper__footer--summary">
              {isPaymentSuccess && <PaymentButton ref={paymentRef} />}
              <RequestSummaryFooter submit={saveAndSubmit} />
            </div>
          ) : (
            <RequestFooter
              noSteps={noSteps}
              saveAndContinue={saveAndContinue}
              saveAndReturn={saveAndReturn}
              disabled={disabled}
              {...props}
            />
          )}
        </div>
      )}
      {isRequestForm && !isIntro && (
        <div
          className={classnames('request-wrapper__footer', {
            'fields-activity': isFiledsActivity,
            'fields-activity--summary': isFiledsActivity && !editForm
          })}
        >
          <div className="container request-wrapper__footer--summary">
            {isAmendment && !isRenewal && (
              <RequestFooter
                saveAndContinue={amendmentSaveAndSubmit}
                previousStep={handleBack}
                currentStep={activeStep}
                disabled={disabled}
                variant={REQUEST_FORM}
                cancelAmendment={onCancelAmendmentClick}
                isSummary={isSummary}
                {...props}
              />
            )}
            {!isAmendment && !isRenewal && (
              <>
                <RequestFooter
                  saveAndContinue={saveAndContinueSteps}
                  previousStep={handleBack}
                  currentStep={activeStep}
                  disabled={disabled}
                  variant={REQUEST_FORM}
                  isSummary={isSummary}
                  isSubmit={isPaymentsConfirmations}
                  isUpdateRequire={isUpdateRequire}
                  {...props}
                />
                {isPaymentSuccess && isFinal && <PaymentButton ref={paymentRef} />}
              </>
            )}
            {isRenewal && (
              <>
                <RequestFooter
                  saveAndContinue={renewalSaveAndSubmit}
                  previousStep={handleBackRenewal}
                  nextStep={handleNext}
                  editForm={editForm}
                  goToEditForm={goToEditForm}
                  cancelEditForm={cancelEditForm}
                  currentStep={activeStep}
                  disabled={disabled}
                  variant={REQUEST_FORM}
                  cancelAmendment={isPaymentsConfirmations ? onCancelRenewalPayment : onCancelAmendmentClick}
                  isSummary={isSummary}
                  isRenewal={isRenewal}
                  isSubmit={isPaymentsConfirmations}
                  isCodeOfConduct={isCodeOfConduct}
                  {...props}
                />
                {isPaymentSuccessRenewal && isFinal && <PaymentButton ref={paymentRef} isRenewal={isRenewal} />}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

RequestStepWrapper_.propTypes = {
  isDeletionProcessStarted: PropTypes.bool,
  isFinal: PropTypes.bool,
  children: PropTypes.shape({
    content: PropTypes.node,
    footer: PropTypes.node
  }).isRequired,
  status: PropTypes.number,
  formRef: PropTypes.object,
  validationSubmit: PropTypes.func,
  warningToast: PropTypes.func,
  successToast: PropTypes.func,
  disabled: PropTypes.bool,
  isAdmin: PropTypes.bool,
  adminRedirect: PropTypes.string,
  content: PropTypes.node,
  goBack: PropTypes.string,
  formVariant: PropTypes.string,
  setActiveCategory: PropTypes.func,
  activeCategory: PropTypes.string,
  categories: PropTypes.object,
  steps: PropTypes.arrayOf(PropTypes.string),
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
  isIntro: PropTypes.bool,
  isRenewal: PropTypes.bool,
  setEditForm: PropTypes.func,
  editForm: PropTypes.bool,
  isCodeOfConduct: PropTypes.bool,
  setIsSummaryCompleted: PropTypes.func,
  isUpdateRequire: PropTypes.bool
};

const mapStateToProps = ({ user }) => ({ isAdmin: user.details.is_admin, createdAt: user.details.created_at });

export const RequestStepWrapper = connect(mapStateToProps)(RequestStepWrapper_);
