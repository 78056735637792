import './common.helpers.scss';


export const removeExtensionFromFileName = (name) => {
  if (name) {
    return name.replace(/\.[^/.]+$/, "")
  }
};

export const validateIsin = (isin) => {
  if (!isin) return false;
  const controlNumber = Number(isin.slice(-1));

  if (isNaN(controlNumber)) {
    return false;
  }

  if (isin.length !== 12) {
    return false;
  }

  const  v = [];
  for (let i = isin.length - 2; i >= 0; i--) {
    const c = isin.charAt(i);
    if (isNaN(c)) { //not a digit
      const letterCode = isin.charCodeAt(i) - 55; //Char ordinal + 9
      v.push(letterCode % 10);
      if (letterCode > 9) {
        v.push(Math.floor(letterCode / 10));
      }
    } else {
      v.push(Number(c));
    }
  }

  let sum = 0;
  const l = v.length;

  for (let i = 0; i < l; i++) {
    if (i % 2 === 0) {
      var d = v[i] * 2;
      sum += Math.floor(d / 10);
      sum += d % 10;
    } else {
      sum += v[i];
    }
  }

  return ((10 - (sum % 10)) % 10) === controlNumber;
};
