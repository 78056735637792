import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import './ClientAdvisorBadge.scss';
import { BaseButtonOutline } from '..';
import { ProtectedImage } from '../ProtectedImage/ProtectedImage';
import { ProtectedDownloadHandler } from '../ProtectedDownloadHandler/ProtectedDownloadHandler';
import { FORM_VARIANT } from 'registry/src/constants/form.constant';

export const ClientAdvisorBadge = ({ clientId, image, href, variant }) => {
  const { t: common } = useTranslation('common');
  const filename = `badge-client-advisor-${clientId}.zip`;
  const { REQUEST_DETAILS } = FORM_VARIANT;

  const isRequestDetails = variant === REQUEST_DETAILS;

  const renderBadge = () => (
    <>
      <ProtectedImage src={image} className="client-advisor-badge__image" />
      <ProtectedDownloadHandler filename={filename} href={href}>
        {({ onClick }) => (
          <BaseButtonOutline
            onClick={onClick}
            small
            left={!isRequestDetails}
            iconRight={!isRequestDetails}
            iconLeft={isRequestDetails}
            icon="curved-arrow-down"
            className={`multiline-button client-advisor-badge__download-button`}
            data-cypress="client-advisor-badge-download-button"
          >
            {common('request.details.downloadBadge')}
          </BaseButtonOutline>
        )}
      </ProtectedDownloadHandler>
    </>
  );

  return (
    <div className={classnames('client-advisor-badge', { [`client-advisor-badge--${variant}`]: isRequestDetails })}>
      <p className="client-advisor-badge__title">{common('request.details.badge')}</p>

      {isRequestDetails ? <div className="client-advisor-badge__wrapper">{renderBadge()}</div> : renderBadge()}
    </div>
  );
};

ClientAdvisorBadge.propTypes = {
  clientId: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  variant: PropTypes.string
};
