import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { getStatusesStringList } from '../../helpers/common.helpers';
import { countValidUntil } from '../../helpers/validDate.helper';
import { BaseIcon, BaseSwitch } from '..';

import AppConfig from '../../providers/AppConfig';
import { STATUS } from '../../constants/status.constant';
import { FORM_VARIANT } from 'registry/src/constants/form.constant';
import { formatDate } from 'registry/src/helpers/formatDate.helper';

import './BaseStatus.scss';

const BaseStatus_ = props => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');
  const {
    formVariant,
    status,
    className,
    statusTypes,
    case_id,
    last_edited,
    jira_link,
    submitted_date,
    validation_date,
    isAdmin,
    showDetails,
    isAdvisor,
    isPublishedOnRegister,
    handleSwitchChange = () => {},
    creator,
    hasGroup,
    amendment,
    request_submission_date,
    request_approval_date,
    last_update_submission_date,
    last_amendment_submission_date,
    last_amendment_approval_date,
    valid_until_date
  } = props;

  const statuses = useMemo(() => getStatusesStringList(statusTypes.data), []);
  const showJiraLink = isAdmin && jira_link && jira_link.url;
  const { MAIN } = FORM_VARIANT;
  const isMainForm = formVariant === MAIN;
  const { dayMonthYearHourFormat, dayMonthHourFormat } = AppConfig;

  const caseIdBlock = () => (
    <div className="base-status__info">
      <p className="base-status__info-label">{isAdvisor ? t('registrationId') : common('caseId')}</p>
      <p className="base-status__info-value" data-cypress="base-status-case-id">
        {case_id}
      </p>
    </div>
  );

  const draftStatusBlock = () => (
    <div className="base-status__info">
      <p className="base-status__info-label">{common('request.details.lastSaved')}:</p>
      <p className="base-status__info-value">{formatDate(last_edited, dayMonthYearHourFormat)}</p>
    </div>
  );

  const jiraBlock = () => (
    <div className="base-status__info">
      <p className="base-status__info-label">{common('request.details.jiraTicket')}:</p>
      <a href={jira_link.url} target="_blank" rel="noopener noreferrer" className="link--underlined">
        <p className="base-status__info-value">{jira_link.name}</p>
      </a>
    </div>
  );

  const creatorAndGroupBlock = () => (
    <div className="base-status__info" data-cypress="summary-creator">
      <p className="base-status__info-label">{t('request.details.owner')}:</p>
      <p className="base-status__info-value">{creator.full_name}</p>
    </div>
  );

  const publicRegisterBlock = () => (
    <div className="base-status__switch">
      <p className="base-status__info-label">
        {t('publicRegister')}
        {isMainForm ? ':' : null}
      </p>
      {isAdmin ? (
        <BaseSwitch value={isPublishedOnRegister} onChange={handleSwitchChange} />
      ) : (
        <BaseIcon large={!isMainForm} icon={isPublishedOnRegister ? 'yes' : 'no'} />
      )}
    </div>
  );

  const clientAdvisorBlock = () => (
    <div className="base-status__date">
      {baseStatusBlock(common('requestSubmissionDate'), formatDate(request_submission_date, dayMonthHourFormat))}
      {baseStatusBlock(common('requestApprovalDate'), formatDate(request_approval_date, dayMonthHourFormat))}
    </div>
  );

  const requestAmendmentBlock = () => (
    <div className="base-status__date">
      {baseStatusBlock(
        common('lastAmendmentSubmissionDate'),
        formatDate(last_amendment_submission_date, dayMonthHourFormat)
      )}
      {baseStatusBlock(
        common('lastAmendmentApprovalDate'),
        formatDate(last_amendment_approval_date, dayMonthHourFormat)
      )}
      {baseStatusBlock(common('amendmentStatus'), baseLabelBlock(amendment.status))}
    </div>
  );

  const validUntilDate = () => (
    <div className="base-status__date">
      {baseStatusBlock(common('validUntilDate'), countValidUntil(valid_until_date))}
    </div>
  );

  const baseStatusBlock = (label, value) => (
    <div className="base-status__info">
      <p className="base-status__info-label">{label}</p>
      <p className="base-status__info-value">{value}</p>
    </div>
  );

  const baseLabelBlock = (status, className = '') => (
    <span
      data-cypress={props['data-cypress']}
      className={classnames('base-status', `base-status--${statuses[status]}`, className)}
    >
      {common(`status.${statuses[status]}`)}
    </span>
  );

  const prospectusStatusBar = showDetails && (
    <>
      {case_id && caseIdBlock()}
      {showJiraLink && jiraBlock()}
      {status === STATUS.DRAFT && draftStatusBlock()}
      {creator && hasGroup && creatorAndGroupBlock()}
      {submitted_date &&
        baseStatusBlock(common('request.details.submittedDate'), formatDate(submitted_date, dayMonthHourFormat))}
      {AppConfig.optionalDateStatuses.includes(status) &&
        baseStatusBlock(common(`request.details.${statuses[status]}`), formatDate(validation_date, dayMonthHourFormat))}
    </>
  );

  const advisorStatusBar = showDetails && (
    <>
      {isAdvisor && status === STATUS.APPROVED && publicRegisterBlock()}
      {case_id && caseIdBlock()}
      {!isMainForm || status === STATUS.DRAFT ? draftStatusBlock() : null}
      {!isMainForm && validUntilDate()}
      {isMainForm && clientAdvisorBlock()}
      {isMainForm && amendment && requestAmendmentBlock()}
    </>
  );

  return (
    <div className={classnames('base-status__wrapper', { [`base-status__wrapper--${formVariant}`]: formVariant })}>
      {baseLabelBlock(status, className)}
      {isAdvisor ? advisorStatusBar : prospectusStatusBar}
    </div>
  );
};

BaseStatus_.propTypes = {
  status: PropTypes.number,
  formVariant: PropTypes.string,
  case_id: PropTypes.string,
  last_edited: PropTypes.string,
  jira_link: PropTypes.object,
  submitted_date: PropTypes.string,
  validation_date: PropTypes.string,
  showDetails: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isAdvisor: PropTypes.bool,
  isPublishedOnRegister: PropTypes.bool,
  className: PropTypes.string,
  handleSwitchChange: PropTypes.func,
  statusTypes: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  creator: PropTypes.shape({
    id: PropTypes.string,
    full_name: PropTypes.string
  }),
  valid_until_date: PropTypes.string
};

const mapStateToProps = state => ({
  statusTypes: state.resources.status,
  isAdmin: state.user.details.is_admin,
  hasGroup: state.user.details.group
});

export const BaseStatus = connect(mapStateToProps)(BaseStatus_);
