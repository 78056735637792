import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BaseButtonLink, BaseButtonOutline, BaseStatus } from 'bxcommon';
import { GeneralSummary } from '../Summary/GeneralSummary';
import { ProspectusSummary } from '../Summary/ProspectusSummary';
import { InvoicingSummary } from '../Summary/InvoicingSummary';

import { updateRequestField, warningToast } from 'bxcommon/store/actions';
import AppConfig from 'bxcommon/providers/AppConfig';
import { STATUS } from 'bxcommon/constants/status.constant';
import { FormWrapper } from "bxcommon/components/FormWrapper/FormWrapper";

import { SECURITY_TYPE } from '../ListView/ListView';
import { ProspectusHeader } from '../ProspectusHeader/ProspectusHeader';

import './ProspectusDetails.scss';


const mapStateToProps = (state, ownProps) => ({
  listEntry: state.list.find(entry => entry.case_id === ownProps.match.params.detailsId),
  resources: state.resources,
  statusTypes: state.resources.status
});

const ProspectusDetails = (props) => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const {listEntry, match, history, warningToast, updateRequestField, resources} = props;
  const {status, case_id, security_type} = listEntry || {};

  const goToEdit = useCallback(() => history.push(`/request/${SECURITY_TYPE[security_type]}/${case_id}`),
    [history, case_id, security_type]
  );
  const goToAddendum = useCallback(() => history.push(`/addendum/${case_id}`), [history, case_id]);
  const goToFinalTerms = useCallback(() => history.push(`/final-terms/${case_id}`), [history, case_id]);

  const isApproved = status === STATUS.APPROVED;

  useEffect(() => {
    if (match.params.detailsId) {
      updateRequestField(listEntry)
    }
  }, [match, listEntry, updateRequestField]);

  if (!status) {
    warningToast(common('errors.prospectusMessage'), common('errors.prospectusTitle'));
    history.push('/list');
    return <div />
  } else {
    return (
        <ProspectusHeader>
          <FormWrapper>
            <div className="container prospectus-details">
              <div className="row prospectus-details__wrapper">
                <div className="col-2">
                  <BaseButtonLink
                    iconLeft
                    icon="arrow-left"
                    onClick={() => history.push('/list')}
                    className="prospectus-details__go-back"
                  >
                    {common('goBack')}
                  </BaseButtonLink>
                  <BaseStatus showDetails {...listEntry} />
                  {isApproved &&
                  <div className="prospectus-details__additional-files">
                    <BaseButtonOutline
                      onClick={goToAddendum}
                      iconRight
                      icon="pencil"
                      data-cypress="details-addendum-button"
                    >
                      {t('list.buttons.addendum')}
                    </BaseButtonOutline>
                    {listEntry.issue_type === 5 &&
                    <BaseButtonOutline
                      onClick={goToFinalTerms}
                      iconRight
                      icon="pencil"
                      data-cypress="details-final-terms-button"
                    >
                      {t('list.buttons.finalTerms')}
                    </BaseButtonOutline>
                    }
                  </div>
                  }
                </div>
                <div className="col-8 prospectus-details__summary-content">
                  {AppConfig.editStatuses.includes(status) &&
                  <BaseButtonOutline
                    className="prospectus-details__controls"
                    onClick={goToEdit}
                    iconRight
                    icon="pencil"
                    data-cypress="details-edit-button"
                  >
                    {t('list.buttons.editRequest')}
                  </BaseButtonOutline>
                  }

                  <GeneralSummary {...props} request={listEntry} resources={resources}/>
                  <ProspectusSummary {...props} request={listEntry} resources={resources}/>
                  <InvoicingSummary {...props} request={listEntry} resources={resources}/>
                </div>
              </div>
            </div>
          </FormWrapper>
        </ProspectusHeader>

    )
  }
};

ProspectusDetails.propTypes = {
  match: PropTypes.object,
  listEntry: PropTypes.shape({
    status: PropTypes.number,
    case_id: PropTypes.string,
    last_edited: PropTypes.string,
    security_type: PropTypes.number,
    submitted_date: PropTypes.string,
    validation_date: PropTypes.string,
    jira_link: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string
    }),
  }),
  history: PropTypes.object,
  resources: PropTypes.object,
  warningToast: PropTypes.func
};

export default connect(mapStateToProps, { updateRequestField, warningToast })(ProspectusDetails);


