import React from 'react';
import classnames from 'classnames';

import { BaseIcon } from '..';

import './BaseHint.scss';

export const BaseHint = props => {
  const { children, className, hints } = props;
  const classes = classnames('base-hint', className);

  return (
    <div
      className={classes}
      data-cypress="base-hint"
    >
      {hints && hints.length > 0 ? (
        hints.map((hint, key) =>
          <div key={key} className="base-hint--multiple">
            <BaseIcon icon="info"/>
            <div className="copy copy--small">{hint}</div>
          </div>
        )
      ) : (
        <div>
          <BaseIcon icon="info"/>
          <div className="copy copy--small">{children}</div>
        </div>
      )}
    </div>
  );
};
