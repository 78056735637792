import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseBox, BaseBoxHeading, SummaryInput, SummaryWrapper } from 'bxcommon';
import { searchForLabel } from 'bxcommon/helpers/form.helper';

import 'bxcommon/components/SummaryHelpers/SummaryHelpers.scss';


export const InvoicingSummary = (props) => {
  const { request, resources: { countries } } = props;
  const { t } = useTranslation();
  
  return (
    <SummaryWrapper
      heading={t('request.summary.invoicing')}
      buttonText={t('request.summary.editInvoicing')}
      {...props}
    >
      {/* Invoice recipient */}
      <BaseBox borderless className="base-summary__section">
        <>
          <BaseBoxHeading className="base-summary__subheading">
            {t('request.form.invoicing.heading')}
          </BaseBoxHeading>
          <ul className="base-summary__content">
            <SummaryInput
              name={t('request.summary.invoiceSend')}
            >
              {request.invoice.invoice_type === 1
                ? <span data-cypress="summary-send-to-me">{t('request.summary.sendToMe')}</span>
                : <span className="base-summary__address">
                    <span
                      className="base-summary--margin"
                      data-cypress="summary-invoice-issuer-name"
                    >
                      {request.invoice.issuer_name}
                    </span>
                    <span
                      className="base-summary--margin"
                      data-cypress="summary-invoice-first-last-name"
                    >
                      {request.invoice.first_name} {request.invoice.last_name}
                    </span>
                    <span
                      className="base-summary--margin"
                      data-cypress="summary-invoice-address-line-1"
                    >
                      {request.invoice.address_line_1}
                    </span>
                    <span
                      className="base-summary--margin"
                      data-cypress="summary-invoice-address-line-2"
                    >
                      {request.invoice.address_line_2}
                    </span>
                    <span
                      className="base-summary--margin"
                      data-cypress="summary-invoice-postcode-city"
                    >
                      {request.invoice.postcode} {request.invoice.city}
                    </span>
                    <span
                      className="base-summary--margin"
                      data-cypress="summary-invoice-country"
                    >
                      {searchForLabel(countries, request.invoice.country)}
                    </span>
                  </span>
              }
            </SummaryInput>
          </ul>
        </>
      </BaseBox>
    </SummaryWrapper>
  )
};


