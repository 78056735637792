import React from 'react';

import { BaseEnvironmentBar } from '..';

import './AppHeader.scss';

export const AppHeader = ({children}) => {
  return (
    <div
      className="app-header"
      data-cypress="app-header"
    >
      <div className="container">
        <div className="row">
          <div
            className="col-4 app-header__logo"
            data-cypress="app-logo"
          >
            <img src="/public/regservices_logo.png" alt="regservices.ch logo" className="logo" />
          </div>
          <div className="col-8 app-header__content">
            {children}
          </div>
        </div>
        <BaseEnvironmentBar />
      </div>
    </div>
  )
};
