import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './BaseBox.scss';

export const BaseBox = props => {
  const { className, lifted, children, borderless, box } = props;

  const classes = classnames('base-box', className, {
    'base-box--lifted': lifted,
    'base-box--borderless': borderless,
    'base-box--box': box
  });

  return (
    <div className={classes} data-cypress={props['data-cypress']}>
      {children}
    </div>
  );
};

BaseBox.propTypes = {
  className: PropTypes.string,
  lifted: PropTypes.bool,
  borderless: PropTypes.bool,
  children: PropTypes.node.isRequired
};
