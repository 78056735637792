import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BaseButtonFill } from '../BaseButtonFill/BaseButtonFill';

export const RequestSummaryFooter = ({ submit }) => {
  const { t: common } = useTranslation('common');

  return (
    <BaseButtonFill
      type="button"
      onClick={submit}
      iconRight
      icon="paper-airplane"
      data-cypress="request-footer-submit-button"
    >
      {common('request.footer.submitRequest')}
    </BaseButtonFill>
  );
};

RequestSummaryFooter.propTypes = {
  submit: PropTypes.func.isRequired
};
