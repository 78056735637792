import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd'

import { BaseFormFieldWrapper } from '../BaseFormFieldWrapper/BaseFormFieldWrapper';

import './BaseCheckbox.scss';

const BaseCheckbox_ = props => {
  const {field, onBlur, children, handleChange, ...rest} = props;

  return (
    <>
      <Checkbox
        {...field}
        {...rest}
        onBlur={onBlur}
        onChange={(e) => handleChange(e.target.checked)}
      >
        {children  && <span className="checkbox-text">{children}</span>}
      </Checkbox>
    </>
  );
};

BaseCheckbox_.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any
  }),
  onBlur: PropTypes.func,
  children: PropTypes.node,
  handleChange: PropTypes.func
};

export const BaseCheckbox = BaseFormFieldWrapper(BaseCheckbox_);
