import { REQUEST_META_ACTIONS, setSubmittedState, setCompletedState } from './requestMeta.actions';
import axios from 'axios';
import { errorToast } from './toaster.actions';
import i18n from '../../i18n';
import { updateOnList } from './list.actions';

export const REQUEST_ACTIONS = {
  UPDATE_FIELD: 'update_request_field',
  SUBMIT_ENTRY: 'submit_request_entry',
  CLEAR: 'clear_request'
};

export const updateRequestField = field => {
  return dispatch => {
    dispatch({
      type: REQUEST_ACTIONS.UPDATE_FIELD,
      payload: field
    });
  };
};

export const clearForm = () => {
  return dispatch => {
    dispatch({ type: REQUEST_ACTIONS.CLEAR });
    dispatch({ type: REQUEST_META_ACTIONS.SET_COMPLETED_STATE, payload: false });
  };
};

const handleRequest = (type, data, url, dispatch) => {
  const caseId = data.case_id ? `${data.case_id}/` : '';
  return axios({
    method: type,
    url: `/${url}/${caseId}`,
    data
  })
    .then(({ data }) => {
      dispatch({
        type: REQUEST_ACTIONS.UPDATE_FIELD,
        payload: data.data
      });
      return data;
    })
    .catch(backendValidationError);
};

export const submitRequest = (data, url) => dispatch => {
  return handleRequest('patch', data, url, dispatch).then(data => {
    dispatch(updateOnList(data.data));
    return data.data;
  });
};

export const clearAfterSubmit = () => {
  return dispatch => {
    dispatch(setSubmittedState(true));
    dispatch(setCompletedState(false));
    dispatch({ type: REQUEST_ACTIONS.CLEAR });
  };
};

export const createRequest = (data, url) => dispatch =>
  handleRequest('post', data, url, dispatch).then(response => {
    dispatch(updateOnList(response.data));
    return response.data;
  });

export const updateRequest = (data, url) => dispatch =>
  handleRequest('put', data, url, dispatch).then(response => {
    dispatch(updateOnList(response.data));
    return response.data;
  });

export const deleteRequest = caseId => () => {
  return axios({
    method: 'delete',
    url: `/client-advisors/${caseId}/`
  });
};

export const checkRequestPaymentStatus = caseId => () => {
  return axios({
    method: 'get',
    url: `/client-advisors/${caseId}/payment-status`
  });
};

export const backendValidationError = error => {
  if (error.response && error.response.status === 400) {
    const { data } = error.response.data;
    // todo: create interceptor when backend implementation completed
    if (data && (data.code === 'validation_error' || data.code === 'invalid')) {
      errorToast(
        i18n.t('common:request.form.errors.backendValidationError'),
        i18n.t('common:request.form.errors.backendValidationTitle')
      );
      console.error(data.errors);
    } else {
      console.error(error);
    }
  }

  throw error;
};
