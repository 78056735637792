import React, { useContext, useImperativeHandle, useCallback, useEffect, useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Upload, Progress } from 'antd';
import { connect } from 'react-redux';

import { BaseButton, BaseIcon, BaseLoaderSpinner, BaseButtonLink } from 'bxcommon';
import { errorToast } from 'bxcommon/store/actions';
import { RequestContext } from 'bxcommon/context/Request.context';
import { FILE_EXTENSIONS } from '../../helpers/form.helper';
import { fileAddDebouncer } from '../../helpers/common.helpers';
import { getEarliestDate } from 'bxcommon/helpers/earliestDate';

import reducer, { initialState, REDUCER_TYPES } from './BaseUploadInput.reducer';

import {
  acceptMultipleFileTypes,
  acceptPdfOnly,
  PROGRESS_STATUS_OPTIONS,
  UPLOAD_ACTIONS_OPTIONS
} from './BaseUploadInput.constants';
import { STATUS } from '../../constants/status.constant';
import AppConfig from '../../providers/AppConfig';

import { ReactComponent as Doc } from '../../assets/images/file-type-doc.svg';
import { ReactComponent as Pdf } from '../../assets/images/file-type-pdf.svg';
import { ReactComponent as Png } from '../../assets/images/file-type-png.svg';
import { ReactComponent as Jpg } from '../../assets/images/file-type-jpg.svg';

import './BaseUploadInput.scss';

const Dragger = Upload.Dragger;

/*
  Note about BaseUploadInput component

  1. Flow on automatic upload.

  * Upload from ant design uses customRequest, and invokes onAddFile when file is selected
  * Then:
  * 1st - prepare file/s and list for sending (prepareFileAndList function).
  * 2nd - invoke handleUploadRequest function, which invokes a call to backend with file in formData format.
  * 3rd - proper handling of UI list - either add a file, show loaders and progress or remove from the list on error.

  2. Flow on manual upload - only ready for single file upload.

  * Upload from ant design uses customRequest, and invokes onAddFile when file is selected
  * Then, now only for single file upload approach:
  * 1st - prepare file/s and list for sending (prepareFileAndList function).
  * 2nd - DON'T invoke handleUploadRequest function because of shouldUploadManually prop.
  * 3rd - click on submit button which invokes handleUploadRequest and therefore call to backend.
  * 4th - proper handling of UI list - either add a file, show loaders and progress or remove from the list on error.

  3. Multiple UI-wise upload in automatic upload is handled through fileAddDebouncer helper, which gathers all files
  and passes them into prepareFileAndList. Without debounce function, files would be added one by one,
  therefore not allowing for loader and progressbar UI issues.

  4. Multiple (UI-wise) upload in manual upload uses antdDisplayFiles which are passed to prepareFileAndList.

  5. There is no 'real' multiple upload (one request to backend with many files).
*/

const BaseUploadInput_ = (props, ref) => {
  const { t: common } = useTranslation('common');
  const { request, addFile, replaceFile, removeFile } = useContext(RequestContext);

  const {
    className,
    outline,
    errorToast,
    type,
    handleChange: handleChangeInStore,
    onUploadStateChange,
    maxFileSize,
    config: { shouldUploadManually, multiple, customValidators, extraFieldsMapper, pdfOnly, showFilesWithErrors },
    uploadingFiles,
    beforeUpload,
    isRenewal,
    isCodeOfConduct
  } = props;

  useEffect(() => {
    setUploaded(uploadingFiles);
  }, [uploadingFiles]);

  const [uploadedFiles, setUploaded] = useState([]);
  const [filesWithError, setFilesWithError] = useState([]);
  const [{ loading, progress, progressStatus, activeFileIndex }, updateState] = useReducer(reducer, initialState);
  const setLoading = bool => {
    updateState({ type: REDUCER_TYPES.SET_LOADING, payload: bool });
  };

  const setActiveFileIndex = index => {
    updateState({ type: REDUCER_TYPES.SET_ACTIVE_FILE_INDEX, payload: index });
  };

  const setProgressStatus = status => {
    updateState({ type: REDUCER_TYPES.SET_PROGRESS_STATUS, payload: status });
  };

  const setProgress = progress => {
    updateState({ type: REDUCER_TYPES.SET_PROGRESS, payload: progress });
  };

  const showSpinner = useCallback(
    index => {
      return loading && index === activeFileIndex;
    },
    [loading, activeFileIndex]
  );

  const showProgress = useCallback(
    index => {
      return loading && index === activeFileIndex && progress > 0;
    },
    [loading, activeFileIndex, progress]
  );

  const allowToReplace = !loading && request.status === STATUS.UPDATE_REQUIRED;
  const allowToRemove =
    !loading &&
    (AppConfig.editStatuses.includes(request.status) ||
      (shouldUploadManually && request.status === STATUS.APPROVED) ||
      (request.amendment && request.amendment.status === STATUS.DRAFT));

  const classes = classnames('base-upload-input', className, {
    'base-upload-input--outline': outline
  });

  const getFileIcon = type => {
    switch (type) {
      case FILE_EXTENSIONS.DOC:
      case FILE_EXTENSIONS.DOCX:
        return <Doc />;
      case FILE_EXTENSIONS.PDF:
        return <Pdf />;
      case FILE_EXTENSIONS.PNG:
        return <Png />;
      case FILE_EXTENSIONS.JPG:
      case FILE_EXTENSIONS.JPEG:
        return <Jpg />;
    }
  };

  const showStartOnUi = () => {
    onUploadStateChange(true);
    setProgress(0);
    setLoading(true);
  };

  const showFinishOnUi = () => {
    onUploadStateChange(false);
    setLoading(false);
  };

  const transformToFormData = file => {
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('name', file.name);
    if (type) {
      formData.append('type', type);
    }

    if (extraFieldsMapper && Object.keys(extraFieldsMapper).length > 0) {
      Object.entries(extraFieldsMapper).forEach(([key, mapFn]) => formData.set(key, mapFn(file.file, request)));
    }
    return formData;
  };

  const config = {
    onUploadProgress: progressEvent => {
      return setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    }
  };

  const renderToast = (type, value) => {
    errorToast(
      common(`request.form.errors.upload.${type}.description`, { value }),
      common(`request.form.errors.upload.${type}.title`)
    );
  };

  const validateFile = validatedFile => {
    const smallerThanLimit = validatedFile.size / 1024 / 1024 < maxFileSize;
    if (!smallerThanLimit) {
      renderToast('tooBig', maxFileSize);
      return false;
    }

    const nameShorterThanLimit = validatedFile.name.length < AppConfig.maxFileUploadNameLength;
    if (!nameShorterThanLimit) {
      renderToast('tooLong', AppConfig.maxFileUploadNameLength);
      return false;
    }

    if (customValidators.length > 0) {
      const customValidationResult = customValidators.every(customValidator => {
        const validatedValue = customValidator.fieldMapper(validatedFile[customValidator.field]);
        const result = customValidator.validatorFn(validatedValue);
        if (!result) {
          renderToast('customValidation');
        }
        return result;
      });
      if (!customValidationResult) {
        return false;
      }
    }
    return true;
  };

  const mapNewFiles = files => {
    return files.map(file => {
      return {
        id: file.uid,
        name: file.name,
        file: file
      };
    });
  };

  const uploadFile = async ({ file, docType, replacedFileId, index }) => {
    setActiveFileIndex(index);
    setProgressStatus(PROGRESS_STATUS_OPTIONS.ACTIVE);
    const formData = transformToFormData(file);
    let result;
    try {
      if (replacedFileId) {
        result = await replaceFile(formData, config, replacedFileId);
      } else {
        result = await addFile(formData, config, docType);
      }
      setProgressStatus(PROGRESS_STATUS_OPTIONS.SUCCESS);
    } catch (e) {
      if (!shouldUploadManually) {
        renderToast(replacedFileId ? UPLOAD_ACTIONS_OPTIONS.REPLACE : UPLOAD_ACTIONS_OPTIONS.ADD);
        setProgressStatus(PROGRESS_STATUS_OPTIONS.EXCEPTION);
      }
    }
    return result;
  };

  const onRemoveFile = async file => {
    if (file.updated_at) {
      const index = uploadedFiles.findIndex(f => f.id === file.id);
      setActiveFileIndex(index);
      showStartOnUi();
      try {
        await removeFile(file.id);
      } catch (_) {
        renderToast('remove');
        setActiveFileIndex(null);
      } finally {
        showFinishOnUi();
      }
    } else {
      setFilesWithError([...filesWithError.filter(f => f.uid !== file.uid)]);
    }
    let currentState = [...uploadedFiles.filter(f => f.id !== file.id)];
    if (currentState) {
      handleChangeInStore(currentState);
    }
  };

  const onReplaceFile = async (replacedFile, replacedFileId) => {
    if (!validateFile(replacedFile)) return;
    const file = mapNewFiles([replacedFile])[0];

    showStartOnUi();
    let result = [...uploadedFiles];
    const fileIndex = uploadedFiles.findIndex(f => f.id === replacedFileId);
    let uploadedFile = await uploadFile({ file, index: fileIndex, replacedFileId });
    if (uploadedFile) {
      result = result.reduce((acc, file, index) => {
        index === fileIndex ? acc.push(uploadedFile) : acc.push(file);
        return acc;
      }, []);
    }
    handleChangeInStore(result);
    showFinishOnUi();
  };

  const onAddFile = async files => {
    const incorrectFiles = [];
    files = files.filter(file => {
      const validationResult = validateFile(file);
      if (!validationResult && showFilesWithErrors) {
        incorrectFiles.push(file);
      }
      return validationResult;
    });
    setFilesWithError(incorrectFiles);

    const mappedNewFiles = mapNewFiles(files);
    const beforeUploadState = [...uploadedFiles, ...mappedNewFiles];

    setUploaded(beforeUploadState);
    if (shouldUploadManually) {
      return handleChangeInStore(beforeUploadState);
    }
    showStartOnUi();
    const result = [...uploadedFiles];
    let index = result.length;
    for (const file of mappedNewFiles) {
      let uploadedFile = await uploadFile({ file, index });
      if (uploadedFile) {
        result.push(uploadedFile);
      }
      index++;
    }
    handleChangeInStore(result);
    showFinishOnUi();
  };

  const onManualUpload = async docType => {
    showStartOnUi();
    const result = [];
    let index = 0;
    for (const file of uploadedFiles) {
      let uploadedFile = await uploadFile({ file, index, docType });
      if (uploadedFile) {
        result.push(uploadedFile);
      } else {
        result.push({
          name: file.name,
          status: 6
        });
      }
      index++;
    }
    showFinishOnUi();
    setUploaded([]);
    setFilesWithError([]);
    return result;
  };

  const debounceUpload = fileAddDebouncer(AppConfig.fileAddDebounceTime, onAddFile);

  useImperativeHandle(ref, () => ({
    manualUpload: onManualUpload,
    loading
  }));

  const renderDash = Boolean(uploadedFiles.length) && <div className="divider divider--dashed" />;
  const renderDragger = (
    <Dragger
      beforeUpload={beforeUpload}
      multiple={multiple}
      className={classes}
      accept={pdfOnly ? acceptPdfOnly : acceptMultipleFileTypes}
      name="file"
      showUploadList={false}
      fileList={uploadedFiles}
      customRequest={({ file }) => ({
        upload: debounceUpload(file),
        abort() {}
      })}
      disabled={loading}
    >
      <BaseButton
        onClick={() => beforeUpload()}
        fill={!outline}
        outline={outline}
        small={outline}
        icon="curved-arrow-up"
        iconRight
        className="base-upload-input__button"
        data-cypress="upload-input-button"
        type="button"
        disabled={loading || props.disabled}
      >
        {common('request.form.common.uploadFile')}
      </BaseButton>
    </Dragger>
  );

  const renderErrorFiles = (
    <div className="base-upload__list">
      {filesWithError.map((fileObject, index) => (
        <div
          key={index}
          className="base-upload__list-item base-upload__list-item--invalid"
          data-cypress="base-upload__list-item"
        >
          <div className="base-upload__list-item-content-wrapper">
            <div className="base-upload__list-item-content">
              <span className="base-upload__list-icon">
                {fileObject.name && getFileIcon(fileObject.name.split('.').pop())}
              </span>
              <p className="base-upload__list-name copy copy--color-dark" data-cypress="base-upload-file-name">
                {fileObject.name}
              </p>
            </div>
            <BaseIcon
              icon="times"
              small
              type="button"
              onClick={() => onRemoveFile(fileObject)}
              data-cypress="base-upload-remove"
            />
          </div>
        </div>
      ))}
    </div>
  );

  const renderUploadedFiles = (
    <div className="base-upload__list">
      {uploadedFiles.map((fileObject, index) => (
        <div key={fileObject.id} className="base-upload__list-item" data-cypress="base-upload__list-item">
          <div className="base-upload__list-item-content-wrapper">
            <div className="base-upload__list-item-content">
              <span className="base-upload__list-icon">
                {fileObject.name && getFileIcon(fileObject.name.split('.').pop())}
              </span>
              <p className="base-upload__list-name copy copy--color-dark" data-cypress="base-upload-file-name">
                {fileObject.name}
              </p>
            </div>
            <div className="base-upload__list-actions">
              {allowToReplace && (
                <Dragger
                  multiple={multiple}
                  className="base-upload__list-item-replace-upload"
                  accept={pdfOnly ? acceptPdfOnly : acceptMultipleFileTypes}
                  showUploadList={false}
                  fileList={uploadedFiles}
                  customRequest={({ file }) => ({
                    upload: onReplaceFile(file, fileObject.id),
                    abort() {}
                  })}
                  disabled={loading}
                >
                  {!showSpinner(index) && (
                    <span
                      className="copy copy--color-primary base-upload__list-item-replace"
                      data-cypress="base-upload-replace"
                    >
                      {common('request.form.common.replace')}
                    </span>
                  )}
                </Dragger>
              )}
              {!showSpinner(index) && allowToRemove && (
                <>
                  {isRenewal ? (
                    new Date(fileObject.created_at) -
                      new Date(
                        getEarliestDate([
                          request.request_submission_date,
                          request.last_renewal_submission_date,
                          request.last_amendment_submission_date
                        ])
                      ) >
                      1 || isCodeOfConduct ? (
                      <BaseButtonLink
                        className="base-upload__list-item-remove"
                        onClick={() => onRemoveFile(fileObject)}
                        data-cypress="base-upload-remove"
                      >
                        {common('request.form.common.delete')}
                      </BaseButtonLink>
                    ) : null
                  ) : (
                    <BaseIcon
                      className="base-upload__list-item-remove"
                      icon="times"
                      small
                      type="button"
                      onClick={() => onRemoveFile(fileObject)}
                      data-cypress="base-upload-remove"
                    />
                  )}
                </>
              )}
            </div>
            {showSpinner(index) && <BaseLoaderSpinner isComponent className="base-upload__list-item-loader" />}
          </div>
          {showProgress(index) && <Progress percent={progress} status={progressStatus} default="small" />}
        </div>
      ))}
    </div>
  );

  return isRenewal ? (
    <>
      {renderErrorFiles}
      {renderUploadedFiles}
      {renderDragger}
    </>
  ) : (
    <>
      {renderDragger}
      {renderDash}
      {renderErrorFiles}
      {renderUploadedFiles}
    </>
  );
};

BaseUploadInput_.propTypes = {
  className: PropTypes.string,
  outline: PropTypes.bool,
  errorToast: PropTypes.func,
  files: PropTypes.array,
  type: PropTypes.number.isRequired,
  handleChange: PropTypes.func,
  onUploadStateChange: PropTypes.func,
  disabled: PropTypes.bool,
  shouldUploadManually: PropTypes.bool,
  multiple: PropTypes.bool,
  maxFileSize: PropTypes.number.isRequired
};

export const BaseUploadInput = connect(null, { errorToast }, null, { forwardRef: true })(
  React.forwardRef((props, ref) => BaseUploadInput_(props, ref))
);
