import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './BaseBoxHeading.scss';

export const BaseBoxHeading = ({children, className}) => {

  return (
    <div className={classnames('base-box__heading', className)}>
      <div className="divider divider--color-primary" />
      <span>{children}</span>
      <div className="divider divider--color-primary" />
    </div>
  );
};

BaseBoxHeading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};
