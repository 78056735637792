import axios from 'axios';


export const LIST_ACTIONS = {
  HYDRATE_LIST: 'hydrate_list',
  ADD_TO_LIST: 'add_to_list',
  UPDATE_ON_LIST: 'update_on_list',
  REMOVE_FROM_LIST: 'remove_from_list',
  CLEAR_LIST: 'clear_list'
};

export const hydrateList = (url) => (search, query) => {
  const params = search ? `?${query}=${search}` : '';
  return (dispatch) => {
    return axios.get(`/${url}/${params}`)
      .then(({data}) => {
        dispatch({
          type: LIST_ACTIONS.HYDRATE_LIST,
          payload: data.data
        });
        return data.data;
      });
  }
};

export const removeFromList = (url) => (caseId) => {
  return dispatch => {
    return axios.delete(`/${url}/${caseId}/`)
      .then(() => {
        dispatch({
          type: LIST_ACTIONS.REMOVE_FROM_LIST,
          payload: caseId
        });
      });
  }
};

export const updateOnList = (payload) => {
  return (dispatch) => dispatch({
    type: LIST_ACTIONS.UPDATE_ON_LIST,
    payload
  })
};

export const clearList = () => {
  return dispatch => {
    dispatch({type: LIST_ACTIONS.CLEAR_LIST});
  }
};
