import axios from 'axios';

export const SELECTED_USER_GROUP_ACTIONS = {
  SET_USER_GROUP: 'set_selected_user_group'
};

export const addUserGroup = (payload) => {
  return (dispatch) => {
    return axios.post(`/groups/`, payload)
      .then(({data}) => {
        dispatch({
          type: SELECTED_USER_GROUP_ACTIONS.SET_USER_GROUP,
          payload: data.data
        });
      });
  };
};

export const updateUserGroup = (payload, id) => {
  return (dispatch) => {
    return axios.put(`/groups/${id}/`, payload)
      .then(({data}) => {
        dispatch({
          type: SELECTED_USER_GROUP_ACTIONS.SET_USER_GROUP,
          payload: data.data
        });
      });
  };
};

export const setUserGroup = (payload) => {
  return dispatch => {
    dispatch({
      type: SELECTED_USER_GROUP_ACTIONS.SET_USER_GROUP,
      payload
    });
  }
};
