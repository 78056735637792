import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {AppHeaderAdmin, AppHeaderDefault, BaseButtonFill, BaseDropdown} from 'bxcommon';
import { history } from 'bxcommon/BxBrowserRouter';

import { setUserGroup } from '../../store/actions';
import { clearForm } from 'bxcommon/store/actions';

const ProspectusHeader_= ({children, loggedIn, setUserGroup, clearForm}) => {
  const { t } = useTranslation();

  const newRequestButton = (
    <BaseButtonFill
      iconRight
      icon="add"
      data-cypress="header-add-request"
    >
      {t('list.buttons.createNewRequest')}
    </BaseButtonFill>
  );

  const requestTypes = [
    {
      label: 'Equity security',
      link: '/request/equity',
      icon: 'equity',
      disabled: false,
      runBefore: clearForm
    },
    {
      label: 'Debt security',
      link: '/request/debt',
      icon: 'debt',
      disabled: false,
      runBefore: clearForm
    },
    {
      label: 'Derivative security',
      link: '/request/derivative',
      icon: 'derivative',
      disabled: false,
      runBefore: clearForm
    }
  ];

  const addNewProspectus = loggedIn && (
    <div className="app-header__new-request">
      <BaseDropdown>
        {{
          triggerElement: newRequestButton,
          menuContent: requestTypes
        }}
      </BaseDropdown>
    </div>
  );

  const clearAndGoToGroup = () => {
    setUserGroup({});
    history.push('/groups/edit')
  };

  const createNewUserGroup = loggedIn && (
    <div className="app-header__new-request">
      <BaseButtonFill
        iconRight
        icon="add"
        data-cypress="header-create-group"
        onClick={clearAndGoToGroup}

      >
        {t('userGroups.buttons.create')}
      </BaseButtonFill>
    </div>
  );

  return (
    <AppHeaderDefault
      adminHeader={<AppHeaderAdmin isProspectus />}
      controls={history.location.pathname.includes('/groups') ? createNewUserGroup : addNewProspectus}
    >
      {children}
    </AppHeaderDefault>
  )
};

ProspectusHeader_.propTypes = {
  children: PropTypes.node.isRequired,
  loggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  loggedIn: state.user.loggedIn
});

export const ProspectusHeader = connect(mapStateToProps, { setUserGroup, clearForm })(ProspectusHeader_);
