import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseIcon } from 'bxcommon';
import { scrollToTop } from 'bxcommon/helpers/scrollTop.helpers';

import './FooterComponent.scss';

export const FooterComponent = () => {
  const { t: common } = useTranslation('common');

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer__info-wrapper">
        <div className="container">
          <div className="footer__info">
            <div className="footer__company">
              <p className="copy copy--bold copy--color-white">BX Swiss AG</p>
              <p className="copy copy--color-white">Talstrasse 70</p>
              <p className="copy copy--color-white">CH-8001 Zurich</p>
            </div>
            <div className="footer__contact">
              <div className="footer__contact-item">
                <BaseIcon icon="phone" white />
                <a href="tel:+41313294055" rel="noopener noreferrer">
                  +41 31 329 40 55
                </a>
              </div>
              <div className="footer__contact-item">
                <BaseIcon icon="mail" white />
                <a href="mailto:office@regservices.ch" rel="noopener noreferrer">
                  office@regservices.ch
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <div className="container footer__copyright-content">
          <div>
            <span>
              &copy; {currentYear} Regservices.ch - {common('footer.allRightsReserved')}
            </span>
            {/*TODO add links to impressum and privacy policy*/}
            <a href="https://www.regservices.ch/en/impressum/" target="_blank" rel="noopener noreferrer">
              <span className="copy copy--color-white">{common('footer.impressum')}</span>
            </a>
            <a href="https://www.regservices.ch/en/privacy-policy/" target="_blank" rel="noopener noreferrer">
              <span className="copy copy--color-white">{common('footer.privacyPolicy')}</span>
            </a>
          </div>
          <BaseIcon
            className="footer-scroll-top"
            icon="arrow-left"
            white
            data-cypress="footer-scroll-top"
            onClick={scrollToTop}
          />
        </div>
      </div>
    </footer>
  );
};
