import listReducer from 'bxcommon/store/reducers/list.reducer';
import requestReducer from 'bxcommon/store/reducers/request.reducer';
import requestMetaReducer from 'bxcommon/store/reducers/requestMeta.reducer';
import resourcesReducer from 'bxcommon/store/reducers/resources.reducer';
import toasterReducer from 'bxcommon/store/reducers/toaster.reducer';
import userReducer from 'bxcommon/store/reducers/user.reducer';
import userGroupsReducer from './userGroups.reducer';
import selectedUserGroupReducer from './selectedUserGroup.reducer';

const requestInitialState = {
  name: '', // string
  instrument_id: '', // string
  publish_here: false, // bool
  publish_on: null,
  is_already_published: false,
  already_published_location: '',
  already_published_date: null,
  paper_version: '',
  issue_type: null,
  stock_exchange: null,
  issue_date: null, // number
  type: null,
  foreign_type: null,
  scheme: null,
  issuer_name: '',
  issuers: [],
  guarantors: '',
  invoice: {
    issuer_name: '',
    first_name: '', // string
    last_name: '', // string
    address_line_1: '', // string
    address_line_2: '', // string
    postcode: '', // string
    city: '', // string
    country: null,
    invoice_type: 1,
  },
  maturity_date: null,
  security_type: null,
  declaration_files: [],
  prospectus_files: [],
  incorporated_files: [],
  additional_files: [],
  final_terms_files: []
};


export default {
  list: listReducer(),
  request: requestReducer(requestInitialState),
  requestMeta: requestMetaReducer(),
  resources: resourcesReducer(),
  toasts: toasterReducer(),
  user: userReducer(),
  userGroups: userGroupsReducer(),
  selectedUserGroup: selectedUserGroupReducer()
};
