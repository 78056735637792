import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  BaseBox,
  BaseBoxHeading,
  BaseButtonFill,
  BaseIcon,
  BaseLoader,
  BaseStatus,
  BaseTable,
  RequestStepWrapper
} from 'bxcommon';
import { RequestDataWrapper } from 'bxcommon/components';
import { createSelect, createUploadBox, requiredFilesValidator } from 'bxcommon/helpers/form.helper';
import errorService from 'bxcommon/helpers/errors.helper';
import { STATUS } from 'bxcommon/constants/status.constant';

import { ProspectusHeader } from '../ProspectusHeader/ProspectusHeader';
import AppConfig from 'bxcommon/providers/AppConfig';
import { RequestContext } from 'bxcommon/context/Request.context';
import { errorToast, successToast } from "bxcommon/store/actions";
import config from '../../prospectus.config';
import {
  getAddendumList,
  deleteAdditionalFiles,
  patchAdditionalFiles
} from '../../common/requests';

import './Addendum.scss';

const {maxFileSize, ADDITIONAL_FILE_TYPES} = config;
const Addendum = (props) => {
  const {request, resources: {addendum_type}} = useContext(RequestContext);
  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const formRef = useRef(null);
  const {t} = useTranslation();
  const {t: common} = useTranslation('common');
  const {
    successToast,
    errorToast
  } = props;

  const blockUpload = useMemo(() => {
    return list.some(entry => entry.status === STATUS.IN_REVIEW);
  }, [list]);

  const showDeleteButton = useMemo(() => {
    return list.some(entry => entry.status === STATUS.DRAFT);
  }, [list]);

  useEffect(() => {
    if (request.case_id && request.status !== STATUS.APPROVED) {
      props.history.push('/list');
    }
  }, [request.case_id, request.status, props.history]);

  const getList = useCallback(async () => {
    try {
      setList(await getAddendumList(request.case_id));
    } catch (e) {
      errorService.showBackendErrors(e);
    } finally {
      setListLoading(false);
    }
  }, [request.case_id]);

  useEffect(() => {
    if (request.case_id) {
      getList();
    }
  }, [request, getList]);

  const refetchData = (bool) => {
    if (bool) {
      setListLoading(true);
      getList();
    }
  };

  const onDeleteClick = async () => {
    try {
      await deleteAdditionalFiles(request.case_id, ADDITIONAL_FILE_TYPES.ADDENDUM);
      setList([]);
      successToast(
        common('request.form.common.allDraftFilesRemoved'),
        common('request.form.common.success')
      )
    } catch (e) {
      errorToast(
        common(`request.form.errors.defaultErrorDescription`),
        common(`request.errors.error`)
      )
    }
  };

  const uploadInputRef = useRef(null);
  const renderUploadBox = createUploadBox(() => {
  }, formRef, refetchData);
  const initialValues = {
    uploadType: 5,
    files: []
  };

  const validationSchema = Yup.object().shape({
    uploadType: Yup.mixed().required(common('errors.requiredField')),
    files: requiredFilesValidator()
  });

  const onFormSubmit = async () => {
    setDisabled(true);
    let results = await uploadInputRef.current.manualUpload(ADDITIONAL_FILE_TYPES.ADDENDUM);
    const withError = results.some(file => file.status === STATUS.ERROR);

    if (withError) {
      await deleteAdditionalFiles(request.case_id, ADDITIONAL_FILE_TYPES.ADDENDUM);
      errorToast(
        common(`request.form.errors.upload.backendErrorDuringUpload.description`),
        common(`request.form.errors.upload.backendErrorDuringUpload.title`),
      );
    } else {
      let finalList = await patchAdditionalFiles(request.case_id, ADDITIONAL_FILE_TYPES.ADDENDUM);
      successToast(
        common('request.form.common.allFilesUploadedSuccessfully'),
        common('request.form.common.success')
      );
      setList([...list, ...finalList]);
    }
    setDisabled(false);
  };

  const splitNameAndRemoveUnnecessaryElements = (name) => {
    const splitName = name.split('_');
    splitName.splice(0,5 );
    return splitName;
  };
  const preparingToDisplay = (addendums) => {
    const sorted = addendums.sort((a, b) => a.created_at > b.created_at ? -1 : 1);
    const result = [];
    let prevFileIdx = null;
    let index = 0;
    sorted.forEach((current, idx) => {
      const currFileIdx = splitNameAndRemoveUnnecessaryElements(current.name).pop();
      if (idx === 0) {
        result[index] = [current];
        prevFileIdx = currFileIdx;
        return;
      }
      if (!currFileIdx || !prevFileIdx || parseInt(currFileIdx) >= parseInt(prevFileIdx)) {
        index++;
      }
      result[index] ? result[index].push(current) : result[index] = [current];
      prevFileIdx = currFileIdx;
    });
    return result;
  };

  return (
    <ProspectusHeader>
      <div className="addendum">
        <RequestDataWrapper
          redirect={'/list'}
          {...props}
        >
          <RequestStepWrapper
            noSteps
            hidden
            goBack={`../details/${request.case_id}`}
            {...props}
          >
            <BaseLoader loading={listLoading} large>
              <BaseBox
                className="request-form__box"
                lifted
              >
                <BaseBoxHeading>{t('addendum.heading')}</BaseBoxHeading>
                {!blockUpload && <Formik
                  ref={formRef}
                  initialValues={initialValues}
                  onSubmit={() => onFormSubmit()}
                  validationSchema={validationSchema}
                >
                  {({values}) => (
                    <Form className="form-container">
                      <div className="col-10">
                        <div className="row">
                          {createSelect({
                            name: 'uploadType',
                            label: t('addendum.uploadType'),
                            options: addendum_type.data
                          })}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-10">
                          <div className="addendum__upload">
                            {renderUploadBox({
                              name: 'files',
                              conditions: common('request.form.common.fileDefaultsPDF'),
                              type: values.uploadType,
                              maxFileSize,
                              config: {
                                shouldUploadManually: true,
                                multiple: true
                              },
                              uploadInputRef
                            })}
                          </div>

                          <BaseButtonFill
                            type="submit"
                            className="base-upload-box__submit-button"
                            loading={disabled}
                            disabled={disabled}
                            data-cypress="submit-button"
                          >
                            {common('submit')}
                          </BaseButtonFill>

                          {showDeleteButton && <BaseButtonFill
                            type="button"
                            className="base-upload-box__submit-button"
                            onClick={onDeleteClick}
                          >
                            {t('addendum.removeFilesButton')}
                          </BaseButtonFill>}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>}

                {blockUpload &&
                <div className="addendum__info-wrapper">
                  <BaseIcon icon="info"/>
                  <p
                    className="copy copy--color-dark copy--small addendum__info"
                    data-cypress="addendum-upload-not-allowed"
                  >
                    {t('addendum.uploadNotAllowed')}
                  </p>
                </div>
                }
              </BaseBox>
              {list.length ? (
                <BaseTable labels={t('addendum.formLabels', {returnObjects: true})}>
                  {preparingToDisplay(list)
                    .map((addendum, idx) => (
                      <tr
                        key={idx}
                        className="addendum__table-item"
                        data-cypress="addendum-table-item"
                      >
                        <td className="addendum__table--with-icon">
                          {addendum.map((file, idx2) => (
                            <div key={idx2}>
                              <BaseIcon icon="file-type-pdf"/>
                              <a href={file.protected_url} target="_blank" rel="noopener noreferrer">
                                <span>{file.name}</span>
                              </a>
                            </div>
                          ))}
                        </td>
                        <td>
                            {addendum[0].created_at && moment(addendum[0].created_at).format(AppConfig.dayMonthHourFormat)}
                        </td>
                        <td><BaseStatus status={addendum[0].status}/></td>
                      </tr>
                    ))}
                </BaseTable>
              ) : (
                <div className="addendum__info-wrapper">
                  <BaseIcon icon="info"/>
                  <p
                    className="copy copy--color-dark copy--small addendum__info"
                    data-cypress="addendum-empty-list"
                  >
                    {t('addendum.emptyList')}
                  </p>
                </div>
              )}
            </BaseLoader>
          </RequestStepWrapper>
        </RequestDataWrapper>
      </div>
    </ProspectusHeader>
  );
};

export default connect(null, {errorToast, successToast})(Addendum);
