import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BaseButton } from '..';
import { noop } from '../../helpers/common.helpers';
import './RequestDeletionConfirmationModal.scss';

export const RequestDeletionConfirmationModal = props => {
  const { t: common } = useTranslation('common');

  return (
    <Modal
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto' }}
      className="search-form"
      closable={true}
      footer={props.footer}
      onCancel={props.onCancel}
      title={props.title}
      visible={props.visible}
      width={680}
    >
      <div className="request-deletion-confirmation-modal__content">
        <div className="request-deletion-confirmation-modal__text">
          <p className="copy copy--color-dark">{common('request.requestDeletion.confirmationInfo')}</p>
        </div>
        <BaseButton
          onClick={props.onConfirm}
          className="multiline-button"
          data-cypress="details-delete-registration-button"
          fill={true}
        >
          {common('request.requestDeletion.continue')}
        </BaseButton>
      </div>
    </Modal>
  );
};

RequestDeletionConfirmationModal.propTypes = {
  footer: PropTypes.node,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.node,
  visible: PropTypes.bool
};

RequestDeletionConfirmationModal.defaultProps = {
  footer: null,
  onCancel: noop,
  onConfirm: noop,
  title: null,
  visible: false
};
