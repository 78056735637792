import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import className from 'classnames';

import './Captcha.scss';


export const Captcha = (props) => {
  const { t: common } = useTranslation('common');

  const checkCaptcha = () => {
    if (!Boolean(window.grecaptcha.getResponse())) {
      props.setCaptchaError(true);
    } else {
      props.setCaptchaError(false);
    }
  };

  useLayoutEffect(() => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.render('captcha', {
        sitekey: process.env.REACT_APP_CAPTCHA_KEY,
        callback: checkCaptcha
      });
    })
  }, []);

  useEffect(() => {
    if (props.validateOnSubmitCount) {
      checkCaptcha()
    }
  }, [props.validateOnSubmitCount]);


  return (
    <div
      className={className('row', 'captcha', {
        'error': props.captchaError
      })}
      data-cypress="captcha"
    >
      <div id="captcha"/>
      <div className="ant-form-explain">
        {common('errors.requiredField')}
      </div>
    </div>
  )
};

Captcha.propTypes = {
  setCaptchaError: PropTypes.func.isRequired,
  captchaError: PropTypes.bool.isRequired,
  validateOnSubmitCount: PropTypes.number.isRequired
};
