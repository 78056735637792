import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { getProtectedBlobURL } from '../../helpers/common.helpers';
import './ProtectedDownloadHandler.scss'

export const ProtectedDownloadHandler = ({ children, filename, href }) => {
  const linkRef = useRef(null);
  const [linkHref, setLinkHref] = useState(null);

  const handleClick = async (event) => {
    event.preventDefault();
    const fileBlobURL = await getProtectedBlobURL(href);
    setLinkHref(fileBlobURL);
  }

  useEffect(() => {
    if (!linkRef.current || !linkHref) return;

    linkRef.current.download = filename;
    linkRef.current.href = linkHref;
    linkRef.current.click();
  }, [linkHref, linkRef.current]);


  return (
    <>
      {children({
        onClick: handleClick
      })}
      <a
        className="protected-download-link"
        ref={linkRef}
      />
    </>
  );
};

ProtectedDownloadHandler.propTypes = {
  children: PropTypes.func,
  filename: PropTypes.string,
  href: PropTypes.string.isRequired,
};

ProtectedDownloadHandler.defaultProps = {
  children: null,
  filename: "file.zip"
};
