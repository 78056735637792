export const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
};

export const initialStateMerger = (baseInitialState) => (initialState) => {
  if (!initialState) {
    return baseInitialState;
  }

  return Array.isArray(baseInitialState)
    ?  [...baseInitialState, ...initialState]
    : {...baseInitialState, ...initialState};
};
