import React from 'react';
import PropTypes from 'prop-types';

import { BaseIcon } from 'bxcommon';
import { FILE_EXTENSIONS } from '../../helpers/form.helper';

import './SummaryHelpers.scss';

const SummaryFile = ({ name, type }) => {
  let icon = null;

  switch (type) {
    case FILE_EXTENSIONS.DOC:
    case FILE_EXTENSIONS.DOCX:
      icon = <BaseIcon icon="file-type-doc" />;
      break;
    case FILE_EXTENSIONS.PDF:
      icon = <BaseIcon icon="file-type-pdf" />;
      break;
    case FILE_EXTENSIONS.PNG:
      icon = <BaseIcon icon="file-type-png" />;
      break;
  }
  return (
    <>
      <span className="summary-file__icon">{icon}</span>
      <span className="summary-file__name" data-cypress="summary-file-name">
        {name}
      </span>
    </>
  );
};

SummaryFile.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string
};

const SummaryLink = ({ href, download, key, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="summary-file__link link--underlined"
    download={download}
    key={key}
  >
    {children}
  </a>
);

SummaryLink.propTypes = {
  href: PropTypes.string,
  download: PropTypes.bool,
  key: PropTypes.number,
  children: PropTypes.element
};

export const SummaryFilesList = ({ files, download }) =>
  files && files.length
    ? files.map((file, idx) =>
        download ? (
          <div className="summary-file" key={idx}>
            <SummaryLink href={file.file} download={download}>
              <SummaryFile name={file.name} type={file.name.split('.').pop()} />
            </SummaryLink>
            <SummaryLink href={file.file}>
              <span className="summary-file__icon summary-file__icon--open">
                <BaseIcon icon="openFile" />
              </span>
            </SummaryLink>
          </div>
        ) : (
          <a
            href={file.file}
            target="_blank"
            rel="noopener noreferrer"
            className="summary-file__link link--underlined"
            key={idx}
          >
            <SummaryFile name={file.name} type={file.name.split('.').pop()} />
          </a>
        )
      )
    : '-';

SummaryFilesList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.number
    })
  ),
  download: PropTypes.bool
};
