import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';

import { BaseFormFieldWrapper } from '../BaseFormFieldWrapper/BaseFormFieldWrapper';
import './BaseDatePicker.scss';

const BaseDatePicker_ = props => {
  const { field, onBlur, handleChange, full, ...rest } = props;
  const className = classnames({
    'date-picker--full': full
  });

  return <DatePicker {...field} {...rest} className={className} onBlur={onBlur} onChange={handleChange} />;
};

BaseDatePicker_.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any
  }),
  onBlur: PropTypes.func,
  handleChange: PropTypes.func
};

export const BaseDatePicker = BaseFormFieldWrapper(BaseDatePicker_);
