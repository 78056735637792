import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import { BaseFormFieldWrapper } from '../BaseFormFieldWrapper/BaseFormFieldWrapper'

import './BaseCheckboxGroup.scss';

const BaseCheckboxGroup_ = props => {
  const {field, onBlur, handleChange, customKeys, options, ...rest} = props;

  const mappedOptions = (options, keys = { label: 'name', value: 'id' }) => {
    return options.map(o => ({
      label: o[keys.label],
      value: o[keys.value]
    }));
  };

  return (
    <Checkbox.Group
      {...field}
      {...rest}
      options={customKeys ? mappedOptions(options, customKeys) : mappedOptions(options)}
      onBlur={onBlur}
      onChange={handleChange}
    />
  );
};

BaseCheckboxGroup_.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any
  }),
  onBlur: PropTypes.func,
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  customKeys: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  })
};

export const BaseCheckboxGroup = BaseFormFieldWrapper(BaseCheckboxGroup_);
