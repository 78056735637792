import axios from 'axios';

export const REGISTRY_ACTIONS = {
  HYDRATE_RESULT: 'hydrate_result'
};

export const getRegistryResult = ({first_name, last_name, case_id}) => {
  const firstName = first_name ? `?first_name=${first_name}` : '';
  const lastName = last_name ? `&last_name=${last_name}` : '';
  const caseId = case_id ? `?case_id=${case_id}` : '';

  return (dispatch) => {
    return axios.get(`/client-advisors/registry/${firstName}${lastName}${caseId}`)
      .then(({data}) => {
        dispatch({
          type: REGISTRY_ACTIONS.HYDRATE_RESULT,
          payload: data.data
        });
      });
  }
};
