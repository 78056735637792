import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import { BaseButtonFill, BaseButtonLink, BaseIcon } from 'bxcommon';

import './BaseModal.scss';

export const MODAL_RESPONSE_TYPE = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  CLOSE: 'close'
};

export const BaseModal = props => {
  const {icon, title, description, customContent, okButton, cancelButton, response, visible, customClass} = props;

  return (
    <Modal
      centered
      visible={visible}
      className={customClass}
      title={(
        <div className="base-modal__header">
          <BaseIcon
            className="base-modal__header-icon"
            white
            icon={icon}
          />
          <BaseButtonLink
            className="base-modal__header-close-icon"
            iconLeft
            iconWhite
            icon="times"
            onClick={() => response(MODAL_RESPONSE_TYPE.CLOSE)}
            data-cypress="modal-close-icon"
          />
          <h2 className="heading heading--subsection heading--color-white">{title}</h2>
        </div>
      )}
      // hide default close icon
      closable={false}
      footer={(
        <div className="base-modal__footer">
          <BaseButtonFill
            data-cypress="modal-accept-button"
            onClick={() => response(MODAL_RESPONSE_TYPE.ACCEPT)}
          >
            {okButton}
          </BaseButtonFill>
          {cancelButton &&
            <BaseButtonLink
              iconLeft
              icon="arrow-right"
              data-cypress="modal-reject-button"
              onClick={() => response(MODAL_RESPONSE_TYPE.REJECT)}
            >
              {cancelButton}
            </BaseButtonLink>
          }
        </div>
      )}
    >
      <div className="base-modal__content">
        {customContent ? description : <p className="copy copy--color-dark">{description}</p>}
      </div>
    </Modal>
  );
};

BaseModal.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]),
  okButton: PropTypes.string,
  cancelButton: PropTypes.string,
  visible: PropTypes.bool,
  bodyStyle: PropTypes.object,
  customContent: PropTypes.bool,
  response: PropTypes.func
};
