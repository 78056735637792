import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { BaseBox, BaseIcon } from 'bxcommon';

import './HowToStart.scss';

export const HowToStart = () => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const renderIconBox = (icon, translation) => (
    <div className="how-to-start__icons-item">
      <BaseIcon icon={icon} />
      <p>
        {translation}
      </p>
    </div>
  );

  const renderItem = (icon, translation) => (
    <li className="how-to-start__instructions-list-item">
      <BaseIcon icon={icon} dark />
      <p className="copy copy--color-dark">
        {translation}
      </p>
    </li>
  );

  return (
    <BaseBox
      className="how-to-start"
      data-cypress="how-to-start"
      paddingSmall
    >
      <h2 className="heading heading--primary heading--color-primary heading--uppercase">
        {t('howToStart.heading')}
      </h2>

      <div className="how-to-start__icons">
        <div className="how-to-start__icons-connector" />
        <div className="row">
          <div className="col-4">
            {renderIconBox('add', t('howToStart.create'))}
          </div>
          <div className="col-4">
            {renderIconBox('desktop-upload', t('howToStart.uploadDocuments'))}
          </div>
          <div className="col-4">
            {renderIconBox('mail', common('submit'))}
          </div>
        </div>
      </div>

      <div className="how-to-start__instructions">
        <div className="row">
          <div className="col-4">
            <div className="how-to-start__instructions-item">
              <p className="copy copy--color-dark">
                {t('howToStart.createNewRequest')}
              </p>
              <ul className="how-to-start__instructions-list">
                {renderItem('equity', t('howToStart.equitySecurity'))}
                {renderItem('debt', t('howToStart.debtSecurity'))}
                {renderItem('derivative', t('howToStart.derivative'))}
              </ul>
            </div>
          </div>
          <div className="col-4">
            <div className="how-to-start__instructions-item">
              <p className="copy copy--color-dark">
                {t('howToStart.documents')}
              </p>
              <ul className="how-to-start__instructions-list">
                {renderItem('file-type-pdf', t('howToStart.prospectus'))}
                {renderItem('file-type-pdf', t('howToStart.referenceDocuments'))}

                <li className="how-to-start__instructions-list-item">
                  <BaseIcon icon="file-type-pdf" dark />
                  <div>
                    <p className="copy copy--color-dark">
                      {t('howToStart.issuerDeclaration')}
                    </p>
                    <p className="copy">
                      <Trans i18nKey="howToStart.ifTemplateNeeded">
                        <a
                          href="https://www.regservices.ch/?download=6319"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link--underlined"
                        >
                          here
                        </a>
                      </Trans>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-4">
            <div className="how-to-start__instructions-item">
              <p className="copy copy--color-dark">
                {t('howToStart.submitRequest')}
              </p>
              <ul className="how-to-start__instructions-list">
                <li className="how-to-start__instructions-list-item">
                  <BaseIcon icon="info" dark />
                  <p className="copy copy--color-dark">
                    <Trans i18nKey="howToStart.fees">
                      <a
                        href={process.env.REACT_APP_FEES_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link--underlined"
                      >
                        List of Fees
                      </a>
                    </Trans>
                    <Trans i18nKey="howToStart.rules">
                      <a
                        href={process.env.REACT_APP_RULES_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="link--underlined"
                      >
                        Rules of Procedure.
                      </a>
                    </Trans>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BaseBox>
  );
};
