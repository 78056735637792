import React from 'react';
import PropTypes from 'prop-types';

import './HalfPageLayout.scss'

export const HalfPageLayout = ({ leftContent = null, rightContent }) => (
  <div className="row half-page">
    <div className="col-6 half-page__left">
      { leftContent }
    </div>
    <div className="col-6 half-page__right">
      { rightContent }
    </div>
  </div>
)

HalfPageLayout.propTypes = {
  leftContent: PropTypes.node,
  rightContent: PropTypes.node.isRequired
};
