import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { BaseButtonLink, BaseButtonFill, BaseButtonOutline, BaseBox } from '..';
import { RequestContext } from 'bxcommon/context/Request.context';
import { FORM_VARIANT } from 'registry/src/constants/form.constant';

import './RequestFooter.scss';

const { REQUEST_FORM, MAIN } = FORM_VARIANT;

export const RequestFooter = ({
  previousStep,
  currentStep,
  saveAndReturn,
  saveAndContinue,
  disabled,
  noSteps,
  variant = MAIN,
  cancelAmendment,
  nextStep,
  editForm,
  goToEditForm,
  cancelEditForm,
  isSummary,
  isSubmit,
  isRenewal,
  isCodeOfConduct,
  isUpdateRequire
}) => {
  const { t: common } = useTranslation('common');
  const { metadata } = useContext(RequestContext);
  const isRequestForm = variant === REQUEST_FORM;

  return (
    <div
      className={classnames(
        'request-footer',
        { [`request-footer--${variant}`]: variant },
        { container: !isRequestForm }
      )}
    >
      <div className="row">
        <div className={isRequestForm ? 'col-3 request-footer__sidebar' : 'col-7'}>
          {!isRequestForm && currentStep > 1 && (
            <BaseButtonLink onClick={previousStep} data-cypress="request-footer-return-button">
              {common('request.footer.back')}
            </BaseButtonLink>
          )}
        </div>
        <div className={isRequestForm ? 'col-8 request-footer__buttons' : 'col-7 request-footer__buttons'}>
          {!isRequestForm && (
            <div className="row">
              {saveAndReturn && (
                <BaseButtonOutline
                  type="submit"
                  disabled={disabled}
                  onClick={saveAndReturn}
                  data-cypress="request-footer-return-button"
                >
                  {/*TODO: for noSteps this button should porbably be removed */}
                  {noSteps ? common('request.footer.save') : common('request.footer.saveForLater')}
                </BaseButtonOutline>
              )}
              {saveAndContinue && (
                <BaseButtonFill
                  type="submit"
                  onClick={saveAndContinue}
                  disabled={disabled}
                  data-cypress="request-footer-continue-button"
                >
                  {metadata.requestCompleted
                    ? common('request.footer.saveAndSummary')
                    : common('request.footer.saveAndContinue')}
                </BaseButtonFill>
              )}
            </div>
          )}
          {isRequestForm && !isRenewal && (
            <BaseBox borderless>
              <div className="request-footer__wrapper">
                {cancelAmendment ? (
                  <>
                    <div className="request-footer__col request-footer__col--normal">
                      <div className="request-footer__cancel">
                        <BaseButtonLink onClick={cancelAmendment} data-cypress="request-footer-return-button">
                          {common('request.footer.cancel')}
                        </BaseButtonLink>
                      </div>
                    </div>
                    <div className="request-footer__col request-footer__col--normal request-footer__col--end">
                      {currentStep > 1 && (
                        <BaseButtonOutline onClick={previousStep} data-cypress="request-footer-return-button">
                          {common('request.footer.stepBack')}
                        </BaseButtonOutline>
                      )}
                      <BaseButtonFill
                        type="submit"
                        onClick={saveAndContinue}
                        disabled={disabled}
                        iconRight={isSummary || isSubmit}
                        icon={isSummary || isSubmit ? 'paper-airplane' : null}
                        data-cypress="request-footer-continue-button"
                      >
                        {isSubmit && common('request.footer.submit')}
                        {isSummary && common('request.footer.submit')}
                        {!isSummary && !isSubmit && common('request.footer.next')}
                      </BaseButtonFill>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="request-footer__col request-footer__col--normal">
                      {isRequestForm && currentStep > 1 && (
                        <BaseButtonOutline onClick={previousStep} data-cypress="request-footer-return-button">
                          {common('request.footer.stepBack')}
                        </BaseButtonOutline>
                      )}
                    </div>
                    <div className="request-footer__col request-footer__col--normal request-footer__col--end">
                      {isUpdateRequire ? (
                        <BaseButtonFill
                          type="submit"
                          onClick={saveAndContinue}
                          disabled={disabled}
                          iconRight={isSubmit || isSummary}
                          icon={isSubmit || isSummary ? 'paper-airplane' : null}
                          data-cypress="request-footer-continue-button"
                        >
                          {isSubmit && common('request.footer.submit')}
                          {isSummary && common('request.footer.submit')}
                          {!isSubmit && !isSummary && common('request.footer.saveAndContinue')}
                        </BaseButtonFill>
                      ) : (
                        <BaseButtonFill
                          type="submit"
                          onClick={saveAndContinue}
                          disabled={disabled}
                          iconRight={isSubmit}
                          icon={isSubmit ? 'paper-airplane' : null}
                          data-cypress="request-footer-continue-button"
                        >
                          {isSubmit && common('request.footer.submit')}
                          {isSummary && common('request.footer.continue')}
                          {!isSubmit && !isSummary && common('request.footer.saveAndContinue')}
                        </BaseButtonFill>
                      )}
                    </div>
                  </>
                )}
              </div>
            </BaseBox>
          )}
          {isRequestForm && isRenewal && (
            <BaseBox borderless>
              <div
                className={classnames('request-footer__wrapper', {
                  'request-footer__wrapper--renewal': isRenewal
                })}
              >
                {!isSummary && !isSubmit && !isCodeOfConduct && (
                  <div
                    className={classnames('request-footer__info', {
                      'request-footer__info--secondary': editForm
                    })}
                  >
                    {editForm ? common('request.footer.infoUpdate') : common('request.footer.infoValid')}
                  </div>
                )}
                <div
                  className={classnames('request-footer__container', {
                    'request-footer__container--secondary': isCodeOfConduct
                  })}
                >
                  <div className="request-footer__col request-footer__col--small">
                    {currentStep > 1 && (
                      <BaseButtonOutline
                        onClick={previousStep}
                        data-cypress="request-footer-return-button"
                        className="request-footer__button--back"
                      >
                        {common('request.footer.stepBack')}
                      </BaseButtonOutline>
                    )}
                  </div>
                  <div className="request-footer__col request-footer__col--large">
                    {editForm || isSummary || isSubmit || isCodeOfConduct ? (
                      <>
                        <BaseButtonFill
                          type="submit"
                          onClick={saveAndContinue}
                          disabled={disabled}
                          iconRight={isSummary || isSubmit}
                          icon={isSummary || isSubmit ? 'paper-airplane' : null}
                          data-cypress="request-footer-continue-button"
                        >
                          {isSubmit && common('request.footer.submit')}
                          {isSummary && common('request.footer.continue')}
                          {!isSummary && !isSubmit && common('request.footer.saveAndContinue')}
                        </BaseButtonFill>
                        {!isSummary && !isSubmit && !isCodeOfConduct && (
                          <BaseButtonOutline onClick={cancelEditForm} data-cypress="request-footer-return-button">
                            {common('request.footer.cancelEdit')}
                          </BaseButtonOutline>
                        )}
                      </>
                    ) : (
                      <>
                        <BaseButtonFill
                          type="submit"
                          onClick={nextStep}
                          disabled={disabled}
                          data-cypress="request-footer-continue-button"
                        >
                          {common('request.footer.yes')}
                        </BaseButtonFill>
                        <BaseButtonOutline onClick={goToEditForm} data-cypress="request-footer-return-button">
                          {common('request.footer.edit')}
                        </BaseButtonOutline>
                      </>
                    )}
                  </div>
                </div>
                <div className="request-footer__cancel">
                  <BaseButtonLink onClick={cancelAmendment} data-cypress="request-footer-return-button">
                    {common('request.footer.canelRenewal')}
                  </BaseButtonLink>
                </div>
              </div>
            </BaseBox>
          )}
        </div>
      </div>
    </div>
  );
};

RequestFooter.propTypes = {
  previousStep: PropTypes.func,
  currentStep: PropTypes.number,
  saveAndReturn: PropTypes.func,
  saveAndContinue: PropTypes.func,
  disabled: PropTypes.bool,
  noSteps: PropTypes.bool,
  variant: PropTypes.string,
  cancelAmendment: PropTypes.func,
  isSummary: PropTypes.bool,
  isSubmit: PropTypes.bool,
  nextStep: PropTypes.func,
  editForm: PropTypes.bool,
  goToEditForm: PropTypes.func,
  cancelEditForm: PropTypes.func,
  isRenewal: PropTypes.bool,
  isCodeOfConduct: PropTypes.bool,
  isUpdateRequire: PropTypes.bool
};
