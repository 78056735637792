import { createReducer, initialStateMerger } from 'bxcommon/helpers/redux.helpers';
import { REQUEST_ACTIONS } from 'bxcommon/store/actions/request.actions';
import { PAYMENT_METHODS } from '../../constants/payments.constant';

const requestInitialState = initialStateMerger({
  case_id: '', // string
  last_edited: null, // date
  status: 1,
  additional_recipients: '',
  confirmations: {
    not_been_convicted: false,
    no_criminal_record: false,
    no_under_prohibition: false,
    all_information_correct: false,
    aware_of_obligations: false,
    electronic_delivery_allowed: false
  },
  comment: '',
  approvals: {
    terms_of_use: false,
    rules_of_registration_office: false,
    list_of_fees: false
  },
  payment: {
    method: PAYMENT_METHODS.CREDIT_CARD,
    voucher_code: null,
    status: null,
    paid_at: null,
    express_process: false,
    accepted_additional_fee_agreement: false
  }
});

export default initialState => {
  const request = {
    [REQUEST_ACTIONS.UPDATE_FIELD]: (state, { payload }) => {
      return Object.assign({}, state, payload);
    },
    [REQUEST_ACTIONS.CLEAR]: () => {
      return Object.assign({}, requestInitialState(initialState));
    },
    [REQUEST_ACTIONS.SUBMIT_ENTRY]: (state, payload) => {
      return Object.assign({}, state, { status: payload });
    }
  };

  return createReducer(requestInitialState(initialState), request);
};
