import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { BaseBox, BaseBoxHeading, SummaryInput, SummaryWrapper } from 'bxcommon';
import { searchForLabel } from 'bxcommon/helpers/form.helper'
import { SummaryFilesList } from 'bxcommon/components/SummaryHelpers/SummaryFiles';
import AppConfig from 'bxcommon/providers/AppConfig'

import 'bxcommon/components/SummaryHelpers/SummaryHelpers.scss';


export const GeneralSummary = (props) => {
  const {
    request,
    resources: {
      issue_type,
      stock_exchange,
      type,
      scheme,
      foreign_type
    },
    isEquity
  } = props;
  const { t } = useTranslation();

  const formatDate = (value) => value ? moment(value).format(AppConfig.defaultDateFormat) : value;

  return (
    <SummaryWrapper
      heading={t('request.form.general.heading')}
      buttonText={t('request.summary.editGeneral')}
      {...props}
    >
      {/* Basic information */}
      <BaseBox borderless className="base-summary__section">
        <>
          <BaseBoxHeading className="base-summary__subheading">
            {t('request.form.general.basicInformation.heading')}
          </BaseBoxHeading>
          <ul className="base-summary__content">
            <SummaryInput
              name={t('request.summary.name')}
              data-cypress="summary-name"
            >
              {request.name}
            </SummaryInput>
            <SummaryInput name={t('request.form.general.basicInformation.definitionOfType')}>
              <>
                <span
                  className="base-summary--margin"
                  data-cypress="summary-type"
                >
                  {searchForLabel(type.data, request.type)}
                </span>
                {request.type === 2 &&
                  <span
                    className="base-summary--margin"
                    data-cypress="summary-type"
                  >
                    {searchForLabel(foreign_type.data, request.foreign_type)}
                  </span>
                }
                <span
                  className="base-summary--margin"
                  data-cypress="summary-issue-type"
                >
                  {searchForLabel(issue_type.data, request.issue_type)}
                </span>
                <span
                  className="base-summary--margin"
                  data-cypress="summary-stock-exchange"
                >
                  {isEquity && request.issue_type === 1 &&
                    searchForLabel(stock_exchange.data, request.stock_exchange)
                  }
                </span>
              </>
            </SummaryInput>
            <SummaryInput
              name={t('request.summary.isin')}
              condition={!isEquity && request.issue_type === 3}
              data-cypress="summary-instrument-id"
            >
              {request.instrument_id}
            </SummaryInput>

            <SummaryInput
              name={t('request.form.general.basicInformation.issue_date')}
              condition={Boolean(request.issue_date)}
              data-cypress="summary-issue-date"
            >
              {formatDate(request.issue_date)}
            </SummaryInput>

            <SummaryInput
              name={t('request.summary.maturityDate')}
              condition={!isEquity && Boolean(request.maturity_date)}
              data-cypress="summary-maturity-date"
            >
              {formatDate(request.maturity_date)}
            </SummaryInput>
          </ul>
        </>
      </BaseBox>

      {/* Issuer */}
      <BaseBox borderless className="base-summary__section">
        <>
          <BaseBoxHeading className="base-summary__subheading">
            {t('request.form.general.typeOfIssue.heading')}
          </BaseBoxHeading>
          <ul className="base-summary__content">
            {isEquity &&
              <SummaryInput
                name={t('request.form.general.typeOfIssue.scheme')}
                condition={isEquity}
                data-cypress="summary-scheme"
              >
                {searchForLabel(scheme.data, request.scheme)}
              </SummaryInput>
            }

            <SummaryInput
              name={t('request.form.general.typeOfIssue.issuer_name')}
              data-cypress="summary-issuer-name"
            >
              {request.issuer_name || request.issuers.join(', ')}
            </SummaryInput>

            <SummaryInput
              name={t('request.summary.guarantors')}
              condition={!isEquity}
              data-cypress="summary-guarantors"
            >
              {request.guarantors}
            </SummaryInput>

            { isEquity
              ? <SummaryInput
                  name={t('request.summary.uploadDeclaration')}
                >
                {/* declaration_files */}
                <SummaryFilesList files={request.declaration_files}/>
                </SummaryInput>
              : <SummaryInput
                name={t('request.summary.uploadGuarantor')}
              >
                <SummaryFilesList files={request.declaration_files}/>
              </SummaryInput>
            }
          </ul>
        </>
      </BaseBox>
    </SummaryWrapper>
  )
};

GeneralSummary.propTypes = {
  isEquity: PropTypes.bool,
  request: PropTypes.object,
  resources: PropTypes.object
};
