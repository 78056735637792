import React from 'react';
import * as Yup from 'yup';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { FastField, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { BaseBoxHeading, BaseButtonFill, BaseButtonOutline, BaseRadioGroup } from 'bxcommon';
import { createCheckbox, createInput, createSelect, searchForLabel } from "bxcommon/helpers/form.helper";
import { type_of_employment } from "../RequestForm/CompanyInformation";
import { search } from "../../store/actions/request.actions";

import './SearchFormModal.scss';

const SearchFormModal = (props) => {
  const { t } = useTranslation();
  const searchFormRef = React.createRef();
  const status_types = props.status_types.data;
  const payment_methods = props.payment_methods.data;
  const ombudsman_offices = props.ombudsman_offices.data;
  const countries = props.countries;
  const initialValues = {
    case_id: '',
    status: '',
    first_name: '',
    last_name: '',
    country_of_citizenship: null,
    proof_of_knowledge_professional_expertise: false,
    proof_of_knowledge_knowhow: false,
    type_of_employment: null,
    company_name: '',
    ombudsman_offices: null,
    payment_method: null
  };

  const validationSchema = Yup.object({
    case_id: Yup.string().min(2, t('request.searchForm.validation.caseId')),
    status: Yup.number(),
    first_name: Yup.string().min(3, t('request.searchForm.validation.firstName')),
    last_name: Yup.string().min(3, t('request.searchForm.validation.firstName')),
    country_of_citizenship: Yup.number().nullable(),
    proof_of_knowledge_professional_expertise: Yup.boolean(),
    proof_of_knowledge_knowhow: Yup.boolean(),
    type_of_employment: Yup.number().nullable(),
    company_name: Yup.string().min(3, t('request.searchForm.validation.firstName')),
    ombudsman_offices: Yup.number().nullable(),
    payment_method: Yup.number().nullable()
  });

  const formatFiltersList = (currentFilters) => {
    let formatted = '';
    for (const [key, value] of Object.entries(currentFilters)) {
      switch (key) {
        case 'case_id':
          formatted += `Registration ID: <b>${value}</b>; `
          break;
        case 'status':
          formatted += `Register status: <b>${searchForLabel(status_types, value)}</b>; `
          break;
        case 'first_name':
          formatted += `First name: <b>${value}</b>; `
          break;
        case 'last_name':
          formatted += `Last name: <b>${value}</b>; `
          break;
        case 'country_of_citizenship':
          formatted += `Country of citizenship: <b>${searchForLabel(countries, value)}</b>; `
          break;
        case 'proof_of_knowledge_professional_expertise':
          formatted += `Proof of knowledge professional expertise: <b>${value}</b>; `
          break;
        case 'proof_of_knowledge_knowhow':
          formatted += `Proof of knowledge knowhow: <b>${value}</b>; `
          break;
        case 'type_of_employment':
          formatted += `Type of employment: <b>${searchForLabel(type_of_employment, value)}</b>; `
          break;
        case 'company_name':
          formatted += `Company name: <b>${value}</b>; `
          break;
        case 'ombudsman_offices':
          formatted += `Ombudsman office: <b>${searchForLabel(ombudsman_offices, value)}</b>; `
          break;
        case 'payment_method':
          formatted += `Payment method: <b>${searchForLabel(payment_methods, value)}</b>; `
          break;
        default:
          break;
      }
    }
    props.setCurrentFiltersFormatted(formatted)
  }

  const setInitialValues = () => {
    searchFormRef.current.resetForm();
  }

  const submitForm = () => {
    const form = searchFormRef.current;
    form.submitForm()
  }

  const clearEmptyValues = (obj) => {
    return Object.entries(obj).reduce((result, [key, value]) => (value ? (result[key] = value, result) : result), {})
  }

  const closeSearchModal = () => {
    props.setSearchModalVisible(false)
  }

  const onFormSubmit = async (values) => {
    const queryParams = clearEmptyValues(values);
    if (Object.keys(queryParams).length === 0) {
      props.clearSearchFilters()
      props.setSearchModalVisible(false);
      return
    }
    try {
      const { data } = await props.search(queryParams);
      formatFiltersList(queryParams)
      props.setFilteredList(data.data);
      props.setSearchModalVisible(false);
    } catch (err) {
      console.log('something went wrong')
    }
  }

  return (
    <Modal
      className="search-form"
      onCancel={closeSearchModal}
      visible={props.visible}
      title="Search"
      closable={true}
      footer={(
        <div className="search-modal__footer">
          <BaseButtonOutline className="mr-16" onClick={setInitialValues}>
            Clear
          </BaseButtonOutline>
          <BaseButtonFill onClick={submitForm}>
            Search
          </BaseButtonFill>
        </div>
      )}
      bodyStyle={
        { maxHeight: '60vh', overflow: 'auto' }
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={onFormSubmit}
        ref={searchFormRef}
      >
        {({ values }) => (
          <form>
            <div className="search-modal__content">
              <BaseBoxHeading>{t('request.searchForm.requestInformation')}</BaseBoxHeading>
              <div className="row">
                {createInput('case_id', t('request.searchForm.registrationId'))}
              </div>
              <div className="row" id='search-form__status'>
                {createSelect({
                  name: 'status',
                  label: t('request.searchForm.status'),
                  options: status_types,
                  allowClear: true,
                  getPopupContainer: () => document.getElementById('search-form__status')
                })}
              </div>

              <BaseBoxHeading>{t('request.form.personalInformation.heading')}</BaseBoxHeading>
              <div className="row">
                {createInput('first_name', t('request.form.personalInformation.first_name'))}
              </div>
              <div className="row">
                {createInput('last_name', t('request.form.personalInformation.last_name'))}
              </div>
              <div className="row" id='search-form__countries'>
                {createSelect({
                  name: 'country_of_citizenship',
                  label: t('request.form.personalInformation.country_of_citizenship'),
                  options: countries,
                  allowClear: true,
                  getPopupContainer: () => document.getElementById('search-form__countries')
                })}
              </div>

              <BaseBoxHeading>{t('request.form.proofOfRequiredKnowledge.heading')}</BaseBoxHeading>
              <div className="row">
                {createCheckbox(
                  'proof_of_knowledge_professional_expertise',
                  values.proof_of_knowledge_professional_expertise,
                  null,
                  t('request.form.education.proofOfKnowledgeProfessionalExpertise')
                )}
              </div>
              <div className="row">
                {createCheckbox(
                  'proof_of_knowledge_knowhow',
                  values.proof_of_knowledge_knowhow,
                  null,
                  t('request.form.education.proofOfKnowledgeKnowhow')
                )}
              </div>

              <BaseBoxHeading>{t('request.form.companyInformation.heading')}</BaseBoxHeading>
              <FastField
                component={BaseRadioGroup}
                name='type_of_employment'
                options={type_of_employment}
                value={values.type_of_employment}
              />
              <div className="row">
                {createInput('company_name', t('request.form.companyInformation.company_name'))}
              </div>

              <BaseBoxHeading>{t('request.form.ombudsmanOffice.heading')}</BaseBoxHeading>
              <div className="row" id='search-form__ombudsman'>
                {createSelect({
                  name: 'ombudsman_offices',
                  label: t('request.form.ombudsmanOffice.ombudsman_offices'),
                  options: ombudsman_offices,
                  allowClear: true,
                  getPopupContainer: () => document.getElementById('search-form__ombudsman')
                })}
              </div>

              <BaseBoxHeading>{t('request.summary.headings.payments')}</BaseBoxHeading>
              <FastField
                component={BaseRadioGroup}
                name="payment_method"
                options={payment_methods}
                value={values.payment_method}
              />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  status_types: state.resources.status,
  countries: state.resources.countries,
  payment_methods: state.resources.payment_methods,
  ombudsman_offices: state.resources.ombudsman_options,
});
export default connect(mapStateToProps, { search })(SearchFormModal);
