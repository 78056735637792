import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  createRequest,
  updateRequest,
  deleteRequest,
  submitRequest,
  updateRequestField,
  setSubmittedState,
  setCompletedState,
  clearForm,
  clearAfterSubmit,
  removeFromList,
  successToast,
  warningToast
} from '../store/actions';

import { handleFileAdd, handleDeleteRegistration, handleFileRemove, handleFileReplace } from '../misc/requests';
import { BaseLoader } from '../components';
import errorService from '../helpers/errors.helper';
import { hydrateAdvisorList } from 'registry/src/store';
import AppConfig from '../providers/AppConfig';

let RequestContext;
const { Provider } = (RequestContext = React.createContext({}));

const mapStateToProps = state => ({
  request: state.request,
  meta: state.requestMeta,
  resources: state.resources,
  list: state.list,
  fileUploadTypes: state.resources.file_type
    ? state.resources.file_type.data.reduce((acc, next) => {
        acc[next.name.toUpperCase().replace(/ /g, '_')] = next.id;
        return acc;
      }, {})
    : {}
});

class RequestProvider extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef(); // holds form ref needed in other parts of app
    this.validateFormFnRef = React.createRef(); // holds validation function needed in other parts of app
  }

  state = {
    loading: false
  };

  saveStep = async values => {
    this.handleLoading(true);
    try {
      const payload = values ? { ...values, case_id: this.props.request.case_id } : { ...this.props.request };
      if (payload.date_of_birth) {
        payload.date_of_birth = payload.date_of_birth.format(AppConfig.defaultBackendDateFormat);
      }
      if (this.props.request.case_id) {
        return await this.props.updateRequest(payload, this.props.url);
      } else {
        return await this.props.createRequest(payload, this.props.url);
      }
    } catch (e) {
      errorService.showBackendErrors(e);
    } finally {
      this.handleLoading(false);
    }
    return false;
  };

  handleLoading = bool => {
    this.setState({ loading: bool });
  };

  clearAfterSubmit = async () => {
    await this.props.clearAfterSubmit();
  };

  submitForm = async (isAmendment = false, isRenewal = false, additionalRecipients = '') => {
    this.handleLoading(true);
    try {
      const {
        confirmations,
        comment,
        approvals,
        case_id,
        additional_recipients,
        payment,
        security_type,
        electronic_delivery_allowed
      } = this.props.request;
      let payload;
      if (this.props.url === 'prospectuses') {
        payload = { comment, electronic_delivery_allowed, security_type };
      }
      if (this.props.url === 'client-advisors') {
        payload = { confirmations, comment, approvals, case_id, additional_recipients, payment };
      }
      if (isRenewal) {
        payload = {
          payment: { method: 1, voucher_code: '', status: null }
        };
      }
      if (isAmendment) {
        payload = { payment, additional_recipients: additionalRecipients };
      }
      payload.case_id = case_id;
      if (isRenewal) {
        await this.props.updateRequest(payload, this.props.url);
      }
      if (!isRenewal) {
        await this.props.submitRequest(payload, this.props.url);
      }

      this.handleLoading(false);
      return true;
    } catch (e) {
      if (this.props.url === 'client-advisors' && e.response && e.response.status === 406) {
        this.handleLoading(false);
        return { status: 406 };
      }
      errorService.showBackendErrors(e);
      this.handleLoading(false);
      return false;
    }
  };

  addFile = async (data, config, docType = 'documents') => {
    try {
      const { case_id } = this.props.request;
      return handleFileAdd(`${this.props.url}/${case_id}/${docType}/`, data, config);
    } catch (e) {
      throw new Error();
    }
  };

  replaceFile = async (data, config, replacedFileId) => {
    try {
      const { case_id } = this.props.request;
      return handleFileReplace(`${this.props.url}/${case_id}/documents/${replacedFileId}/`, data, config);
    } catch (e) {
      throw new Error();
    }
  };

  removeFile = async fileId => {
    try {
      await handleFileRemove(this.props.request.case_id, fileId, this.props.url);
    } catch (e) {
      throw new Error();
    }
  };

  cancelAmendment = async caseId => {
    this.handleLoading(true);
    try {
      await this.props.deleteRequest(caseId);
    } catch (e) {
      errorService.showBackendErrors(e);
    } finally {
      this.handleLoading(false);
    }
  };

  hydrateList = async () => {
    this.handleLoading(true);
    try {
      const list = await this.props.hydrateAdvisorList();
      if (list.length) {
        this.props.updateRequestField(list[0]);
      }
    } catch (e) {
      errorService.showBackendErrors(e);
    } finally {
      this.handleLoading(false);
    }
  };

  deleteRegistration = data => {
    try {
      const { case_id } = this.props.request;
      return handleDeleteRegistration(`/client-advisors/${case_id}/`, {
        deletionDate: data.deletion_date.format(AppConfig.defaultBackendDateFormat),
        files: data.files,
        documentType: data.type
      });
    } catch (e) {
      throw new Error();
    }
  };

  render() {
    return (
      <Provider
        value={{
          request: this.props.request,
          metadata: this.props.meta,
          resources: this.props.resources,
          list: this.props.list,
          url: this.props.url,
          hydrateList: this.hydrateList,
          cancelAmendment: this.cancelAmendment,
          deleteRegistration: this.deleteRegistration,
          updateRequestField: this.props.updateRequestField,
          setCompletedState: this.props.setCompletedState,
          setSubmittedState: this.props.setSubmittedState,
          clearForm: this.props.clearForm,
          clearAfterSubmit: this.clearAfterSubmit,
          successToast: this.props.successToast,
          warningToast: this.props.warningToast,
          submitForm: this.submitForm,
          saveStep: this.saveStep,
          addFile: this.addFile,
          replaceFile: this.replaceFile,
          removeFile: this.removeFile,
          setLoading: this.handleLoading,
          removeFromList: this.props.removeFromList,
          formRef: this.formRef,
          validateFormFnRef: this.validateFormFnRef,
          FILE_UPLOAD_TYPES: this.props.fileUploadTypes
        }}
      >
        <BaseLoader loading={this.state.loading} large>
          {this.props.children}
        </BaseLoader>
      </Provider>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    ...bindActionCreators(
      {
        updateRequestField,
        setCompletedState,
        setSubmittedState,
        createRequest,
        updateRequest,
        deleteRequest,
        clearForm,
        clearAfterSubmit,
        submitRequest,
        successToast,
        warningToast,
        hydrateAdvisorList
      },
      dispatch
    ),
    removeFromList: id => dispatch(removeFromList(ownProps.url)(id))
  };
};

const connectedProvider = connect(mapStateToProps, mapDispatchToProps)(RequestProvider);

RequestProvider.propTypes = {
  request: PropTypes.object,
  meta: PropTypes.object,
  resources: PropTypes.object,
  list: PropTypes.array,
  updateRequestField: PropTypes.func,
  setCompleteState: PropTypes.func,
  setSubmittedState: PropTypes.func,
  createRequest: PropTypes.func,
  deleteRegistration: PropTypes.func,
  updateRequest: PropTypes.func,
  submitRequest: PropTypes.func,
  clearForm: PropTypes.func,
  clearAfterSubmit: PropTypes.func,
  successToast: PropTypes.func,
  warningToast: PropTypes.func,
  url: PropTypes.string.isRequired
};

export { connectedProvider as RequestProvider, RequestContext };
