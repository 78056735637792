import React from 'react';
import classnames from "classnames";
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { BaseFormFieldWrapper } from '../BaseFormFieldWrapper/BaseFormFieldWrapper'
import { BaseIcon } from '..';
import './BaseSelect.scss';

const { Option } = Select;

const BaseSelect_ = props => {
  const {field, selectOptions, customKeys, onBlur, handleChange, placeholder, valueType, mode, ...rest} = props;

  return (
    <Select
      className={classnames({'multiple-select': mode === 'multiple'})}
      showSearch
      // https://ant.design/components/select/ - check documentation for option.props.children origin.
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1}
      {...field}
      {...rest}
      onBlur={onBlur}
      onChange={(e) => handleChange(e)}
      placeholder={placeholder}
      suffixIcon={<BaseIcon icon="arrow-down" />}
      mode={mode || 'default'}
      showArrow={true}
    >
      {selectOptions.map(option => (
        <Option
          key={customKeys ? option[customKeys.value] : option.value}
          value={customKeys ? option[customKeys.value] : option.value}
        >
          {customKeys ? option[customKeys.label] : option.label}
        </Option>
      ))}
    </Select>
  );
};

BaseSelect_.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number
  }),
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  customKeys: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  }),
  onBlur: PropTypes.func,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string
};

export const BaseSelect = BaseFormFieldWrapper(BaseSelect_);
