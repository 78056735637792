export const REQUEST_META_ACTIONS = {
  SET_AVAILABLE_STEPS: 'set_available_steps',
  SET_COMPLETED_STATE: 'set_completed_state',
  SET_SUBMITTED_STATE: 'set_submitted_state',
  TEMP_LIST: 'temp_list'
};

const dispatchValue = (action) => (value) => {
  return (dispatch) => {
    dispatch({
      type: action,
      payload: value
    });
  };
};

export const setCompletedState = dispatchValue(REQUEST_META_ACTIONS.SET_COMPLETED_STATE);
export const setSubmittedState = dispatchValue(REQUEST_META_ACTIONS.SET_SUBMITTED_STATE);
