import * as Yup from 'yup';
import i18n from 'bxcommon/i18n';

import {
  isAnyDegreeSelected,
  phoneRegExp,
  requiredArrayWithAtLeastOneElementValidator,
  requiredFilesValidator,
  requiredStringMax500Validator,
  requiredStringValidator
} from 'bxcommon/helpers/form.helper';

const MAX_ADDITIONAL_RECIPIENTS = 20;

export const formValidationSchema = is_admin => {
  const baseCompanySchema = Yup.object({
    type_of_employment: Yup.number(),
    name: requiredStringValidator(),
    address_line_1: requiredStringValidator(),
    postcode: requiredStringValidator(),
    city: requiredStringValidator(),
    country: Yup.string()
      .nullable()
      .required(i18n.t('common:errors.requiredField')),
    ombudsman_offices: requiredArrayWithAtLeastOneElementValidator(),
    job_function: requiredStringValidator(),
    job_position: requiredStringValidator(),
    liability_insurance_files: requiredFilesValidator(),
    confirmation_of_employment_files: Yup.array()
  });

  const schema = Yup.object().shape({
    // documents
    professional_or_academic_degree_files: Yup.array().when('education.degrees_temp', {
      is: value => isAnyDegreeSelected(value) === true,
      then: requiredFilesValidator(),
      otherwise: Yup.array()
    }),
    predefined_certificate_files: Yup.array().when('education.has_predefined_certificates', {
      is: value => value === true,
      then: requiredFilesValidator(),
      otherwise: Yup.array()
    }),
    additional_certificate_files: Yup.array().when('education.has_additional_certificates', {
      is: value => value === true,
      then: requiredFilesValidator(),
      otherwise: Yup.array()
    }),
    employer_confirmation_files: Yup.array().when('education.has_employer_confirmation', {
      is: value => value === true,
      then: requiredFilesValidator(),
      otherwise: Yup.array()
    }),
    finsa_certificate_files: Yup.array().when('education.has_finsa_certificates', {
      is: value => value === true,
      then: requiredFilesValidator(),
      otherwise: Yup.array()
    }),

    // company
    companies: Yup.array().when('education.has_employer_confirmation', {
      is: value => value === true,
      then: Yup.array().of(baseCompanySchema),
      otherwise: Yup.array().of(
        baseCompanySchema.shape({
          confirmation_of_employment_files: Yup.array().when('type_of_employment', {
            is: value => value === 1,
            then: requiredFilesValidator(),
            otherwise: Yup.array()
          })
        })
      )
    }),

    additional_recipients: additionalRecipientsValidationSchema()
  });

  return schema;
};

export const personalValidationSchema = is_admin => {
  const schema = Yup.object().shape({
    // admin fields
    first_name: requiredStringValidator(),
    last_name: requiredStringValidator(),
    email: requiredStringValidator().email(i18n.t('common:errors.email')),
    // personal
    address_line_1: requiredStringValidator(),
    postcode: requiredStringValidator(),
    city: requiredStringValidator(),
    country: Yup.string()
      .nullable()
      .required(i18n.t('common:errors.requiredField')),
    alternative_email: requiredStringValidator()
      .email(i18n.t('common:errors.email'))
      .notOneOf([Yup.ref('email')], i18n.t('common:errors.chooseDifferentEmail')),
    phone_number: requiredStringValidator().matches(phoneRegExp, i18n.t('common:errors.phone')),
    phone_number_dial_code: requiredStringValidator(),
    work_phone_number: requiredStringValidator().matches(phoneRegExp, i18n.t('common:errors.phone')),
    work_phone_number_dial_code: requiredStringValidator(),
    date_of_birth: Yup.string()
      .nullable()
      .required(i18n.t('common:errors.requiredField')),
    country_of_citizenship: Yup.string().required(i18n.t('common:errors.requiredField')),
    additional_country_of_citizenship: Yup.string().nullable(),
    place_of_origin: Yup.string()
      .nullable()
      .when(['country_of_citizenship', 'additional_country_of_citizenship'], {
        is: (a, b) => a === '214' || b === '214',
        then: Yup.string().required(i18n.t('common:errors.requiredField')),
        otherwise: Yup.string()
          .nullable()
          .notRequired()
      }),
    // documents
    passport_files: requiredFilesValidator(),
    cv_files: requiredFilesValidator(),
    criminal_record_extract_files: requiredFilesValidator()
  });

  return schema;
};

export const activityValidationSchema = is_admin => {
  const schema = Yup.object().shape({
    // fields of activity
    fields_of_activity: Yup.array().test(
      'fields_of_activity required',
      i18n.t('common:errors.chooseAtLeastOne'),
      value => value.length
    )
  });

  return schema;
};

export const professionalValidationSchema = is_admin => {
  const schema = Yup.object().shape({
    // education
    education: Yup.object().shape({
      degrees_temp: Yup.object().test(
        'degrees',
        i18n.t('common:errors.chooseAtLeastOneOfTheAboveAndFillTextField'),
        function(value) {
          const { type, details } = value;
          let checker = true;
          for (let [type_id, value] of Object.entries(type)) {
            if (value && (!details[type_id] || details[type_id] === '')) {
              checker = false;
              break;
            }
          }
          return checker
            ? checker
            : this.createError({
                path: 'education.degrees',
                message: i18n.t('common:errors.correspondingTextFieldRequired')
              });
        }
      ),
      proof_of_knowledge: Yup.boolean(), // A
      proof_of_knowledge_professional_expertise: Yup.boolean(), // B
      proof_of_knowledge_knowhow: Yup.boolean(), // C
      proof_of_knowledge_declaration: Yup.boolean(), // D
      AorB: Yup.bool().when(
        ['proof_of_knowledge', 'proof_of_knowledge_professional_expertise', 'proof_of_knowledge_knowhow'],
        {
          is: (a, b, c) => (a ? !b && !c : false),
          then: Yup.bool().required(i18n.t('common:errors.chooseAtLeastOneOfTheAbove')),
          otherwise: Yup.bool()
        }
      ),
      AandBandCandD: Yup.boolean().when(
        [
          'proof_of_knowledge',
          'proof_of_knowledge_professional_expertise',
          'proof_of_knowledge_knowhow',
          'proof_of_knowledge_declaration'
        ],
        {
          is: (a, b, c, d) => a && b && c && d,
          then: Yup.boolean().required(i18n.t('common:errors.pleaseVerifyTransitionalProvisionPursuant')),
          otherwise: Yup.boolean()
        }
      ),
      AandBorCandNotD: Yup.boolean().when(
        [
          'proof_of_knowledge',
          'proof_of_knowledge_professional_expertise',
          'proof_of_knowledge_knowhow',
          'proof_of_knowledge_declaration'
        ],
        {
          is: (a, b, c, d) => !d && a && !(b && c),
          then: Yup.boolean().required(i18n.t('common:errors.pleaseSelectThisIfNotBoth')),
          otherwise: Yup.bool()
        }
      ),
      has_predefined_certificates: Yup.boolean(),
      has_additional_certificates: Yup.boolean(),
      has_employer_confirmation: Yup.boolean(),
      has_predefined_or_additional_or_employer_when_POK_checked: Yup.boolean().when(
        [
          'proof_of_knowledge_professional_expertise',
          'has_predefined_certificates',
          'has_additional_certificates',
          'has_employer_confirmation'
        ],
        {
          is: (
            proof_of_knowledge,
            has_predefined_certificates,
            has_additional_certificates,
            has_employer_confirmation
          ) => {
            if (!proof_of_knowledge) {
              return false;
            }
            return !has_predefined_certificates && !has_additional_certificates && !has_employer_confirmation;
          },
          then: Yup.bool().required(i18n.t('common:errors.chooseAtLeastOneOfTheAbove')),
          otherwise: Yup.bool()
        }
      ),
      has_predefined_or_additional_or_employer: Yup.boolean().when(
        ['has_predefined_certificates', 'has_additional_certificates', 'has_employer_confirmation'],
        {
          is: (has_predefined_certificates, has_additional_certificates, has_employer_confirmation) => {
            return !has_predefined_certificates && !has_additional_certificates && !has_employer_confirmation;
          },
          then: Yup.bool().required(i18n.t('common:errors.chooseAtLeastOneOfTheAbove')),
          otherwise: Yup.bool()
        }
      ),
      predefined_certificates: Yup.array().when('has_predefined_certificates', {
        is: value => value === true,
        then: requiredArrayWithAtLeastOneElementValidator(),
        otherwise: Yup.array()
      }),
      additional_certificates: Yup.string().when('has_additional_certificates', {
        is: value => value === true,
        then: requiredStringMax500Validator(),
        otherwise: Yup.string()
      })
    }),

    // documents
    professional_or_academic_degree_files: Yup.array().when('education.degrees_temp', {
      is: value => isAnyDegreeSelected(value) === true,
      then: requiredFilesValidator(),
      otherwise: Yup.array()
    }),
    predefined_certificate_files: Yup.array().when('education.has_predefined_certificates', {
      is: value => value === true,
      then: requiredFilesValidator(),
      otherwise: Yup.array()
    }),
    additional_certificate_files: Yup.array().when('education.has_additional_certificates', {
      is: value => value === true,
      then: requiredFilesValidator(),
      otherwise: Yup.array()
    }),
    employer_confirmation_files: Yup.array().when('education.has_employer_confirmation', {
      is: value => value === true,
      then: requiredFilesValidator(),
      otherwise: Yup.array()
    })
  });

  return schema;
};

export const codeOfConductValidationSchema = is_admin => {
  const schema = Yup.object().shape({
    // education
    education: Yup.object().shape({
      has_finsa_certificates: Yup.boolean(),
      has_finsa_checked: Yup.boolean().when(['has_finsa_certificates'], {
        is: has_finsa_certificates => {
          return !has_finsa_certificates;
        },
        then: Yup.bool().required(i18n.t('common:errors.checkboxIsRequired')),
        otherwise: Yup.bool()
      }),
      finsa_certificates: Yup.array().when('has_finsa_certificates', {
        is: value => value === true,
        then: requiredArrayWithAtLeastOneElementValidator(),
        otherwise: Yup.array()
      })
    }),

    // documents
    finsa_certificate_files: Yup.array().when('education.has_finsa_certificates', {
      is: value => value === true,
      then: requiredFilesValidator(),
      otherwise: Yup.array()
    })
  });

  return schema;
};

export const companyValidationSchema = is_admin => {
  const baseCompanySchema = Yup.object({
    type_of_employment: Yup.number(),
    name: requiredStringValidator(),
    address_line_1: requiredStringValidator(),
    postcode: requiredStringValidator(),
    city: requiredStringValidator(),
    country: Yup.string()
      .nullable()
      .required(i18n.t('common:errors.requiredField')),
    ombudsman_offices: requiredArrayWithAtLeastOneElementValidator(),
    job_function: requiredStringValidator(),
    job_position: requiredStringValidator(),
    liability_insurance_files: requiredFilesValidator(),
    confirmation_of_employment_files: Yup.array()
  });

  const schema = Yup.object().shape({
    // company
    companies: Yup.array().when('education.has_employer_confirmation', {
      is: value => value === true,
      then: Yup.array().of(baseCompanySchema),
      otherwise: Yup.array().of(
        baseCompanySchema.shape({
          confirmation_of_employment_files: Yup.array().when('type_of_employment', {
            is: value => value === 1,
            then: requiredFilesValidator(),
            otherwise: Yup.array()
          })
        })
      )
    })
  });

  return schema;
};

export const detailsInitialValidationSchema = () => {
  return Yup.object().shape({
    confirmations: initialConfirmationsValidationSchema(),
    additional_recipients: additionalRecipientsValidationSchema(),
    approvals: approvalsValidationSchema(),
    payment: paymentValidationSchema()
  });
};

export const detailsRenewalValidationSchema = () => {
  return Yup.object().shape({
    confirmations: renewalConfirmationsValidationSchema(),
    additional_recipients: additionalRecipientsValidationSchema(),
    approvals: approvalsValidationSchema(),
    payment: paymentValidationSchema(true)
  });
};

export const initialConfirmationsValidationSchema = () =>
  Yup.object({
    not_been_convicted: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    no_criminal_record: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    no_under_prohibition: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    not_registered_in_other_office: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    all_information_correct: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    aware_of_obligations: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField'))
  });

export const renewalConfirmationsValidationSchema = () =>
  Yup.object({
    not_been_convicted: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    no_criminal_record: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    no_under_prohibition: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    all_information_correct: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    aware_of_obligations: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField'))
  });

export const approvalsValidationSchema = () =>
  Yup.object({
    terms_of_use: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    rules_of_registration_office: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField')),
    list_of_fees: Yup.boolean().oneOf([true], i18n.t('common:errors.checkedField'))
  });
export const paymentValidationSchema = isRenewal =>
  Yup.object({
    method: Yup.number().oneOf([1, 2], i18n.t('common:errors.checkedField')),
    voucher_code: Yup.string().when('method', {
      is: value => value === 2,
      then: Yup.string().test('isValidRegistrationCode', i18n.t('common:errors.invalidRegistrationCode'), function(
        value
      ) {
        const re = /[a-zA-Z]{3}[a-z0-9]{7}$/;
        return re.test(
          String(value)
            .toLowerCase()
            .trim()
        );
      }),
      otherwise: Yup.string()
    }),
    accepted_additional_fee_agreement: Yup.boolean().when(isRenewal ? 'express_payment_needed' : 'express_process', {
      is: true,
      then: Yup.boolean().oneOf([true], i18n.t('common:errors.additionalFeeRequired'))
    })
  });

export const additionalRecipientsValidationSchema = (wrapWithObject = false) => {
  const schema = Yup.string().test('isValidListOfEmails', i18n.t('common:errors.invalidInput'), function(value) {
    if (value) {
      const emails = value
        .split(';')
        .map(e => e.split(','))
        .reduce((acc, cur) => cur.concat(acc), [])
        .filter(Boolean);
      try {
        // Below regex is used here to keep the consistency with the backend validation rules.
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        emails.forEach(email => {
          const result = re.test(
            String(email)
              .toLowerCase()
              .trim()
          );
          if (!result) {
            throw Boolean(false);
          }
        });
      } catch (e) {
        return this.createError({ path: 'additional_recipients', message: i18n.t('common:errors.email') });
      }
      if (emails.length > MAX_ADDITIONAL_RECIPIENTS) {
        return this.createError({ path: 'additional_recipients', message: i18n.t('common:errors.toManyEmails') });
      }
    }
    return true;
  });

  return wrapWithObject ? Yup.object({ additional_recipients: schema }) : schema;
};

export const newEmailValidationSchema = () => {
  return Yup.object({
    new_email: requiredStringValidator().email(i18n.t('common:errors.email'))
  });
};
