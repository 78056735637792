import React, { forwardRef, useImperativeHandle, useEffect, useContext, useState, useRef } from 'react';

import { searchForLabel } from '../../helpers/form.helper';
import { RequestContext } from '../../context/Request.context';

import './PaymentButton.scss';

export const PaymentButton = forwardRef((props, ref) => {
  const button = useRef();
  const {
    resources: { countries },
    request
  } = useContext(RequestContext);
  const [url, setUrl] = useState('');

  const isExpress = JSON.parse(sessionStorage.getItem('regservices-form-values-accepted_additional_fee_agreement'));

  const { isRenewal } = props;

  const getTID = () => {
    if (isExpress && isRenewal) {
      return process.env.REACT_APP_PAYREXX_TID_EXPRESS_RENEWAL;
    }
    if (isExpress) {
      return process.env.REACT_APP_PAYREXX_TID_EXPRESS;
    }
    if (isRenewal) {
      return process.env.REACT_APP_PAYREXX_TID_RENEWAL;
    }
    return process.env.REACT_APP_PAYREXX_TID;
  };

  const setupUrl = () => {
    // return url is always used by Payrexx - for credit cards and for e.g. Sofort.
    // Because of this, payment status handling is moved to ThankYou page to checkPaymentStatus method.
    const return_url = `${window.location.protocol}//${window.location.host}/request-form/confirmation/${request.case_id}`;
    const params = {
      RETURN_URL: window.btoa(return_url),
      tid: getTID(),
      referenceId: request.case_id,
      contact_forename: request.first_name,
      contact_surname: request.last_name,
      contact_company: request.companies[0].name,
      contact_street: `${request.address_line_1}${request.address_line_2 ? `;  ${request.address_line_2}` : ''}`,
      contact_place: request.city,
      contact_postcode: request.postcode,
      contact_country: searchForLabel(countries, request.country, { label: 'iso_code', value: 'id' }),
      contact_email: request.email
    };
    const queryString = Object.keys(params)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&');
    setUrl(`${process.env.REACT_APP_PAYREXX_URL}?${queryString}`);
  };
  useEffect(() => {
    setupUrl();
    /*
      Unfortunately jQuery has to be used here. payrexxModal method executed below, is a function which extend jQuery object.
      It's provided by external provider(Payrexx) so there is no way to change it, at least at the moment it was created.
      All alternative solutions are welcome. :)
    */
    if ($ && $.fn && $.fn.payrexxModal) {
      $('.payrexx-trigger-btn').payrexxModal({
        hideObjects: ['#btn-modal-close']
      });
    }
  }, []);

  useEffect(() => {
    setupUrl();
  }, [isExpress]);

  useImperativeHandle(
    ref,
    () => ({
      clickButton() {
        if ($ && $.fn && $.fn.payrexxModal) {
          button.current.click();
          const modal = document.querySelector('#payrexx-modal-iframe');
          modal.src = `${url}&appview=1`;
        } else {
          throw false;
        }
      }
    }),
    [button, url]
  );

  return <button className="payrexx-trigger-btn" data-href={url} ref={button} />;
});
