import React from 'react';
import classnames from 'classnames';
import { FastField, Form } from 'formik';
import config from '../../registry.config';
import { Trans, useTranslation } from 'react-i18next';

import { createInput, createSelect } from 'bxcommon/helpers/form.helper';
import { BaseBox, BaseBoxHeading, BaseButtonFill, BaseHint, BaseRadioGroup } from 'bxcommon';

import './CompanyInformation.scss';

// TODO: move to backend
export const type_of_employment = [
  { id: 1, name: 'Employed by a financial service provider' },
  { id: 2, name: 'Self-employed' }
];

const { maxFileSize } = config;
export const DEFAULT_COMPANY = {
  id: null,
  type_of_employment: 1,
  name: '',
  address_line_1: '',
  address_line_2: '',
  postcode: '',
  city: '',
  country: '',
  ombudsman_offices: [],
  job_function: '',
  job_position: '',
  confirmation_of_employment_files: [],
  liability_insurance_files: []
};

export const CompanyInformation = props => {
  const { t } = useTranslation();
  const {
    values,
    countries,
    ombudsmanOptions,
    renderUploadBox,
    FILE_UPLOAD_TYPES,
    index,
    cleanFieldsAndFiles,
    formikHelpers,
    saveStep,
    borderless,
    isRenewal
  } = props;

  const onAdd = async () => {
    values.companies.push(DEFAULT_COMPANY);
    // HINT: request below is performed for the needs of obtaining ID for a new company object. COMPANY.ID is needed
    // during uploading a files for assigning file to a specific company object.
    const result = await saveStep(values);
    formikHelpers.form.setFieldValue('companies', result.companies);
  };

  const onRemove = async () => {
    await cleanFieldsAndFiles('confirmation_of_employment_files', index);
    await cleanFieldsAndFiles('liability_insurance_files', index);
    formikHelpers.remove(index);
    values.companies.splice(index, 1);
    // HINT: request below is performed for keeping the consistent behavior between adding and removing companies
    const result = await saveStep(values);
    formikHelpers.form.setFieldValue('companies', result.companies);
  };

  const extraFieldsMapper = {
    company_id: (_, request) => values.companies[index].id || request.companies[index].id
  };

  return (
    <BaseBox borderless={borderless} data-cypress="request-form-box-company" lifted className="request-wrapper">
      <BaseBoxHeading className="request-wrapper__main-heading">
        {t('request.form.companyInformation.heading')}
      </BaseBoxHeading>
      <Form className="form-container">
        {values.companies[index].id && (
          <FastField type="hidden" name={`companies.${index}.id`} value={values.companies[index].id} />
        )}
        <div className="col-12">
          <FastField
            component={BaseRadioGroup}
            name={`companies.${index}.type_of_employment`}
            options={type_of_employment}
            value={values.companies[index].type_of_employment}
            data-cypress="type_of_employment"
          />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row request-form--with-hint">
              {createInput(`companies.${index}.name`, t('request.form.companyInformation.company_name'))}
              <BaseHint>{t('request.form.hints.companyInformation')}</BaseHint>
            </div>
            <div className="row">
              {createInput(`companies.${index}.address_line_1`, t('request.form.common.address_line_1'))}
            </div>
            <div className="row">
              {createInput(`companies.${index}.address_line_2`, t('request.form.common.address_line_2'))}
            </div>
            <div className="row">
              <div className="col-4">
                {createInput(`companies.${index}.postcode`, t('request.form.common.postcode'))}
              </div>
              <div className="col-8">{createInput(`companies.${index}.city`, t('request.form.common.city'))}</div>
            </div>
            <div className="row">
              {createSelect({
                name: `companies.${index}.country`,
                label: t('request.form.common.country'),
                options: countries,
                placeholder: t('request.form.personalInformation.countryPlaceholder'),
                dropdownClassName: 'company-country'
              })}
            </div>
          </div>
        </div>
      </Form>

      <BaseBoxHeading>{t('request.form.jobDescription.heading')}</BaseBoxHeading>
      <Form className="form-container">
        <div className="col-12">
          <div className="row request-form--with-hint">
            {createInput(`companies.${index}.job_function`, t('request.form.jobDescription.job_function'))}
            <BaseHint>{t('request.form.hints.jobFunction')}</BaseHint>
          </div>
          <div className="row request-form--with-hint">
            {createInput(`companies.${index}.job_position`, t('request.form.jobDescription.job_position'))}
            <BaseHint>{t('request.form.hints.jobPosition')}</BaseHint>
          </div>
        </div>
      </Form>

      <BaseBoxHeading>{t('request.form.ombudsmanOffice.heading')}</BaseBoxHeading>
      <Form className="form-container">
        <div className="col-12">
          <div className="row request-form--with-hint">
            {createSelect({
              name: `companies.${index}.ombudsman_offices`,
              label: t('request.form.ombudsmanOffice.ombudsman_offices'),
              options: ombudsmanOptions,
              placeholder: t('request.form.common.selectPlaceholder'),
              multiple: true,
              dropdownClassName: 'company-ombudsman-offices'
            })}
            <BaseHint>
              <Trans i18nKey="request.form.hints.ombudsmanList">
                <a
                  href="https://www.regservices.ch"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link--underlined"
                >
                  www.regservices.ch
                </a>
              </Trans>
            </BaseHint>
          </div>
        </div>
      </Form>

      <BaseBoxHeading>{t('request.form.uploadDocuments.heading')}</BaseBoxHeading>
      <Form className="form-container">
        <div className="col-12">
          <div className="row request-form--with-hint flex-column">
            {renderUploadBox({
              name: `companies.${index}.confirmation_of_employment_files`,
              title: t('request.form.uploadDocuments.confirmationOfEmployment'),
              conditions: t('request.form.uploadDocuments.fileDefaults'),
              type: FILE_UPLOAD_TYPES.CONFIRMATION_OF_EMPLOYMENT_FILE,
              maxFileSize,
              isRenewal: isRenewal,
              config: {
                extraFieldsMapper,
                pdfOnly: false
              }
            })}
            <BaseHint>
              <Trans i18nKey="request.form.hints.confirmationOfEmployment">
                <a
                  href="https://www.regservices.ch/?download=7462"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link--underlined"
                >
                  Employer Confirmation
                </a>
              </Trans>
            </BaseHint>
          </div>
          <div className="row request-form--with-hint">
            {renderUploadBox({
              name: `companies.${index}.liability_insurance_files`,
              title: t('request.form.uploadDocuments.liabilityInsuranceFiles'),
              conditions: t('request.form.uploadDocuments.fileDefaults'),
              type: FILE_UPLOAD_TYPES.LIABILITY_INSURANCE_FILE,
              maxFileSize,
              isRenewal: isRenewal,
              config: {
                extraFieldsMapper,
                pdfOnly: false
              }
            })}
            <BaseHint>
              {t('request.form.hints.liabilityInsurance')}
              <ul>
                <li>{t('request.form.hints.liabilityInsurance1')}</li>
                <li>{t('request.form.hints.liabilityInsurance2')}</li>
                <li>{t('request.form.hints.liabilityInsurance3')}</li>
              </ul>
            </BaseHint>
          </div>
        </div>
      </Form>

      <div className="company-information__footer-buttons">
        <BaseButtonFill
          className={classnames({ 'd-none': values.companies.length === 1 && index === 0 })}
          type="button"
          iconLeft
          icon="delete"
          data-cypress="request-form-additional-provider"
          onClick={() => onRemove()}
        >
          {t('request.form.jobDescription.removeJobDescription')}
        </BaseButtonFill>

        <BaseButtonFill
          className={classnames({ 'd-none': values.companies.length - 1 !== index })}
          type="button"
          iconLeft
          icon="add"
          data-cypress="request-form-additional-provider"
          onClick={() => onAdd()}
        >
          {t('request.form.jobDescription.addJobDescription')}
        </BaseButtonFill>
      </div>
    </BaseBox>
  );
};
