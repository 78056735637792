import React, { useReducer, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { BaseIcon, BaseTable, MODAL_RESPONSE_TYPE } from 'bxcommon';
import reducer, {initialState, REDUCER_TYPES} from 'bxcommon/components/ListViewWrapper/ListViewWrapper.reducer';
import { ModalContext } from 'bxcommon/context/Modal.context';

import { ProspectusHeader } from '../ProspectusHeader/ProspectusHeader';
import { hydrateUserGroups, removeFromUserGroups, clearUserGroups } from '../../store/actions';

import './UserGroupsList.scss'


const UserGroupsList = ({removeFromList, userGroups, history, clearList}) => {
  useEffect(() => {
    return () => {
      // check if user goes to group add or edit (then we don't want to send another request for list)
      if (!history.location.pathname.includes('/groups/edit')) {
        clearList();
      }
    }
  },[clearList, history.location.pathname]);

  const { t } = useTranslation();

  const { openConfirmModal } = useContext(ModalContext);

  const [ {listLoading, deleteLoading, activeItemIndex }, updateState ] = useReducer(reducer, initialState);

  const goToEdit = useCallback((id) => {
    history.push(`/groups/edit/${id}`)
  }, [history]);

  const showSpinner = useCallback((index) => {
    return deleteLoading && index === activeItemIndex;
  }, [deleteLoading, activeItemIndex]);

  const deleteRequest = async(id, index) => {
    const response = await openConfirmModal(
      t('userGroups.cancelModal.title'),
      t('userGroups.cancelModal.description'),
      { okButton: t('userGroups.cancelModal.okButton')}
    );
    if (response === MODAL_RESPONSE_TYPE.ACCEPT) {
      try {
        updateState({ type: REDUCER_TYPES.SET_ACTIVE_ITEM_INDEX, payload: index });
        updateState({ type: REDUCER_TYPES.SET_DELETE_LOADING, payload: true });

        await removeFromList(id);
      } finally {
        updateState({ type: REDUCER_TYPES.SET_ACTIVE_ITEM_INDEX, payload: null });
        updateState({ type: REDUCER_TYPES.SET_DELETE_LOADING, payload: false });
      }
    }
  };

  return (
    <ProspectusHeader>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="user-groups">
              <h1
                className="heading heading--secondary"
                data-cypress="user-groups-view-heading"
              >
                {t('userGroups.heading')}
              </h1>
            </div>
            {Boolean(userGroups.length) ? (
              <BaseTable
                labels={t('userGroups.labels', {returnObjects: true})}
                loading={listLoading}
              >
                {userGroups
                  .map((item, index) => (
                    <tr
                      key={item.id}
                      data-cypress="user-groups-list-item"
                    >
                      <td
                        className="copy copy--color-dark user-groups__name"
                        data-cypress="user-groups-group-name"
                      >
                        {item.name}
                      </td>
                      <td
                        className="copy copy--color-dark"
                        data-cypress="user-groups-no-of-members"
                      >
                        {item.members.length}
                      </td>
                      <td>
                        <div className="user-groups__controls">
                          <BaseIcon
                            clickable
                            onClick={() => deleteRequest(item.id, index)}
                            icon="delete"
                            data-cypress="user-groups-delete-button"
                            loading={showSpinner(index)}
                          />
                          <BaseIcon
                            clickable
                            onClick={() => goToEdit(item.id)}
                            icon="edit"
                            data-cypress="user-groups-edit-button"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </BaseTable>
            ) : (
              <div
                className="user-groups__empty"
                data-cypress="user-groups-empty"
              >
                {t('userGroups.empty')}
              </div>
            )}
          </div>
        </div>
      </div>
    </ProspectusHeader>
  );
};

UserGroupsList.propTypes = {
  hydrateList: PropTypes.func,
  removeFromList: PropTypes.func
};

const mapStateToProps = (state) => ({
  userGroups: state.userGroups
});

export default connect(
  mapStateToProps,
  {
    hydrateList: hydrateUserGroups,
    removeFromList: removeFromUserGroups,
    clearList: clearUserGroups
  }
)(UserGroupsList);
