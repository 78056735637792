import { createReducer, initialStateMerger } from 'bxcommon/helpers/redux.helpers';
import { REQUEST_META_ACTIONS } from 'bxcommon/store/actions/requestMeta.actions';

const metaInitialState = initialStateMerger({
  requestCompleted: false,
  requestSubmitted: false
});

export default (initialState) => {

  const requestMeta = {
    [REQUEST_META_ACTIONS.SET_COMPLETED_STATE]: (state, {payload}) => {
      return Object.assign({}, state, {requestCompleted: payload});
    },
    [REQUEST_META_ACTIONS.SET_SUBMITTED_STATE]: (state, {payload}) => {
      return Object.assign({}, state, {requestSubmitted: payload});
    }
  };

  return createReducer(metaInitialState(initialState), requestMeta);
}
