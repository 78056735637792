import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { FastField } from 'formik';
import * as Yup from 'yup';
import { history } from 'bxcommon/BxBrowserRouter';

import { BaseButtonFill, BaseInput } from '..';
import LoginMessages from 'bxcommon/components/LoginMessages/LoginMessages';
import UserCommonView from '../UserCommonView/UserCommonView';

import { createInput, requiredStringValidator } from '../../helpers/form.helper';

import './BaseLogin.scss';

const BaseLogin = ({ login, warning, heading, subheading, isAdvisor, isAdmin = false }) => {
  const [loginLoading, setLoginLoading] = useState(false);
  const { t: common } = useTranslation('common');

  const initialFormValues = {
    email: '',
    password: ''
  };

  const validationSchema = Yup.object().shape({
    email: requiredStringValidator().email(common('errors.email')),
    password: requiredStringValidator()
  });

  const onSubmit = async (values) => {
    setLoginLoading(true);

    try {
      // Unauthenticated route will redirect us to list automatically.
      // returnUrl needed for transferring user back to document if id is provided.
      const returnUrl = history.location.search ? history.location.search.replace('?', '/') : '';
      await login(values);

      if (returnUrl) {
        history.push(returnUrl);
      }
    } catch (error) {
      warning(error);
      setLoginLoading(false);
    }
  };

  return (
    <UserCommonView
      isAdvisor={isAdvisor}
      heading={heading}
      subheading={subheading}
      initialFormValues={initialFormValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      leftContent={isAdmin ? null : <LoginMessages />}
    >
      <div className="row">
        {createInput('email', common('login.email'))}
      </div>
      <div className="row user-login-password">
        <FastField
          component={BaseInput}
          name="password"
          type="password"
          label={common('login.password')}
          data-cypress="password"
        />
      </div>
      <div className="row user-common-view__forgot-password-link">
        <Link
          to="/forgot-password"
          data-cypress="forgot-password-link"
        >
          {common('forgotPassword.heading')}
        </Link>
      </div>
      <div className="row">
        <BaseButtonFill
          type="submit"
          full
          large
          loading={loginLoading}
          iconRight
          icon="arrow-right"
          data-cypress="login-button"
        >
          { common('login.button') }
        </BaseButtonFill>
      </div>
    </UserCommonView>
  );
};

BaseLogin.propTypes = {
  login: PropTypes.func.isRequired,
  warning: PropTypes.func.isRequired,
  heading: PropTypes.string,
  isAdvisor: PropTypes.bool,
  isAdmin: PropTypes.bool,
  subheading: PropTypes.string
};

export default BaseLogin;
