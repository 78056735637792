import { errorToast, warningToast } from '../store/actions';
import i18n from '../i18n';

export class ErrorService {
  static ERRORS = {
    SESSION_EXPIRED: 'token_not_valid',
    NOT_AUTHENTICATED: 'not_authenticated',
    UNIQUE: 'unique',
    INVALID: 'invalid',
    NO_ACTIVE_ACCOUNT: 'no_active_account',
    USER_NOT_ACTIVATED: 'user_not_activated',
    USER_ALREADY_ACTIVATED: 'user_already_activated',
    UNDECODABLE_VALUE: 'undecodable_value',
    INVALID_EMAIL_CONFIRMATION_TOKEN: 'invalid_email_confirmation_token',
    INVALID_PASSWORD_RESET_TOKEN: 'invalid_password_reset_token',
    DOES_NOT_EXIST: 'does_not_exist'
  };

  init(store) {
    this.store = store;
  }

  handleErrorToast(error, errorsMap) {
    let code = error.response.data.code;

    if (!code) {
      console.error('Error toast handler; code not found');
      return;
    }

    // TODO: this is a hack due to misunderstanding with backend that top code should be feature specific
    if (code === ErrorService.ERRORS.INVALID && error.response.data.errors[0]) {
      code = error.response.data.errors[0].code;
    }

    if (!errorsMap[code]) {
      this.showBackendErrors(error);
      return;
    }

    const errorTranslation = errorsMap[code];

    if (errorTranslation) {
      const toast = errorTranslation.type === 'warning' ? warningToast : errorToast;

      this.store.dispatch(toast(
        i18n.t(errorTranslation.content),
        i18n.t(errorTranslation.title)
      ));
    }
  }

  showBackendErrors(error, warn=false) {
    if (!error.response.data || !error.response.data.code) {
      console.error(error);
      errorToast('error');
      return;
    }

    // Don't show errors when user is not authenticated
    if (error.response.data.code === ErrorService.ERRORS.SESSION_EXPIRED) {
      return;
    }

    if (error.response.data.errors.length) {
      error.response.data.errors.forEach(e => this.store.dispatch(warn
        ? warningToast(e.message, error.response.data.message)
        : errorToast(e.message, error.response.data.message)
      ));
    } else if (error.response.data.message) {
      this.store.dispatch(errorToast(error.response.data.message, i18n.t('common:errors.error')));
    }
  }

  displayDefault(error) {
    if (!this.store.getState().toasts.length) {
      this.showBackendErrors(error)
    }
  }
}

export const AUTHENTICATION_ERRORS = {
  [ErrorService.ERRORS.SESSION_EXPIRED]: {
    type: 'warning',
    title: 'common:errors.sessionExpiredTitle',
    content: 'common:errors.sessionExpired'
  },
  [ErrorService.ERRORS.NOT_AUTHENTICATED]: {
    type: 'warning',
    title: 'common:errors.sessionExpiredOrNoSessionTitle',
    content: 'common:errors.sessionExpiredOrNoSession'
  }
};

export const LOGIN_ERRORS = {
  [ErrorService.ERRORS.NO_ACTIVE_ACCOUNT]: {
    type: 'warning',
    title: 'common:login.unableErrorTitle',
    content: 'common:login.unableErrorContent'
  },
  [ErrorService.ERRORS.INVALID]: {
    type: 'warning',
    title: 'common:login.unableErrorTitle',
    content: 'common:login.wrongUser'
  },
  [ErrorService.ERRORS.USER_NOT_ACTIVATED]: {
    type: 'warning',
    title: 'common:login.userNotActivatedTitle',
    content: 'common:login.userNotActivatedContent'
  }
};

export const REGISTRATION_ERRORS = {
  [ErrorService.ERRORS.UNIQUE]: {
    type: 'warning',
    title: 'common:register.errors.registerErrorUniqueTitle',
    content: 'common:register.errors.registerErrorUniqueContent'
  }
};


export default new ErrorService();
