import axios from 'axios';

import { REQUEST_ACTIONS } from 'bxcommon/store/actions';


export const togglePublish = (bool, caseId) => {
  return (dispatch) => {
    return axios.post(`/client-advisors/${caseId}/${bool ? 'publish' : 'unpublish'}/`)
      .then(() => {
        dispatch({
          type: REQUEST_ACTIONS.UPDATE_FIELD,
          payload: {is_published: bool}
        });
      });
  };
};

export const updateAdditionalRecipientsWhenClientAdvisorApproved = (payload) => {
  return (dispatch) => {
    return axios.post(`/client-advisors/additional-recipients/`, payload)
      .then(() => {
        dispatch({
          type: REQUEST_ACTIONS.UPDATE_FIELD,
          payload: {
            additional_recipients: payload.new_additional_recipients
          }
        });
      });
  };
};

export const search = (params) => () => {
  return axios({
    method: 'get',
    url: `/client-advisors`,
    params,
  })
}

export const getCsv = (payload) => () => {
  return axios.post('/client-advisors/csv-list/', payload)
}
