import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import classnames from 'classnames';

import { BaseFormFieldWrapper } from '../BaseFormFieldWrapper/BaseFormFieldWrapper';

const RadioGroup = Radio.Group;

const BaseRadioGroup_ = props => {
  const { field, radioAsBox, onBlur, handleChange, customKeys, options, ...rest } = props;

  const mappedOptions = (options, keys = { label: 'name', value: 'id' }) => {
    return options.map(o => ({
      label: o[keys.label],
      value: o[keys.value]
    }));
  };

  return (
    <div data-cypress={rest['data-cypress']}>
      <RadioGroup
        className={classnames('base-radio', `base-radio--${props.name}`, { 'base-radio-group--visual': radioAsBox })}
        {...field}
        {...rest}
        onBlur={onBlur}
        options={customKeys ? mappedOptions(options, customKeys) : mappedOptions(options)}
        onChange={e => handleChange(e.target.value)}
      />
    </div>
  );
};

BaseRadioGroup_.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any
  }),
  radioAsBox: PropTypes.bool,
  onBlur: PropTypes.func,
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  customKeys: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any
  })
};

export const BaseRadioGroup = BaseFormFieldWrapper(BaseRadioGroup_);
