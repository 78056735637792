import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames'

import { BaseLoaderSpinner } from '..';

import './BaseLoader.scss';

export const BaseLoader = ({loading, children, className, isComponent, large}) => {
  return (
    <div
     className={classnames('base-loader-wrapper', className, {'base-loader-wrapper--visible': loading})}
    >
      <div
        className={classnames('base-loader-blur', className, {'base-loader-blur--visible': loading})}
      >
        {children}
      </div>
      {loading && <BaseLoaderSpinner isComponent={isComponent} large={large} />}
    </div>
  )
};

BaseLoader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  large: PropTypes.bool,
  isComponent: PropTypes.bool
};
