import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { history } from 'bxcommon/BxBrowserRouter';

import { BaseButtonLink } from '../BaseButtonLink/BaseButtonLink';
import { RequestContext } from '../../context/Request.context';
import { MODAL_RESPONSE_TYPE } from '..';
import { ModalContext } from '../../context/Modal.context';
import './RequestHeader.scss';
import { STATUS } from '../../constants/status.constant';

export const RequestHeader = ({header, onCancel, stepsInstance}) => {
  const { t: common } = useTranslation('common');

  const {
    metadata: { requestSubmitted },
    saveStep,
    formRef,
    clearForm,
    validateFormFnRef,
    request,
    removeFromList
  } = useContext(RequestContext);
  const { openConfirmModal } = useContext(ModalContext);

  const back = () => {
    stepsInstance.firstStep();
    clearForm();
    history.push('/list');
  };

  const backFn = async () => {
    const response = await openConfirmModal(
      common('request.backModal.title'),
      common('request.backModal.description'),
      {
        okButton: common('request.backModal.okButton'),
        cancelButton: common('request.backModal.cancelButton')
      });

    const formActions = formRef.current ? formRef.current.getFormikActions() : null;
    if (response === MODAL_RESPONSE_TYPE.ACCEPT && formActions) {
      validateFormFnRef.current(async () => {
        await formActions.submitForm();
        await saveStep();
        back();
      }, false)
    } else if (response === MODAL_RESPONSE_TYPE.ACCEPT) {
      await saveStep();
      back();
    } else if (response === MODAL_RESPONSE_TYPE.REJECT) {
      back();
    }
  };

  const cancelFn = async () => {
    const response = await openConfirmModal(
      common('request.cancelModal.title'),
      common('request.cancelModal.description'),
      { okButton: common('request.cancelModal.okButton')}
    );

    if (response === MODAL_RESPONSE_TYPE.REJECT || response === MODAL_RESPONSE_TYPE.CLOSE) {
      return;
    }

    if (request.case_id) {
      await removeFromList(request.case_id);
    }
    back();
  };

  return (
    <div className="request-header row">
      {!requestSubmitted && (
        <div className="col-4">
          <BaseButtonLink
            className="request-header__back"
            onClick={backFn}
            iconLeft
            iconGray
            icon="arrow-left"
            data-cypress="request-header-back"
          >
            {common('request.header.back')}
          </BaseButtonLink>
        </div>
      )}
      <div className="request-header__title col-4">
        <div>
          {header}
        </div>
      </div>
      {!requestSubmitted && (
        <div className="col-4 request-header__cancel">
          <BaseButtonLink
            className="request-header__cancel"
            onClick={cancelFn}
            hidden={request.status !== STATUS.DRAFT}
            icon="times"
            iconLeft
            iconGray
            data-cypress="request-header-cancel"
          >
            {onCancel.label}
          </BaseButtonLink>
        </div>
      )}
    </div>
  )
};

RequestHeader.propTypes = {
  header: PropTypes.string.isRequired,
  onCancel: PropTypes.shape({
    label: PropTypes.string.isRequired,
    cancelFn: PropTypes.func
  }).isRequired,
  stepsInstance: PropTypes.object.isRequired
};
