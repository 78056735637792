import React, {useContext, useState} from 'react';
import {FastField, Form, Formik} from 'formik'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import moment from 'moment';

import { RequestContext } from 'bxcommon/context/Request.context';
import { RequestStepWrapper } from 'bxcommon/components/RequestStepWrapper/RequestStepWrapper';
import { FormWrapper } from "bxcommon/components/FormWrapper/FormWrapper";

import AppConfig from 'bxcommon/providers/AppConfig';

import {
  createCleanFields,
  createInput,
  useValidation,
  requiredFilesValidator,
  createUploadBox,
  createCheckbox
} from 'bxcommon/helpers/form.helper';
import {
  BaseBox,
  BaseBoxHeading,
  BaseDatePicker,
  BaseHint
} from 'bxcommon';

import config from '../../prospectus.config';

import './FormProspectus.scss';

const { maxFileSize } = config;
export const FormProspectus = (props) => {
  const {request, updateRequestField, formRef, FILE_UPLOAD_TYPES} = useContext(RequestContext);
  const {t} = useTranslation();
  const {t: common} = useTranslation('common');

  const [footerDisabled, disableFooter] = useState(false);
  const renderUploadBox = createUploadBox(disableFooter);

  const [validationSchema, submitWithValidation] = useValidation(
    Yup.object().shape({
      publish_on: Yup.object()
        .nullable()
        .when('publish_here', {
          is: true,
          then: Yup.object().required(common('errors.requiredField')),
          otherwise: Yup.object().notRequired()
        }),
      already_published_location: Yup.string()
        .when('is_already_published', {
          is: true,
          then: Yup.string().required(common('errors.requiredField')),
          otherwise: Yup.string().notRequired()
        }),
      already_published_date: Yup.object()
        .nullable()
        .when('is_already_published', {
          is: true,
          then: Yup.object().required(common('errors.requiredField')),
          otherwise: Yup.object().notRequired()
        }),
      prospectus_files: requiredFilesValidator()
    }, [
      ['is_already_published', 'publish_here']
    ])
  );

  const initialFormValues = {
    publish_here: request.publish_here || false,
    publish_on: request.publish_on ? moment(request.publish_on) : null,
    is_already_published: Boolean(request.already_published_location || request.already_published_date),
    paper_version: request.paper_version || '',
    already_published_location: request.already_published_location || '',
    already_published_date: request.already_published_date ? moment(request.already_published_date) : null,
    prospectus_files: request.prospectus_files || [],
    incorporated_files: request.incorporated_files || [],
    additional_files: request.additional_files || []
  };

  const cleanFields = createCleanFields({
    publish_here: [{key: 'publish_on', value: null}],
    is_already_published: [
      {key: 'already_published_location', value: ''},
      {key: 'already_published_date', value: null}
    ]
  });

  const createDatePicker = (name, label, disabled = false, disabledDate = null) => (
    <FastField
      component={BaseDatePicker}
      name={name}
      label={label}
      disabled={disabled}
      disabledDate={disabledDate}
      format={AppConfig.defaultDateFormat}
      data-cypress={name}
    />
  );

  const onFormSubmit = () => {
    const values = formRef.current.state.values;
    values.security_type = request.security_type;
    return updateRequestField({...values});
  };

  return (
    <FormWrapper>
      <RequestStepWrapper
        formRef={formRef}
        validationSubmit={submitWithValidation}
        disabled={footerDisabled}
        {...props}
      >
        <Formik
          ref={formRef}
          initialValues={initialFormValues}
          onSubmit={() => onFormSubmit()}
          validationSchema={validationSchema}
        >
          {({values, setFieldValue}) => (
            <>
              <BaseBox
                className="request-form__box"
                lifted
              >
                <BaseBoxHeading>{t('request.form.prospectus.basicInformation.place_of_publication')}</BaseBoxHeading>
                <Form className="form-container">
                  <div className="request-form--with-close-hint">
                    {createCheckbox(
                      'publish_here',
                      values.publish_here,
                      (value, fieldName) => cleanFields(value, fieldName, setFieldValue),
                      t('request.form.prospectus.basicInformation.publish_here')
                    )}
                    <div className="row">
                      <div className="col-5 request__form-input-offset">
                        {createDatePicker(
                          'publish_on',
                          t('request.form.prospectus.basicInformation.publish_on'),
                          !values.publish_here,
                          (v) => v < moment().subtract(1, 'day')
                        )}
                      </div>
                    </div>
                    <BaseHint>
                      {t('hints.publishHere')}
                    </BaseHint>
                  </div>
                  <div className="request-form--with-close-hint">
                    <div className="divider divider--dashed"/>
                    {createCheckbox(
                      'is_already_published',
                      values.is_already_published,
                      (value, fieldName) => cleanFields(value, fieldName, setFieldValue),
                      t('request.form.prospectus.basicInformation.is_already_published')
                    )}
                    <div className="request__form-input-offset">
                      {createInput('already_published_location', null, !values.is_already_published)}
                    </div>
                    <div className="row">
                      <div className="col-5 request__form-input-offset">
                        {createDatePicker(
                          'already_published_date',
                          t('request.form.prospectus.basicInformation.already_published_date'),
                          !values.is_already_published
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {createInput('paper_version', t('request.form.prospectus.basicInformation.paper_version'))}
                  </div>
                </Form>
              </BaseBox>
              <BaseBox
                className="request-form__box"
                lifted
              >
                <BaseBoxHeading>{t('request.form.prospectus.typeOfProspectus.heading')}</BaseBoxHeading>
                <Form className="form-container">
                  {renderUploadBox({
                    name: 'prospectus_files',
                    title: t('request.form.prospectus.typeOfProspectus.uploadProspectus'),
                    conditions: common('request.form.common.fileDefaultsPDF'),
                    type: FILE_UPLOAD_TYPES.PROSPECTUS_FILE,
                    maxFileSize,
                    config: {
                      multiple: true
                    }
                  })}

                  <div className="request-form--with-close-hint">
                    {renderUploadBox({
                      name: 'incorporated_files',
                      title: t('request.form.prospectus.typeOfProspectus.uploadIncorporated'),
                      conditions: common('request.form.common.fileDefaultsPDF'),
                      type: FILE_UPLOAD_TYPES.INCORPORATED_FILE,
                      maxFileSize,
                      config: {
                        multiple: true
                      }
                    })}
                    <BaseHint>
                      {t('request.form.prospectus.typeOfProspectus.uploadIncorporatedHint')}
                    </BaseHint>
                  </div>

                  {renderUploadBox({
                    name: 'additional_files',
                    title: `
                      ${t('request.form.prospectus.typeOfProspectus.uploadAdditionalFiles')}
                      ${common('request.form.common.optional')}
                    `,
                    conditions: common('request.form.common.fileDefaultsWordPngJpg'),
                    type: FILE_UPLOAD_TYPES.ADDITIONAL_FILE,
                    maxFileSize,
                    config: {
                      multiple: true,
                      pdfOnly: false
                    },
                    otherProps: {'basic': true}
                  })}
                </Form>
              </BaseBox>
            </>
          )}
        </Formik>
      </RequestStepWrapper>
    </FormWrapper>
  );
};

FormProspectus.propTypes = {
  nextStep: PropTypes.func,
  lastStep: PropTypes.func
};
