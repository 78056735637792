import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { FastField, Formik } from 'formik';

import { BaseBox, BaseBoxHeading, BaseTextArea, RequestStepWrapper } from 'bxcommon';
import { GeneralSummary } from './GeneralSummary';
import { ProspectusSummary } from '../Summary/ProspectusSummary';
import { InvoicingSummary } from '../Summary/InvoicingSummary';
import { BaseHint } from 'bxcommon';
import { RequestContext } from 'bxcommon/context/Request.context';
import { ModalContext } from 'bxcommon/context/Modal.context';
import { updateElectronicDelivery } from 'bxcommon/helpers/common.helpers';

import 'bxcommon/components/SummaryHelpers/SummaryHelpers.scss';
import { createCheckbox, useValidation } from 'bxcommon/helpers/form.helper';
import { detailsInitialValidationSchema } from 'registry/src/components/RequestForm/RequestFormValidation';
import { FormWrapper } from 'bxcommon/components/FormWrapper/FormWrapper';

export const RequestFormSummary = props => {
  const { request, resources, updateRequestField, setCompletedState, formRef } = useContext(RequestContext);
  const { openConfirmModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const electronicDeliveryModal = e => {
    let result = updateElectronicDelivery(openConfirmModal, updateRequestField, t, { target: { checked: !e } });
    if (result === false) {
      formRef.current.setFieldValue('electronic_delivery_allowed', false, false);
    }
  };

  const contentText = (
    <>
      <p className="copy copy--small copy--bold">{t('nextSteps.message')}</p>
      <p className="copy copy--small">{t('nextSteps.review')}</p>
      <p className="copy copy--small">{t('nextSteps.processDays')}</p>
      <p className="copy copy--small">
        <Trans i18nKey="messages.furtherInformation" ns="common">
          <a
            href="https://www.regservices.ch/?download=6318"
            target="_blank"
            rel="noopener noreferrer"
            className="link--underlined"
          >
            Rules of Procedure.
          </a>
        </Trans>
      </p>
    </>
  );

  const initialValues = {
    comment: request.comment || '',
    electronic_delivery_allowed: Boolean(request.electronic_delivery_allowed) || true
  };
  const [validationSchema, submitWithValidation] = useValidation(detailsInitialValidationSchema());

  useEffect(() => {
    setCompletedState(true);
  }, [setCompletedState]);

  return (
    <FormWrapper>
      <RequestStepWrapper
        isFinal
        content={contentText}
        formRef={formRef}
        validationSubmit={submitWithValidation}
        {...props}
      >
        <div className="base-summary__section-wrapper">
          <GeneralSummary isEquity={props.isEquity} {...props} request={request} resources={resources} step={1} />
          <ProspectusSummary {...props} request={request} resources={resources} step={2} />
          <InvoicingSummary {...props} request={request} resources={resources} step={3} />
          <BaseBox className="base-summary__comment" data-cypress="summary-comment">
            <BaseBoxHeading>
              {common('request.summary.commentTitle')} {common('request.form.common.optional')}
            </BaseBoxHeading>
            <Formik
              initialValues={initialValues}
              ref={formRef}
              onSubmit={values => updateRequestField({ ...values })}
              validationSchema={validationSchema}
            >
              {({ values }) => (
                <>
                  <div>
                    <div>{common('request.summary.commentBox')}</div>
                    <FastField
                      component={BaseTextArea}
                      name="comment"
                      value={values.comment}
                      data-cypress="additional_certificates"
                    />
                  </div>
                  <div className="request-form--with-hint">
                    {createCheckbox(
                      'electronic_delivery_allowed',
                      values.electronic_delivery_allowed,
                      electronicDeliveryModal,
                      common('request.summary.electronicDeliveryCheckbox')
                    )}
                    <p className="copy copy--small base-summary__fees">{t('request.summary.additionalFees')}</p>
                    <BaseHint>{t('hints.deliveryPlatform')}</BaseHint>
                  </div>
                </>
              )}
            </Formik>
          </BaseBox>
        </div>
      </RequestStepWrapper>
    </FormWrapper>
  );
};

RequestFormSummary.propTypes = {
  isEquity: PropTypes.bool
};
