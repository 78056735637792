import React from 'react';
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './AppHeaderAdmin.scss';

export const AppHeaderAdmin_ = ({history, isProspectus}) => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const menuRegistry = [
    {
      path: '/list',
      label: t('admin.list')
    }
  ];

  const menuProspectus = [
    {
      path: '/groups',
      label: common('admin.groups')
    },
    {
      path: '/list',
      label: t('admin.list')
    }
  ];

  const chooseMenu = (isProspectus) => {
    return isProspectus ? menuProspectus : menuRegistry;
  };

  return (
    <div
      className="app-header-admin"
      data-cypress="app-header-admin"
    >
      <div className="container app-header-admin__content">
        {/* TODO change this to link if we have more operations */}
        <span
          className="app-header-admin__panel"
          data-cypress="admin-panel"
        >
          {common('admin.panel')}
        </span>
        <div className="app-header-admin__menu">
          {chooseMenu(isProspectus).map(({path, label}) => {
            const isMenuItemActive = history.location.pathname === path;

            return (
              <div
                key={path}
                className={classnames('app-header-admin__list', {
                  'app-header-admin__list--active': isMenuItemActive
                })}
                onClick={() => isMenuItemActive ? null : history.push(path)}
                data-cypress="admin-panel-list-item"
              >
                <span>{label}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
};

AppHeaderAdmin_.propTypes = {
  history: PropTypes.object,
  isProspectus: PropTypes.bool
};

export const AppHeaderAdmin = withRouter((AppHeaderAdmin_));

