import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RequestContext } from 'bxcommon/context/Request.context';
import { BaseIcon, BaseButtonLink, BaseButtonOutline } from '..';
import { noop, useMachine } from '../../helpers/common.helpers';
import { RequestDeletionConfirmationModal } from '../RequestDeletionConfirmationModal/RequestDeletionConfirmationModal';
import { RequestDeletionDetailsModal } from '../RequestDeletionDetailsModal/RequestDeletionDetailsModal';
import { FORM_VARIANT } from 'registry/src/constants/form.constant';
import './RequestDeletionButton.scss';

const DELETION_MACHINE_CONFIG = {
  idle: {
    CLICK_REQUEST_DELETION_BUTTON: 'providingDetails'
  },
  providingDetails: {
    CLICK_DELETE_REGISTRATION_BUTTON: 'confirming',
    CLICK_CLOSE_MODAL: 'idle'
  },
  confirming: {
    CLICK_CONFIRM_DELETE_REGISTRATION_BUTTON: 'deletingRegistration',
    CLICK_CLOSE_MODAL: 'idle'
  },
  deletingRegistration: {
    RECEIVED_DELETE_REGISTRATION_SUCCESS: 'deletedRegistration',
    RECEIVED_DELETE_REGISTRATION_ERROR: 'error'
  },
  deletedRegistration: {},
  error: {}
};

export const RequestDeletionButton = props => {
  const { createdAt, formVariant } = props;
  const { deleteRegistration } = useContext(RequestContext);
  const [payload, setPayload] = useState({});
  const { t: common } = useTranslation('common');
  const [state, send] = useMachine(DELETION_MACHINE_CONFIG, 'idle');
  const { REQUEST_DETAILS } = FORM_VARIANT;
  const isRequestDetails = formVariant === REQUEST_DETAILS;
  const handleRequestDeletionButtonClick = () => {
    send('CLICK_REQUEST_DELETION_BUTTON');
  };
  const handleDeleteRegistrationButtonClick = values => {
    setPayload(values);
  };
  const handleConfirmDeleteRegistrationButtonClick = () => {
    send('CLICK_CONFIRM_DELETE_REGISTRATION_BUTTON');
  };
  const handleCloseModalClick = () => {
    send('CLICK_CLOSE_MODAL');
  };
  const renderDeletionModalTitle = () => {
    return (
      <div className="request-deletion-modal__header">
        <div className="request-deletion-modal__header-icon">
          <BaseIcon icon="question-circle-outlined" white xxxlarge />
        </div>
        {common('request.requestDeletion.deleteYourRegistration')}
      </div>
    );
  };
  const renderDeletionModalFooter = () => {
    return (
      <div className="request-deletion-modal__footer">
        <BaseButtonLink icon="arrow-right" iconLeft onClick={handleCloseModalClick}>
          {common('request.requestDeletion.cancel')}
        </BaseButtonLink>
      </div>
    );
  };

  useEffect(() => {
    if (payload.files) {
      send('CLICK_DELETE_REGISTRATION_BUTTON');
    }
  }, [payload]);

  useEffect(() => {
    if (state === 'deletingRegistration') {
      deleteRegistration({ ...payload, createdAt })
        .then(() => {
          send('RECEIVED_DELETE_REGISTRATION_SUCCESS');
        })
        .catch(() => {
          send('RECEIVED_DELETE_REGISTRATION_ERROR');
        });
    } else if (state === 'deletedRegistration') {
      props.onDeletionRequestDone();
    }
  }, [state]);

  if (props.isHidden) return null;

  return (
    <>
      <div className="base-status__info">
        <BaseButtonOutline
          onClick={handleRequestDeletionButtonClick}
          small
          iconRight={!isRequestDetails}
          iconLeft={isRequestDetails}
          left={isRequestDetails}
          icon="delete"
          className="multiline-button"
          data-cypress="details-request-deletion-button"
          full={true}
        >
          {common('request.requestDeletion.requestDeletion')}
        </BaseButtonOutline>
      </div>
      <RequestDeletionDetailsModal
        footer={renderDeletionModalFooter()}
        onCancel={handleCloseModalClick}
        onConfirm={handleDeleteRegistrationButtonClick}
        title={renderDeletionModalTitle()}
        visible={state === 'providingDetails'}
      />
      <RequestDeletionConfirmationModal
        footer={renderDeletionModalFooter()}
        onCancel={handleCloseModalClick}
        onConfirm={handleConfirmDeleteRegistrationButtonClick}
        title={renderDeletionModalTitle()}
        visible={state === 'confirming'}
      />
    </>
  );
};

RequestDeletionDetailsModal.propTypes = {
  createdAt: PropTypes.string,
  isHidden: PropTypes.bool,
  onDeletionRequestDone: PropTypes.func,
  formVariant: PropTypes.string
};

RequestDeletionDetailsModal.defaultProps = {
  createdAt: null,
  isHidden: false,
  onDeletionRequestDone: noop
};
