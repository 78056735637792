import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BaseUploadInput } from '..';
import { BaseFormFieldWrapper } from '../BaseFormFieldWrapper/BaseFormFieldWrapper';
import { RequestContext } from '../../context/Request.context';

import './BaseUploadBox.scss';

const BaseUploadBox_ = props => {
  const {
    title,
    conditions,
    className,
    basic,
    type,
    config,
    value,
    uploadInputRef,
    isRenewal,
    isCodeOfConduct,
    ...rest
  } = props;
  const classes = classnames('base-upload-box', className, {
    'base-upload-box--no-style': basic,
    'base-upload-box--renewal': isRenewal
  });
  const { formRef, saveStep, request, url } = useContext(RequestContext);

  const beforeUpload = async () => {
    let { case_id, companies } = request;
    if (!case_id) {
      let payload = { companies };
      if (url === 'prospectuses') {
        payload = request;
      }
      payload = { ...payload, ...formRef.current.state.values };
      try {
        await saveStep(payload);
        return true;
      } catch (e) {
        return Promise.reject();
      }
    }
  };

  return (
    <>
      <div className={classes}>
        <p className="copy copy--color-dark copy--bold base-upload-box__title">{title}</p>
        <p className="copy copy--small base-upload-box__conditions">{conditions}</p>

        <BaseUploadInput
          ref={uploadInputRef}
          outline={basic}
          type={type}
          config={config}
          uploadingFiles={value}
          beforeUpload={beforeUpload}
          isRenewal={isRenewal}
          isCodeOfConduct={isCodeOfConduct}
          {...rest}
        />
      </div>
    </>
  );
};

BaseUploadBox_.propTypes = {
  title: PropTypes.string,
  conditions: PropTypes.string,
  className: PropTypes.string,
  basic: PropTypes.bool,
  config: PropTypes.shape({
    shouldUploadManually: PropTypes.bool,
    multiple: PropTypes.bool,
    extraFieldsMapper: PropTypes.objectOf(PropTypes.func),
    pdfOnly: PropTypes.bool
  }),
  type: PropTypes.number.isRequired,
  maxFileSize: PropTypes.number.isRequired
};

export const BaseUploadBox = BaseFormFieldWrapper(BaseUploadBox_);
