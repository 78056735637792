import { createReducer, initialStateMerger } from 'bxcommon/helpers/redux.helpers';
import { TOASTER_ACTIONS } from '../actions/toaster.actions';
import AppConfig from '../../providers/AppConfig';


const toasterInitialState = initialStateMerger([]);

export default (initialState) => {

  const toaster = {
    [TOASTER_ACTIONS.ADD_TOAST]: (state, {payload}) => {
      const nextId = state.reduce((max, toast) => Math.max(max, toast.id), -1) + 1;
      const defaultOptions = {timeout: AppConfig.defaultToastTime, disableClick: false};
      const isDuplicate = Boolean(
        state.find(toast => toast.body.message === payload.body.message && toast.body.type === payload.body.type));

      if (isDuplicate) {
        return state;
      }

      return [
        ...state, {
          id: nextId,
          body: {...payload.body},
          options: {...defaultOptions, ...payload.options}
        }
      ];

    },
    [TOASTER_ACTIONS.REMOVE_TOAST]: (state, {payload}) => {
      return state.filter(toast => toast.id !== payload);
    }
  };

  return createReducer(toasterInitialState(initialState), toaster);
}
