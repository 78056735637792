import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { getProtectedBlobURL } from '../../helpers/common.helpers';

export const ProtectedImage = ({ alt, className, src }) => {
  const imageRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const handleImageLoad = () => {
    URL.revokeObjectURL(imageSrc)
  };

  useEffect(() => {
    async function setProtectedImageSrc() {
      const imageBlobURL = await getProtectedBlobURL(src);
      setImageSrc(imageBlobURL);
    }

    setProtectedImageSrc();
  }, []);

  useEffect(() => {
    if (!imageRef.current || !imageSrc) return;

    imageRef.current.src = imageSrc;
  }, [imageSrc, imageRef.current]);

  return (
    <img
      alt={alt}
      className={className}
      onLoad={handleImageLoad}
      ref={imageRef}
    />
  )
};

ProtectedImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
};

ProtectedImage.defaultProps = {
  alt: "",
  className: ""
};
