import { createReducer, initialStateMerger } from 'bxcommon/helpers/redux.helpers';
import { USER_ACTIONS } from '../actions/user.actions';

const userInitialState = initialStateMerger({
  loggedIn: false,
  details: { is_admin: false }
});


export default (initialState) => {
  const initState = userInitialState(initialState);

  const user = {
    [USER_ACTIONS.TOGGLE_LOGIN]: (state, {payload}) => {
      if (!payload) {
        return {...initState};
      } else {
        return Object.assign({}, state, {loggedIn: payload})
      }
    },
    [USER_ACTIONS.HYDRATE_DETAILS]: (state, {payload}) => {
      return Object.assign({}, state, { details: payload.data })
    }
  };

  return createReducer(initState, user);
}
