import axios from 'axios';
import React, { useReducer } from 'react';
import { Field } from 'formik';
import { Trans } from 'react-i18next';

import authHelper from 'bxcommon/helpers/auth.helper';
import { BaseRadio, MODAL_RESPONSE_TYPE } from '../components';
import { MODAL_TYPES } from '../context/Modal.context';
import { backendValidationError } from '../store/actions';

export const generateDeferredPromise = () => {
  let resolve;
  let reject;

  let p = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return {
    promise: p,
    reject,
    resolve
  };
};

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

export const getStatusesStringList = list => {
  return list.reduce((acc, next) => {
    acc[next.id] = next.name.toLowerCase().replace(/ /g, '_');
    return acc;
  }, {});
};

export const textBreaker = text =>
  text
    .split('\n')
    .reduce(
      (acc, next, idx) =>
        acc
          ? [...acc, <br key={idx} />, <span key={'content' + idx}>{next}</span>]
          : [<span key={'content' + idx}>{next}</span>],
      null
    );

export const updateElectronicDelivery = async (openConfirmModal, updateRequestField, t, { target: { checked } }) => {
  if (checked) {
    const response = await openConfirmModal(
      t('common:request.summary.deliveryModal.title'),
      textBreaker(t('common:request.summary.deliveryModal.content')),
      { type: MODAL_TYPES.WARNING },
      'delivery-modal'
    );
    if (response !== MODAL_RESPONSE_TYPE.ACCEPT) {
      return false;
    }
  }

  updateRequestField({ electronic_delivery_allowed: checked });
};

export const getKey = (object, path, defaultVal = undefined) => {
  if (!object) {
    return undefined;
  }

  const _path = Array.isArray(path) ? path : path.split('.').filter(i => i.length);

  if (!_path.length) {
    return defaultVal ? defaultVal : object;
  }

  return getKey(object[_path.shift()], _path, defaultVal);
};

export const fileAddDebouncer = (time, fn) => {
  let timeout;
  let files = [];

  return async file => {
    if (timeout) {
      clearTimeout(timeout);
    }

    files.push(file);

    timeout = setTimeout(() => {
      fn(files);
      files = [];
    }, time);
  };
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const createRadioWithDescription = (t, translationKey) => (data, index, key, value) => (
  <>
    <Field name={key} component={BaseRadio} value={data.id} checked={value && value === data.id} data-cypress={key}>
      {data.name}
    </Field>
    <p className="copy copy--small">
      <Trans i18nKey={`${translationKey}.${index}.description`}>
        <a
          href="https://www.regservices.ch/?download=7155"
          target="_blank"
          rel="noopener noreferrer"
          className="link--underlined"
        >
          List of countries
        </a>
      </Trans>
    </p>
  </>
);

export async function getProtectedBlobURL(src) {
  const blob = await axios
    .get(src, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${authHelper.token}`
      }
    })
    .then(({ data }) => {
      return new Blob([data]);
    })
    .catch(e => {
      backendValidationError(e);
    });

  return URL.createObjectURL(blob);
}

export const noop = () => {};

export const useMachine = (config, initialState) => {
  const reducer = (state, event) => {
    const nextState = config[state][event];
    return nextState || state;
  };
  const [state, send] = useReducer(reducer, initialState);
  return [state, send];
};
