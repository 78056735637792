import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import reducers from './reducers';
import { hydrateList, removeFromList } from 'bxcommon/store/actions';

const middleware = process.env.NODE_ENV !== 'production' ? [thunk, logger] : [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25}) : compose;

export const hydrateAdvisorList = hydrateList('client-advisors');
export const removeFromAdvisorList = removeFromList('client-advisors');

export default createStore(combineReducers(reducers), composeEnhancers(applyMiddleware(...middleware)));
