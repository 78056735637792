import { Modal } from 'antd';
import { FastField, Formik, Form } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { RequestContext } from 'bxcommon/context/Request.context';
import { createUploadBox, requiredFilesValidator } from 'bxcommon/helpers/form.helper';
import AppConfig from 'bxcommon/providers/AppConfig';
import { BaseDatePicker } from 'bxcommon';
import { BaseButton } from '..';
import config from 'registry/src/registry.config';
import { noop } from '../../helpers/common.helpers';
import './RequestDeletionDetailsModal.scss';

const { maxFileSize } = config;

export const RequestDeletionDetailsModal = props => {
  const { FILE_UPLOAD_TYPES } = useContext(RequestContext);
  const { t: common } = useTranslation('common');
  const formRef = useRef(null);
  const uploadInputRef = useRef(null);
  const disabledDeleteRegistrationDate = date => date < moment();
  const onFormSubmit = () => {
    const values = formRef.current.state.values;
    props.onConfirm(values);
  };
  const [isFileUploaded, setFileUploadState] = useState(false);
  const [isDeleteRegistrationDateSet, setDeleteRegistrationDate] = useState(null);
  const handleFileUploadFieldChange = files => {
    setFileUploadState(!!files.length);
  };
  const handleDeletionDateChange = date => {
    setDeleteRegistrationDate(date);
  };
  const isDeleteRegistrationButtonEnabled = isFileUploaded && isDeleteRegistrationDateSet;
  const renderUploadBox = createUploadBox(() => {}, formRef, () => {});
  const renderDatePicker = props => <BaseDatePicker full calendarInputField={false} {...props} />;

  return (
    <Modal
      bodyStyle={{ maxHeight: '60vh', overflow: 'auto' }}
      className="search-form"
      closable={true}
      footer={props.footer}
      onCancel={props.onCancel}
      title={props.title}
      visible={props.visible}
      width={680}
    >
      <Formik
        ref={formRef}
        initialValues={{
          type: FILE_UPLOAD_TYPES.SIGNED_DELETION_REQUEST_FORM,
          files: []
        }}
        onSubmit={onFormSubmit}
      >
        {() => (
          <Form>
            <div className="request-deletion-details-modal__content">
              <div className="request-deletion-details-modal__text">
                <p className="copy copy--color-dark">
                  <Trans i18nKey="request.requestDeletion.detailsInfo" ns="common">
                    <a
                      href="/public/files/template-deregistration.docx"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link--underlined"
                    />
                    <strong />
                  </Trans>
                </p>
              </div>
              <div className="request-deletion-details-modal__deletion-date">
                <FastField
                  component={renderDatePicker}
                  defaultPickerValue={moment().add(1, 'day')}
                  name="deletion_date"
                  label={common('request.requestDeletion.selectDateForDeletion')}
                  disabledDate={disabledDeleteRegistrationDate}
                  format={AppConfig.defaultDateFormat}
                  data-cypress="deletion-date"
                  inputReadOnly={true}
                  onChange={handleDeletionDateChange}
                  showToday={false}
                />
              </div>
              <div className="request-deletion-details-modal__upload">
                {renderUploadBox({
                  basic: true,
                  name: 'files',
                  conditions: common('request.form.common.fileDefaultsPDF'),
                  maxFileSize,
                  title: common('request.requestDeletion.uploadYourSignedForm'),
                  type: FILE_UPLOAD_TYPES.SIGNED_DELETION_REQUEST_FORM,
                  config: {
                    multiple: false,
                    shouldUploadManually: true
                  },
                  uploadInputRef,
                  otherProps: {
                    onChange: handleFileUploadFieldChange
                  },
                  disabled: isFileUploaded
                })}
              </div>
              <div className="request-deletion-details-modal__delete-button">
                <BaseButton
                  className="multiline-button"
                  data-cypress="details-delete-registration-button"
                  disabled={!isDeleteRegistrationButtonEnabled}
                  type="submit"
                  fill={true}
                >
                  {common('request.requestDeletion.deleteRegistration')}
                </BaseButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

RequestDeletionDetailsModal.propTypes = {
  footer: PropTypes.node,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.node,
  visible: PropTypes.bool
};

RequestDeletionDetailsModal.defaultProps = {
  footer: null,
  onCancel: noop,
  onConfirm: noop,
  title: null,
  visible: false
};
