import axios from 'axios';
import { backendValidationError } from '../store/actions';

export const register = payload => {
  return axios.post('/users/', payload);
};

export const forgotPassword = payload => {
  return axios.post('/users/auth/password/reset/', payload);
};

export const resetPassword = payload => {
  return axios.post('/users/auth/password/reset/confirmation/', payload);
};

export const confirmAccount = payload => {
  return axios.post('/users/email/confirmation/', payload);
};

export const changeEmail = payload => {
  return axios.post('/users/email/change/', payload);
};

export const logout = () => {
  return axios.post('/users/auth/logout');
};

export const handleFileAdd = (url, data, config) => {
  return axios
    .post(url, data, config)
    .then(({ data }) => {
      return data.data;
    })
    .catch(e => {
      backendValidationError(e);
    });
};

export const handleFileReplace = (url, data, config) => {
  return axios
    .put(url, data, config)
    .then(({ data }) => {
      return data.data;
    })
    .catch(e => {
      backendValidationError(e);
    });
};

export const handleFileRemove = (caseId, uid, url) => {
  return axios.delete(`${url}/${caseId}/documents/${uid}/`).catch(e => {
    backendValidationError(e);
  });
};

export const prepareDocumentUrl = async (path, uuid) => {
  const url = () => {
    if (path.includes('document')) {
      return `documents/${uuid}/url/`;
    } else if (path.includes('addenda')) {
      return `prospectuses/addenda/${uuid}/url/`;
    } else if (path.includes('final-terms')) {
      return `prospectuses/final-terms/${uuid}/url/`;
    }
  };
  return await axios
    .get(url())
    .then(({ data }) => {
      return data.data;
    })
    .catch(e => {
      backendValidationError(e);
    });
};

export const setDeletionDate = (url, deletionDate) => {
  return axios
    .put(url, {
      deletion_date: deletionDate
    })
    .then(({ data }) => {
      return data.data;
    })
    .catch(e => {
      backendValidationError(e);
    });
};

export const handleDeleteRegistration = (url, data) => {
  return axios
    .put(url, { amendment: { new: true, type: 1 } })
    .then(() => {
      return setDeletionDate(url, data.deletionDate);
    })
    .then(() => {
      const { file, name } = data.files[0];
      const bodyFormData = new FormData();
      bodyFormData.append('file', file);
      bodyFormData.append('name', name);
      bodyFormData.append('type', data.documentType);

      return axios({
        method: 'post',
        url: `${url}documents/`,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    })
    .then(() => {
      return axios.patch(url, {
        payment: {
          method: 1,
          voucher_code: '',
          paid_at: data.createdAt,
          status: 'success'
        }
      });
    })
    .catch(e => {
      backendValidationError(e);
    });
};
