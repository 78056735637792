import AppConfig from '../providers/AppConfig';
import moment from 'moment';

export const countValidUntil = date => {
  const currentDate = new Date();
  const validDate = new Date(date);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = validDate.getTime() - currentDate.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  const formatDate = moment(validDate).format(AppConfig.dayMothYearFormat);

  return date ? `${formatDate} (${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} left)` : '-';
};
