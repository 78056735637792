import React from 'react';
import PropTypes from 'prop-types';
import {Radio} from 'antd';

import { BaseFormFieldWrapper } from '../BaseFormFieldWrapper/BaseFormFieldWrapper'

const BaseRadio_ = props => {
  const {field, onBlur, handleChange, children, ...rest} = props;

  return (
    <Radio
      className="base-radio--single"
      {...field}
      {...rest}
      onBlur={onBlur}
      onChange={(e) => handleChange(e.target.value)}
    >
      {children  && <span className="radio-text">{children}</span>}
    </Radio>
  );
};

BaseRadio_.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any
  }),
  onBlur: PropTypes.func,
  handleChange: PropTypes.func,
  children: PropTypes.node
};

export const BaseRadio = BaseFormFieldWrapper(BaseRadio_);
