const sortGeneral = (arr, omit) => {
  const omitted = arr.filter(({iso_code}) => omit.includes(iso_code));
  const rest = arr.filter(({iso_code}) => !omit.includes(iso_code));
  const sort = ({iso_code: isoA}, {iso_code: isoB}) => omit.indexOf(isoA) - omit.indexOf(isoB);
  return [...omitted.sort(sort), ...rest]
};


export const sortDialcodes = (arr, omit) => {
  return sortGeneral(arr, omit)
    .map(item => ({
      dial: `${item.name} +${item.dial}`,
      iso_code: item.iso_code,
      id: item.id
    }))
};

export const sortCountries = (arr, countriesToTop) => {
  return sortGeneral(arr, countriesToTop)
    .map(item => ({
      name: item.name,
      iso_code: item.iso_code,
      id: item.id
    }))
};
