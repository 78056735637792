import React from 'react';
import { connect } from 'react-redux';

import './LoginMessages.scss';

const LoginMessages = ({ messages }) => {
  return (
    <div className="login-messages">
      <div className="row">
        <div className="col-2" />
        <div className="col-8">
          {Boolean(messages && messages.length) && messages.map((item) => (
            <div className="login-messages__item">
              <h2 className="heading heading--bold heading--color-white heading--subsection">
                {item.title}
              </h2>
              <p className="copy copy--color-white">
                {item.description}
              </p>
            </div>
          ))}
        </div>
        <div className="col-2" />
      </div>
    </div>
  )
};

const mapStateToProps = (state) => ({
  messages: state.resources.login_messages ? state.resources.login_messages.data : []
});

export default connect(mapStateToProps, null)(LoginMessages);
