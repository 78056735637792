export const PAYMENT_METHODS = {
  CREDIT_CARD: 1,
  VOUCHER_CODE: 2
};

export const PAYMENT_STATUSES = {
  UNKNOWN: "unknown",
  FAILURE: "failure",
  SUCCESS: "success",
  TRUSTED: "trusted",
}
