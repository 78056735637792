import { STATUS } from '../constants/status.constant';

export default {
  defaultDateFormat: 'DD-MM-YYYY',
  defaultBackendDateFormat: 'YYYY-MM-DD',
  dotsDateFormat: 'DD.MM.YYYY',
  dayMonthHourFormat: 'D. MMM, HH:mm',
  dayMothYearFormat: 'D. MMM, YYYY',
  dayMonthYearHourFormat: 'D. MMM, YYYY HH:mm',
  defaultToastTime: 10000,
  mainCountriesIsoCodes: ['CH', 'DE', 'FR', 'GB', 'IT'],
  searchDebounceTime: 700,
  detailsStatuses: [STATUS.REJECTED, STATUS.UPDATE_REQUIRED, STATUS.APPROVED, STATUS.IN_REVIEW],
  amendmentStatuses: [STATUS.IN_REVIEW, STATUS.REJECTED],
  editStatuses: [STATUS.DRAFT, STATUS.UPDATE_REQUIRED],
  approvedStatuses: [STATUS.APPROVED],
  resolvedStatuses: [STATUS.APPROVED, STATUS.REJECTED],
  deleteStatuses: [STATUS.DRAFT],
  removeFileTimeout: 1000,
  optionalDateStatuses: [STATUS.UPDATE_REQUIRED, STATUS.APPROVED, STATUS.REJECTED],
  fileAddDebounceTime: 200,
  maxFileUploadNameLength: 100
};
