import React from 'react';
import PropTypes from 'prop-types';
import { BaseButton } from '../BaseButton/BaseButton';

export const BaseButtonOutline = props => {
  const { children, ...rest } = props;

  return (
    <BaseButton
      outline
      {...rest}
    >
      {children}
    </BaseButton>
  );
};

BaseButtonOutline.propTypes = {
  children: PropTypes.node.isRequired
};
