import axios from 'axios';

let interceptor;
export default (token) => {
  if (token) {
    interceptor = axios.interceptors.request.use(
      (config) => {
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      })
  } else {
    axios.interceptors.request.eject(interceptor);
  }
};
