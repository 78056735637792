import React, { useContext, useState } from 'react';
import { FastField, Field, Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';

import AppConfig from 'bxcommon/providers/AppConfig'
import { RequestContext } from 'bxcommon/context/Request.context';
import { RequestStepWrapper } from 'bxcommon/components/RequestStepWrapper/RequestStepWrapper';
import { createRadioWithDescription } from 'bxcommon/helpers/common.helpers';
import {
  createCleanFields,
  createInput,
  useValidation,
  createUploadBox,
  renderMultipleInputs
} from 'bxcommon/helpers/form.helper';
import {
  BaseBoxHeading,
  BaseDatePicker,
  BaseBox,
  BaseRadioGroup,
  BaseHint
} from 'bxcommon'

import config from '../../prospectus.config';
import './FormGeneral.scss';
import { FormWrapper } from "bxcommon/components/FormWrapper/FormWrapper";


const { maxFileSize } = config;
export const FormGeneral = (props) => {
  const {
    request,
    updateRequestField,
    formRef,
    FILE_UPLOAD_TYPES,
    resources: {
      issue_type,
      stock_exchange,
      type,
      scheme,
      foreign_type
    },
  } = useContext(RequestContext);
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const [footerDisabled, disableFooter] = useState(false);
  const renderUploadBox = createUploadBox(disableFooter);

  const filteredIssueType = issue_type.data
    .filter(type => type.security_types.includes(request.security_type));

  const [validationSchema, submitWithValidation] = useValidation(
    Yup.object().shape({
      // basic information box
      name: Yup.string().required(common('errors.requiredField')),
      type: Yup.number()
        .nullable()
        .test(
          'type-of-prospectus',
          common('errors.requiredField'),
          value => value
        ),
      foreign_type: Yup.number()
        .nullable()
        .when('type', {
          is: type => type === 2,
          then: Yup.number()
            .test(
              'foreign-is-set',
              common('errors.atLeastOneFieldRequired'),
              value => (value)
            ),
          otherwise: Yup.number().notRequired()
        }),
      issue_type: Yup.number()
        .nullable()
        .test(
          'type-of-issue',
          common('errors.requiredField'),
          value => value
        ),
      stock_exchange: Yup.number()
        .nullable()
        .when('issue_type', {
          is: issueType => props.isEquity && issueType === 1,
          then: Yup.number()
            .test(
              'exchange-is-set',
              common('errors.requiredField'),
              value => (value)
            ),
          otherwise: Yup.number().notRequired()
        }),
      issue_date: Yup.object()
        .nullable()
        .when('issue_type', {
          is: issueType => !props.isEquity && issueType === 3,
          then: Yup.object().notRequired(),
          otherwise: Yup.object().notRequired()
        }),


      // issuer box
      scheme: Yup.number()
        .test(
          'prospectus-scheme',
          common('errors.requiredField'),
          value => !props.isEquity ||  value
        ),
      issuer_name: Yup.string()
        .test(
          'issuer-name',
          common('errors.requiredField'),
          value => !props.isEquity || value
        ),
      issuers: Yup.array()
        .test(
          'Issuers required',
          common('errors.requiredField'),
          value => props.isEquity || (value.length === 1 ? value[value.length - 1] : true)
        )
        .test(
          'Issuers at least one required',
          common('errors.atLeastOneFieldRequired'),
          value => props.isEquity || (value.length > 1 ? value.some(v => Boolean(v)) : true)
        )
    })
  );

  const initialFormValues = {
    // basic information
    name: request.name || '',
    type: request.type || null,
    foreign_type: request.foreign_type || null,
    issue_type: request.issue_type || '',
    stock_exchange: request.stock_exchange || '',
    instrument_id: request.instrument_id || '',
    issue_date: request.issue_date ? moment(request.issue_date) : null,
    maturity_date: request.maturity_date ? moment(request.maturity_date) : null,

    // issuer
    scheme: request.scheme || '',
    issuer_name: request.issuer_name || '',
    issuers: request.issuers.length ? request.issuers : [],
    guarantors: request.guarantors || '',
    declaration_files: request.declaration_files || []
  };

  const cleanFields = createCleanFields({
    issue_type: props.isEquity
      ? [{
        key: 'stock_exchange',
        value: null,
        condition: (value) => value !== 1
      }]
      : [{
        key: 'instrument_id',
        value: '',
        condition: (value) => value !== 3
      }, {
        key: 'issue_date',
        value: null,
        condition: (value) => value !== 3
      }, {
        key: 'maturity_date',
        value: null,
        condition: (value) => value !== 3
      }],
    type: [{
      key: 'foreign_type',
      value: null,
      condition: (value) => value !== 2
    }],
  });

  // TODO: move to heleprs (propably curry the disableDate)
  const createDatePicker = (name, label, disabled = false, disabledDate = (v) => v < moment().subtract(1, 'day')) => (
    <FastField
      component={BaseDatePicker}
      name={name}
      label={label}
      disabled={disabled}
      disabledDate={disabledDate}
      format={AppConfig.defaultDateFormat}
      data-cypress={name}
    />
  );

  const foreignTypeRadio = createRadioWithDescription(t, 'request.form.general.basicInformation.foreignType');

  const onFormSubmit = () => {
    const values = formRef.current.state.values;
    values.security_type = request.security_type;
    values.issuers = props.isEquity && values.issuer_name ? [values.issuer_name] : values.issuers.filter(i => i);
    return updateRequestField({...values});
  };

  return (
    <FormWrapper>
      <RequestStepWrapper
        formRef={formRef}
        validationSubmit={submitWithValidation}
        disabled={footerDisabled}
        {...props}
      >
        <div>
          <Formik
            ref={formRef}
            initialValues={initialFormValues}
            onSubmit={() => onFormSubmit()}
            validationSchema={validationSchema}
          >
            {({values, errors, touched, setFieldValue, setFieldTouched}) => (
              <>
                <BaseBox
                  className="request-form__box"
                  lifted
                >
                  <BaseBoxHeading>{t('request.form.general.basicInformation.heading')}</BaseBoxHeading>
                  <Form className="form-container">
                    <div className="row">
                      {createInput('name', t('request.form.general.basicInformation.name'))}
                    </div>
                    <FastField
                      component={BaseRadioGroup}
                      name="type"
                      options={type.data}
                      value={values.type}
                      label={t('request.form.general.basicInformation.definitionOfType')}
                      onChange={(value, fieldName) => cleanFields(value, fieldName, setFieldValue)}
                      data-cypress="type"
                    />
                    {values.type === 2 && (
                      <div className="request__form-foreign-type">
                        {foreign_type.data.map((item, idx) => (
                          <div
                            className="row"
                            key={idx}
                          >
                            <div className="col-12">
                              {foreignTypeRadio(item, idx, 'foreign_type', values.foreign_type)}
                            </div>
                          </div>
                        ))}
                        <ErrorMessage name="foreign_type">
                          {() =>
                            <div className="request__form-foreign-type-error">
                              {common('errors.atLeastOneFieldRequired')}
                            </div>
                          }
                        </ErrorMessage>
                      </div>
                    )}
                    <div className="row request__form-issue-type">
                      <Field
                        component={BaseRadioGroup}
                        name="issue_type"
                        options={filteredIssueType}
                        onChange={(value, fieldName) => cleanFields(value, fieldName, setFieldValue)}
                        value={values.issue_type}
                        radioAsBox
                        data-cypress="issue_type"
                      />
                    </div>
                    <div className="row">
                      {props.isEquity && values.issue_type === 1 && (
                        <FastField
                          component={BaseRadioGroup}
                          name="stock_exchange"
                          options={stock_exchange.data}
                          value={values.stock_exchange}
                          data-cypress="stock_exchange"
                        />
                      )}
                    </div>
                    {!props.isEquity && values.issue_type === 3 && (
                      <>
                        <div className="row">
                          <div className="col-6">
                            {createInput('instrument_id', t('request.form.general.basicInformation.instrument_id'))}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                            {createDatePicker(
                              'issue_date',
                              t('request.form.general.basicInformation.issue_date'),
                              false,
                              null
                            )}
                          </div>
                          {!props.isEquity &&
                          <div className="col-4">
                            {createDatePicker(
                              'maturity_date',
                              t('request.form.general.basicInformation.maturity_date'),
                              false
                            )}
                          </div>
                          }
                        </div>
                      </>
                    )}
                  </Form>
                </BaseBox>

                <BaseBox
                  className="request-form__box"
                  lifted
                >
                  <BaseBoxHeading>{t('request.form.general.typeOfIssue.heading')}</BaseBoxHeading>
                  <Form className="form-container">
                    {props.isEquity &&
                    <div className="row request-form--with-close-hint">
                      <div className="col-10">
                        <FastField
                          component={BaseRadioGroup}
                          name="scheme"
                          options={scheme.data}
                          label={t('request.form.general.typeOfIssue.scheme')}
                          value={values.scheme}
                          data-cypress="scheme"
                        />
                        <BaseHint>
                          {t('hints.scheme')}
                        </BaseHint>
                      </div>
                    </div>
                    }
                    <div className="row">
                      <div className="col-10">
                        {
                          props.isEquity
                            ? createInput('issuer_name', t('request.form.general.typeOfIssue.issuer_name'))
                            : renderMultipleInputs(
                            'issuers',
                            {
                              label: t('request.form.general.typeOfIssue.issuer_name'),
                              add: t('request.form.general.typeOfIssue.addNewIssuer'),
                              clear: t('request.form.general.typeOfIssue.clearIssuers')
                            },
                            values.issuers.length > 0 ? values.issuers : [''],
                            errors.issuers,
                            touched.issuers,
                            setFieldValue,
                            setFieldTouched
                            )
                        }
                      </div>
                    </div>
                    {!props.isEquity &&
                    <div className="row">
                      <div className="col-10">
                        <div className="row">
                          {createInput('guarantors', t('request.form.general.typeOfIssue.guarantors'))}
                        </div>
                      </div>
                    </div>
                    }

                    <div className="request-form--with-close-hint">
                      <BaseBoxHeading>{t('request.form.general.uploadDocument.heading')}</BaseBoxHeading>
                      {renderUploadBox({
                        name: 'declaration_files',
                        title: t('request.form.general.uploadDocument.uploadIssuerDeclaration'),
                        conditions: common('request.form.common.fileDefaultsWordPngJpg'),
                        type: FILE_UPLOAD_TYPES.DECLARATION_FILE,
                        maxFileSize,
                        config: {
                          multiple: true,
                          pdfOnly: false
                        }
                      })}
                      <BaseHint>
                        <Trans i18nKey="hints.declarationFile">
                          <a
                            href="https://www.regservices.ch/?download=6319"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link--underlined"
                          >
                            here
                          </a>
                        </Trans>
                      </BaseHint>
                    </div>
                  </Form>
                </BaseBox>
              </>
            )}
          </Formik>
        </div>
      </RequestStepWrapper>
    </FormWrapper>
  );
};

FormGeneral.propTypes = {
  isEquity: PropTypes.bool
};
