import React, {useState} from 'react';
import { connect } from 'react-redux';
import { Formik, Form, FastField } from 'formik';
import { withRouter } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { successToast } from 'bxcommon/store/actions';
import { register } from 'bxcommon/misc/requests'
import {
  BaseBox,
  BaseBoxHeading,
  BaseButtonFill,
  BaseCheckbox,
  BaseInput,
  BaseSelect
} from 'bxcommon';

import { HalfPageLayout } from 'bxcommon/components/HalfPageLayout/HalfPageLayout';
import LoginMessages from 'bxcommon/components/LoginMessages/LoginMessages';

import { createInput, requiredStringValidator, passwordValidator, phoneRegExp } from 'bxcommon/helpers/form.helper';
import { Captcha } from 'bxcommon/components/Captcha/Captcha';
import { ProspectusHeader } from '../ProspectusHeader/ProspectusHeader';
import errorsService, { REGISTRATION_ERRORS } from 'bxcommon/helpers/errors.helper';

const UserRegistration = ({history, successToast, countries, dialcodes}) => {
  const { t } = useTranslation();
  const { t: common } = useTranslation('common');

  const [captchaError, setCaptchaError] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);

  const initialFormValues = {
    first_name: '',
    last_name: '' ,
    dial_code: undefined,
    phone: '',
    email: '',
    password: '',
    confirm_password: '',
    company_name: '',
    address_line_1: '',
    address_line_2: '',
    postcode: '',
    city: '',
    country: undefined,
    consent: false
  };

  const validationSchema = Yup.object().shape({
    first_name: requiredStringValidator(),
    last_name: requiredStringValidator(),
    phone: requiredStringValidator().matches(phoneRegExp, common('errors.phone')),
    dial_code: requiredStringValidator(),
    email: requiredStringValidator().email(common('errors.email')),
    password: passwordValidator(),
    confirm_password: passwordValidator()
      .oneOf([Yup.ref('password'), null], common('errors.passwordsDontMatch')),
    company_name: requiredStringValidator(),
    address_line_1: requiredStringValidator(),
    postcode: requiredStringValidator(),
    city: requiredStringValidator(),
    country: requiredStringValidator(),
    consent: Yup.boolean().oneOf([true], common('errors.requiredField'))
  });

  const onSubmit = async(formData) => {
    if (!captchaError) {
      formData.last_login = new Date();

      try {
        setRegisterLoading(true);
        await register(formData);

        successToast(
          common('register.success.successDescription'),
          common('register.success.successTitle')
        );
        setRegisterLoading(false);
        history.push('/login');
      } catch (error) {
        errorsService.handleErrorToast(error, REGISTRATION_ERRORS);
        setRegisterLoading(false);
      }
    }
  };

  const Content = () => (
    <div>
      <div className="user-common-view__title">
        <h1 className="heading heading--primary heading--color-primary">
          {common('register.heading')}
        </h1>
        <div>
          {common('register.subheading')}
        </div>
      </div>
      <Formik
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <BaseBox
              className="user-common-view__box"
              borderless
            >
              <BaseBoxHeading>{common('register.accountDetails')}</BaseBoxHeading>
              <Form className="form-container">
                <div className="row">
                  <div className="col-1" />
                  <div className="col-10">
                    <div className="row">
                      {createInput('first_name', common('register.firstName'))}
                    </div>
                    <div className="row">
                      {createInput('last_name', common('register.lastName'))}
                    </div>
                    <div className="user-common-view__label">
                      {common('register.phoneNumber')}
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <FastField
                          component={BaseSelect}
                          name="dial_code"
                          selectOptions={dialcodes}
                          customKeys={{label: 'dial', value: 'id'}}
                          dropdownClassName="dial-code"
                          data-cypress="dial_code"
                        />
                      </div>
                      <div className="col-6">
                        {createInput('phone')}
                      </div>
                    </div>
                    <div className="row">
                      {createInput('email', t('register.workEmail'))}
                    </div>
                    <div className="row">
                      <FastField
                        component={BaseInput}
                        name="password"
                        type="password"
                        label={common('register.password')}
                        inputHint={common('passwordHint')}
                        data-cypress="password"
                      />
                    </div>
                    <div
                      className="row"
                      data-cypress="confirm-password-wrapper"
                    >
                      <FastField
                        component={BaseInput}
                        name="confirm_password"
                        type="password"
                        label={common('register.confirmPassword')}
                        data-cypress="confirm_password"
                      />
                    </div>
                  </div>
                  <div className="col-1" />
                </div>
              </Form>
            </BaseBox>

            <BaseBox
              className="user-common-view__box"
              borderless
            >
              <BaseBoxHeading>{common('register.companyAddress')}</BaseBoxHeading>
              <Form className="form-container">
                <div className="row">
                  <div className="col-1" />
                  <div className="col-10">
                    <div className="row">
                      {createInput('company_name', t('register.companyName'))}
                    </div>
                    <div className="row">
                      {createInput('address_line_1', t('register.addressLine1'))}
                    </div>
                    <div className="row">
                      {createInput('address_line_2', t('register.addressLine2'))}
                    </div>
                    <div className="row">
                      <div className="col-3">
                        {createInput('postcode', t('register.postcode'))}
                      </div>
                      <div className="col-9">
                        {createInput('city', t('register.city'))}
                      </div>
                    </div>
                    <div className="row">
                      <FastField
                        component={BaseSelect}
                        name="country"
                        label={t('register.country')}
                        selectOptions={countries}
                        customKeys={{label: 'name', value: 'id'}}
                        dropdownClassName="country"
                        data-cypress="country"
                      />
                    </div>
                    <div className="row user-common-view__consent">
                      <FastField
                        component={BaseCheckbox}
                        name="consent"
                        checked={props.values.consent}
                        data-cypress="consent"
                      >
                        <Trans i18nKey="register.consent" ns="common">
                          <a
                            href="https://www.regservices.ch/?download=6313"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link--underlined"
                          >
                            User Agreement
                          </a>
                          <a
                            href="https://www.regservices.ch/en/privacy-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link--underlined"
                          >
                            Privacy Policy
                          </a>
                        </Trans>
                      </FastField>
                    </div>

                    { !window.top.Cypress && (
                      <Captcha
                        validateOnSubmitCount={props.submitCount}
                        setCaptchaError={setCaptchaError}
                        captchaError={captchaError}
                      />
                    )}

                    <div className="row user-common-view__submit">
                      <div className="col-12">
                        <BaseButtonFill
                          icon="arrow-right"
                          iconRight
                          full
                          type="submit"
                          loading={registerLoading}
                          data-cypress="register-button"
                        >
                          {common('register.submitButton')}
                        </BaseButtonFill>
                      </div>
                    </div>
                  </div>
                  <div className="col-1" />
                </div>
              </Form>
            </BaseBox>
          </>
        )}
      </Formik>
    </div>
  )

  return (
    <ProspectusHeader>
      <div className="user-common-view__content">
        <HalfPageLayout
          leftContent={<LoginMessages />}
          rightContent={<Content />}
        />
      </div>
    </ProspectusHeader>
  );
};

const mapStateToProps = (state) => ({
  countries: state.resources.countries,
  dialcodes: state.resources.dialcodes,
});

export default withRouter(connect(mapStateToProps, { successToast })(UserRegistration));
